<p
  #paragraph
  *ngIf="details"
  [attr.open]="open"
  [innerHTML]="details | linebreak"
></p>

<button *ngIf="needsReadMore" (click)="toggleOpen()">
  {{ open ? 'View Less' : 'View More' }}
</button>
