import { createReducer, on, Action } from '@ngrx/store';
import { appActions } from '../actions/app.actions';
import { FlashMessage } from '../models/flash-message.model';

export const APP_FEATURE_KEY = 'app';

export interface AppState {
  isLoading: boolean;
  showingFlashMessage: boolean;
  flashMessage: FlashMessage;
}

export const initialState: AppState = {
  isLoading: false,
  showingFlashMessage: false,
  flashMessage: {
    flashMessageType: undefined,
    message: undefined,
    timeout: undefined,
  },
};

const reducer = createReducer(
  initialState,
  on(appActions.SetIsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload.isLoading,
  })),
  on(appActions.ToggleShowingFlashMessage, (state, { payload }) => ({
    ...state,
    showingFlashMessage: payload,
  })),
  on(appActions.SetFlashMessage, (state, payload) => ({
    ...state,
    flashMessage: payload.flashMessage,
  })),
  on(appActions.RemoveFlashMessage, state => ({
    ...state,
  })),
);

export function appReducer(state: AppState, action: Action) {
  return reducer(state, action);
}
