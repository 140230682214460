export enum EventDeclineReason {
  NOT_AVAILABLE = 0,
  TOO_FAR_TO_TRAVEL = 1,
  NOT_PROVIDING_SERVICE = 2,
  NOT_ENOUGH_INFORMATION = 3,
  INADEQUATE_BUDGET = 4,
}

export const SUPPLIER_DECLINE_REASON_TEXT = new Map<EventDeclineReason, string>(
  [
    [
      EventDeclineReason.NOT_AVAILABLE,
      `We don't have availability on this date`,
    ],
    [EventDeclineReason.TOO_FAR_TO_TRAVEL, `It's too far to travel`],
    [EventDeclineReason.NOT_PROVIDING_SERVICE, `We don't provide this service`],
    [EventDeclineReason.NOT_ENOUGH_INFORMATION, `Not enough information`],
    [EventDeclineReason.INADEQUATE_BUDGET, `Inadequate budget`],
  ],
);
