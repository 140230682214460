<h4>{{ review?.title }}</h4>
<ate-star-bar
  color="orange"
  [attr.percent]="review.reviewRating * 20"
></ate-star-bar>
<app-read-more
  [details]="review?.description"
  [readAll]="readAll"
  [moreText]="moreText"
  [lessText]="lessText"
  [toggleCallback]="toggleCallback"
></app-read-more>
<div class="meta">
  <p>
    {{ review.user.firstName }}
    {{ review.user.lastName | slice: 0:1 }} •
    {{ review.dateCreated | shortFormDate }}
  </p>
  <span class="verified"
    ><ate-icon name="check-circle-v2"></ate-icon>VERIFIED</span
  >
</div>
