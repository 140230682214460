<p
  #paragraph
  [class.readAll]="readAll"
  [class.open]="open"
  [innerHTML]="details | linebreak"
  [style.WebkitLineClamp]="lines"
></p>

<button (click)="toggleOpen()" *ngIf="needsReadMore">
  {{ open ? lessText : moreText }}
  <ate-icon *ngIf="icon" name="arrow-right"></ate-icon>
</button>
