import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, map, catchError, first } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

import { eventActions } from './event.actions';
import { EventRequestService } from '../services/event-request/event-request.service';
import { EventRequestSingleType } from '../services/event-request/event-request.type';

@Injectable()
export class EventEffects {
  constructor(
    private _actions$: Actions,
    private _eventRequestService: EventRequestService,
  ) {}

  getEvent$ = createEffect(() =>
    this._actions$.pipe(
      ofType(eventActions.GetEvent),
      switchMap(({ payload }) => {
        return this._eventRequestService
          .getOneRequest(payload.eventUuid, payload.eventRequestUuid)
          .pipe(
            first(),
            map((result: EventRequestSingleType) => {
              return eventActions.SetEventSuccess({
                payload: result,
              });
            }),
            catchError(() => {
              return of(eventActions.SetEventFailure());
            }),
          );
      }),
    ),
  );
}
