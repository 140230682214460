import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MaintenanceService } from '../../services/maintence/maintenance.service';

@Injectable()
export class MaintenanceGuard {
  constructor(
    private _maintenanceService: MaintenanceService,
    private _router: Router,
  ) {}

  canActivate(): Observable<boolean> {
    return this._maintenanceService.getMaintenanceMode().pipe(
      map(snapshot => {
        const maintenanceMode = snapshot.val();

        if (maintenanceMode) {
          this._router.navigate(['maintenance']);
        }

        return !maintenanceMode;
      }),
    );
  }

  canActivateChild(): Observable<boolean> {
    return this.canActivate();
  }
}
