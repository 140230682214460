import { Inject, Injectable } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
declare global {
  interface Window {
    ateV2OpenForm: (formLaunchConfig: { [key: string]: any }) => void;
  }
}
@Injectable()
export class EnhancedFormsLauncherService {
  public localStorageAuthKey = 'enhancedFormsfirebaseAuthToken';

  constructor(@Inject('environment') private environment) {}

  launchForm(formUuid: string, eventUuid: string) {
    window.ateV2OpenForm({
      formUuid,
      googlePlacesApiKey: this.environment.googleMapsApiKey,
      eventUuid,
    });
  }

  onFormClose(): Observable<Event> {
    return fromEvent(window, 'onExitForm');
  }
}
