/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import {
  Database,
  DatabaseReference,
  object,
  ref,
} from '@angular/fire/database';
import { Observable, from } from 'rxjs';

@Injectable()
export class AppversionService {
  private appVersionRef: DatabaseReference;

  constructor(private _database: Database) {
    this.appVersionRef = ref(this._database, `organiser_app_version`);
  }

  public getAppVersionValueChanges(): Observable<any> {
    return from(object(this.appVersionRef));
  }
}
