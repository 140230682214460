export enum QuoteStatus {
  UNPUBLISHED = 'UNPUBLISHED',
  PUBLISHED_PAST = 'PUBLISHED_PAST',
  PUBLISHED = 'PUBLISHED',
  PUBLISHED_MESSAGED = 'PUBLISHED_MESSAGED',
  REJECTED_FUTURE = 'REJECTED_FUTURE',
  REJECTED_PAST = 'REJECTED_PAST',
  ACCEPTED_FUTURE = 'ACCEPTED_FUTURE',
  ACCEPTED_PAST = 'ACCEPTED_PAST',
  PUBLISHED_REVIEWED = 'PUBLISHED_REVIEWED',
  ARCHIVED_AFTER = 'ARCHIVED_AFTER',
  ARCHIVED_BEFORE = 'ARCHIVED_BEFORE',
  PUBLISHED_NEW = 'PUBLISHED_NEW',
  PUBLISHED_VIEWED = 'PUBLISHED_VIEWED',
}
