<div class="spinner">
  <ate-spinner *ngIf="isInitialLoading"></ate-spinner>
</div>

<div class="inner-container" *ngIf="!isInitialLoading && !modalView">
  <header>
    <ate-icon (click)="goBack()" name="chevron-left-v2"></ate-icon>
    <h1>All Reviews</h1>
  </header>

  <main>
    <section class="review-stats">
      <header>
        <ate-star color="orange"></ate-star>
        <span>{{ listingReview?.reviewRating }}</span>
        <span>({{ listingReview?.reviewCount }}) reviews</span>
      </header>
      <div class="core-stat">
        <p>Quality</p>
        <span
          >{{ (listingReview?.breakdown?.quality | number: '1.1')   ?? '-'}}</span
        >
      </div>
      <div class="core-stat">
        <p>Communication</p>
        <span>{{
          (listingReview?.breakdown?.communication | number: '1.1')  ?? '-'
        }}</span>
      </div>
      <div class="core-stat">
        <p>Value</p>
        <span
          >{{ (listingReview?.breakdown?.value | number: '1.1') ?? '-' }}</span
        >
      </div>
    </section>

    <app-review-list-item
      *ngFor="let item of listingReview?.items"
      [readAll]="true"
      [review]="item"
      [attr.id]="'review-' + item.uuid"
    ></app-review-list-item>

    <div class="loading-controller">
      <ate-spinner *ngIf="isLoading"></ate-spinner>
      <button *ngIf="!isLoading" (click)="getListingReviews()">
        <span>View More Reviews</span>
      </button>
    </div>
  </main>
</div>

<div *ngIf="modalView">
  <main>
    <section class="review-stats">
      <header>
        <ate-star color="orange"></ate-star>
        <span>{{ listingReview?.reviewRating }}</span>
        <span>({{ listingReview?.reviewCount }}) reviews</span>
      </header>
      <div class="core-stat-container">
        <div class="core-stat">
          <p>Quality</p>
          <span
            >{{ (listingReview?.breakdown?.quality | number: '1.1')   ?? '-'}}</span
          >
        </div>
        <div class="core-stat">
          <p>Communication</p>
          <span>{{
          (listingReview?.breakdown?.communication | number: '1.1')  ?? '-'
          }}</span>
        </div>
        <div class="core-stat">
          <p>Value</p>
          <span
            >{{ (listingReview?.breakdown?.value | number: '1.1') ?? '-' }}</span
          >
        </div>
      </div>
    </section>

    <app-review-list-item
      *ngFor="let item of listingReview?.items"
      [readAll]="true"
      [review]="item"
      [attr.id]="'review-' + item.uuid"
    ></app-review-list-item>
  </main>
</div>
