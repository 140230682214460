import { Component, HostListener, Input } from '@angular/core';
import { DownloadService } from '../../../../shared/services/download/download.service';

@Component({
  selector: 'app-download-file',
  templateUrl: './download-file.component.html',
  styleUrls: ['./download-file.component.scss'],
})
export class DownloadFileComponent {
  @Input() file = '';
  constructor(private downloadService: DownloadService) {}

  @HostListener('click', ['$event'])
  onClick() {
    this.downloadService.downloadFile(this.file);
  }
}
