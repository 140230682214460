<div class="chat-component" [ngClass]="{ fixed: fixedMessageBox }">
  <app-messages-panel
    *ngIf="quoteUuid"
    [quoteUuid]="quoteUuid"
    [listing]="listing"
  ></app-messages-panel>

  <ng-content></ng-content>

  <app-send-message
    [ngClass]="{ 'app-send-message-fixed': fixedMessageBox }"
    [quoteUuid]="quoteUuid"
    [phoneNumber]="phoneNumber"
    [displayControls]="displayControls"
    [status]="status"
  ></app-send-message>
</div>
