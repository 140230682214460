import { Pipe, PipeTransform } from '@angular/core';
import { isNull, isString, isUndefined } from 'lodash-es';

@Pipe({ name: 'replace' })
export class ReplaceStringPipeline implements PipeTransform {
  transform(value: string, replace: string, replaceWith: string): string {
    if (isNull(value) || isUndefined(value) || !isString(value)) {
      return '';
    }
    return value.replace(replace, replaceWith);
  }
}
