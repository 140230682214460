<p
  *ngIf="status === 'UNPUBLISHED' || status === 'ARCHIVED_BEFORE'"
  class="quote-estimate"
>
  Quote estimate: <b> £{{ quoteEstimate }}</b>
</p>

<p *ngIf="status === 'PUBLISHED_VIEWED'" class="brown">
  <ate-icon name="info-v2"></ate-icon>
  Please respond to quote
</p>

<p *ngIf="status === 'ACCEPTED_FUTURE'" class="green">
  <ate-icon name="check-circle-v2"></ate-icon>
  You have chosen this supplier
</p>

<p *ngIf="status === 'PUBLISHED_NEW'" class="blue">
  <ate-icon name="info-v2"></ate-icon>
  Please read and respond to quote
</p>

<p *ngIf="status === 'ACCEPTED_PAST'" class="blue">
  <ate-icon name="star"></ate-icon>
  Leave a review for the supplier
</p>

<p *ngIf="status === 'PUBLISHED_PAST'" class="grey">
  <ate-icon name="info-v2"></ate-icon>
  The event date has passed
</p>

<p *ngIf="status === 'PUBLISHED_REVIEWED'" class="green">
  <ate-icon name="check-circle-v2"></ate-icon>
  You wrote a review for this supplier
</p>

<p *ngIf="status === 'REJECTED_PAST' || status === 'REJECTED_FUTURE'">
  <ate-icon name="info-v2"></ate-icon>
  You declined this quote
</p>
