import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, Observable, shareReplay, throwError } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import {
  EventRequestCloseReasons,
  EventRequestQuotes,
  EventRequestSingleType,
  EventRequestType,
} from './event-request.type';
import { PaginationType } from '../../../../shared/services/types/pagination.type';
import { EventRequestStatusEnum } from './event-request-status.enum';
import { EventRequestColorEnum } from '../../../../ui/components/status-pill/status-pill-color.enum';
import { Router } from '@angular/router';
import { EventOverviewPageConfig } from '../../interfaces/event-overview-page-config.interface';
import { EventState, eventActions } from '../../state';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class EventRequestService {
  constructor(
    private router: Router,
    private http: HttpClient,
    private _store: Store<EventState>,
  ) {}

  getActiveEventRequests(): Observable<PaginationType<EventRequestType>> {
    return this.http.get<PaginationType<EventRequestType>>(
      environment.apiUrl + '/org/event-requests/active',
      {
        headers: {
          'Content-Type': 'application/ate.organiser.v1+json',
        },
      },
    );
  }

  getInactiveEventRequests(): Observable<PaginationType<EventRequestType>> {
    return this.http.get<PaginationType<EventRequestType>>(
      environment.apiUrl + '/org/event-requests/inactive',
      {
        headers: {
          'Content-Type': 'application/ate.organiser.v1+json',
        },
      },
    );
  }

  getOneRequest(
    eventUuid: string,
    eventRequestUuid: string,
  ): Observable<EventRequestSingleType> {
    return this.http
      .get<EventRequestSingleType>(
        environment.apiUrl + '/org/event/' + eventUuid,
        {
          headers: {
            'Content-Type': 'application/ate.organiser.v1+json',
          },
          params: {
            eventRequestUuid,
          },
        },
      )
      .pipe(
        shareReplay(1),
        catchError(err => this.handleError(err)),
      );
  }

  processStatus(
    eventRequestOrganiserStatus: EventRequestStatusEnum,
  ): { active: boolean; status: EventRequestColorEnum; statusText: string } {
    let statusText = '';
    let status: EventRequestColorEnum;
    let active = true;

    switch (eventRequestOrganiserStatus) {
      case EventRequestStatusEnum.UNAUTHENTICATED:
        statusText = '';
        active = false;
        break;
      case EventRequestStatusEnum.CLOSED_BEFORE_APPROVAL:
        statusText = 'CLOSED';
        status = EventRequestColorEnum.GREY;
        active = false;
        break;
      case EventRequestStatusEnum.DENIED_FLOOD:
        statusText = 'DENIED';
        status = EventRequestColorEnum.RED;
        active = false;
        break;
      case EventRequestStatusEnum.DENIED_EMAILS:
        statusText = 'DENIED';
        status = EventRequestColorEnum.RED;
        active = false;
        break;
      case EventRequestStatusEnum.DENIED_DUPLICATE:
        statusText = 'DENIED';
        status = EventRequestColorEnum.RED;
        active = false;
        break;
      case EventRequestStatusEnum.DENIED_MANUAL:
        statusText = 'DENIED';
        status = EventRequestColorEnum.RED;
        active = false;
        break;
      case EventRequestStatusEnum.AWAITING_APPROVAL:
        statusText = 'AWAITING APPROVAL';
        status = EventRequestColorEnum.BLUE_OUTLINE;
        break;
      case EventRequestStatusEnum.CLOSED_NO_MATCHES:
        statusText = 'CLOSED';
        status = EventRequestColorEnum.GREY;
        active = false;
        break;
      case EventRequestStatusEnum.AWAITING_QUOTES:
        statusText = 'AWAITING QUOTES';
        status = EventRequestColorEnum.BLUE_OUTLINE;
        break;
      case EventRequestStatusEnum.ALL_QUOTES_DECLINED:
        statusText = 'AWAITING QUOTES';
        status = EventRequestColorEnum.BLUE;
        break;
      case EventRequestStatusEnum.FIRST_QUOTE:
        statusText = 'FIRST QUOTE';
        status = EventRequestColorEnum.BLUE;
        break;
      case EventRequestStatusEnum.NEW_QUOTE:
        statusText = 'NEW QUOTE';
        status = EventRequestColorEnum.BLUE;
        break;
      case EventRequestStatusEnum.QUOTES_RECEIVED:
        statusText = 'QUOTES RECEIVED';
        status = EventRequestColorEnum.BLUE;
        break;
      case EventRequestStatusEnum.SUPPLIER_CHOSEN_OPEN:
      case EventRequestStatusEnum.SUPPLIER_CHOSEN_NO_OPEN:
        statusText = 'SUPPLIER CHOSEN';
        status = EventRequestColorEnum.GREEN;
        break;
      case EventRequestStatusEnum.EXPIRED_SUPPLIER_CHOSEN:
        statusText = 'SUPPLIER CHOSEN';
        status = EventRequestColorEnum.GREEN;
        break;
      case EventRequestStatusEnum.CLOSED_EXPIRED_NO_QUOTES:
        statusText = 'CLOSED';
        status = EventRequestColorEnum.GREY;
        active = false;
        break;
      case EventRequestStatusEnum.EXPIRED_ALL_DECLINED_QUOTES:
        statusText = 'CLOSED';
        status = EventRequestColorEnum.GREY;
        active = false;
        break;
      case EventRequestStatusEnum.EXPIRED_ACTIVE_QUOTES:
        statusText = 'QUOTES RECEIVED';
        status = EventRequestColorEnum.BLUE;
        break;
      case EventRequestStatusEnum.CLOSED_PASSED:
        statusText = 'CLOSED';
        status = EventRequestColorEnum.GREY;
        active = false;
        break;
      case EventRequestStatusEnum.CLOSED_REASON_NO_QUOTES:
      case EventRequestStatusEnum.CLOSED_REASON_OPEN_QUOTES:
      case EventRequestStatusEnum.CLOSED_REASON_ALL_DECLINED_QUOTES:
        statusText = 'CLOSED';
        status = EventRequestColorEnum.GREY;
        active = false;
        break;
      case EventRequestStatusEnum.CLOSED_REASON_SUPPLIER_CHOSEN_OPEN:
      case EventRequestStatusEnum.CLOSED_REASON_SUPPLIER_CHOSEN_NO_OPEN:
        statusText = 'SUPPLIER CHOSEN';
        status = EventRequestColorEnum.GREEN;
        break;
    }

    return { statusText, status, active };
  }

  /**
   * Since each status has its own configuration for the request overview page
   * we need to map over the statuses and build up the config for the page to use.
   *
   * TODO: Extract this to external JSON configuration so we can change this text without
   * making a commit to the repository.
   *
   * @param eventRequestStatus The status of the request
   * @param quotes The quotes associated with the request
   */
  public getEventOverviewPageConfig(
    eventRequestStatus: EventRequestStatusEnum,
    quotes: EventRequestQuotes[],
  ): EventOverviewPageConfig {
    const eventStatusMessages: EventOverviewPageConfig = {
      description: '',
      requestStageInfo: undefined,
      step: -1,
      progressAccordion: {
        title: 'Your Request Progress',
        subtitle: 'Compare your quotes and chat',
      },
      noQuotes: {},
      closeServiceRequestButton: false,
      footerButton: false,
    };

    switch (eventRequestStatus as EventRequestStatusEnum) {
      case EventRequestStatusEnum.UNAUTHENTICATED:
        eventStatusMessages.description = '';
        break;
      case EventRequestStatusEnum.CLOSED_BEFORE_APPROVAL:
        eventStatusMessages.footerButton = true;
        eventStatusMessages.description =
          'You have chosen to close your service request & will no longer receive quotes. <b>Need something different? Find more services for your upcoming event or start planning your next one by submitting a new request</b>';
        eventStatusMessages.noQuotes.simpleMessage =
          'Your service request has been closed and you will no longer receive quotes.';
        eventStatusMessages.requestStageInfo = {
          title: 'Request Summary',
          description:
            'Your service request has been closed and you will no longer receive quotes.',
          ctaMessage:
            'Click below to open a new request for your upcoming event.',
          displayCta: true,
        };
        break;
      case EventRequestStatusEnum.DENIED_FLOOD:
        eventStatusMessages.description =
          'Your service request cannot be sent at this time. Please try again in 24 hours.';
        eventStatusMessages.requestStageInfo = {
          message:
            'Your service request cannot be sent at this time. Please try again in 24 hours.',
          title: 'Why has my request been denied?',
          description:
            'Your request can be denied for a variety of reasons such as our system has detected it is a duplicate of a previous event request you submitted or you have already maximised your request limit for the day.' +
            '<br /><br /> For help submitting a new request, please contact <a href="mailto: support@addtoevent.co.uk">support@addtoevent.co.uk</a> with information about your event and denied request.',
        };
        eventStatusMessages.noQuotes.simpleMessage =
          'Your service request has been denied so cannot be sent at this time.';
        break;
      case EventRequestStatusEnum.DENIED_EMAILS:
        eventStatusMessages.description =
          'Your service request cannot be sent at this time.';
        eventStatusMessages.requestStageInfo = {
          message: 'Your service request cannot be sent at this time.',
          title: 'Why has my request been denied?',
          description:
            'Your request can be denied for a variety of reasons such as our system has detected it is a duplicate of a previous event request you submitted or you have already maximised your request limit for the day.' +
            '<br /><br /> For help submitting a new request, please contact <a href="mailto: support@addtoevent.co.uk">support@addtoevent.co.uk</a> with information about your event and denied request.',
        };
        eventStatusMessages.noQuotes.simpleMessage =
          'Your service request has been denied so cannot be sent at this time.';
        break;
      case EventRequestStatusEnum.DENIED_DUPLICATE:
        eventStatusMessages.description =
          'Your service request cannot be sent at this time.';
        eventStatusMessages.requestStageInfo = {
          message: 'Your service request cannot be sent at this time.',
          title: 'Why has my request been denied?',
          description:
            'Your request can be denied for a variety of reasons such as our system has detected it is a duplicate of a previous event request you submitted or you have already maximised your request limit for the day.' +
            '<br /><br /> For help submitting a new request, please contact <a href="mailto: support@addtoevent.co.uk">support@addtoevent.co.uk</a> with information about your event and denied request.',
        };
        eventStatusMessages.noQuotes.simpleMessage =
          'Your service request has been denied so cannot be sent at this time.';
        break;
      case EventRequestStatusEnum.DENIED_MANUAL:
        eventStatusMessages.description =
          'Your service request cannot be sent at this time.';
        eventStatusMessages.requestStageInfo = {
          message: 'Your service request cannot be sent at this time.',
          title: 'Why has my request been denied?',
          description:
            'Your request can be denied for a variety of reasons such as our system has detected it is a duplicate of a previous event request you submitted or you have already maximised your request limit for the day.' +
            '<br /><br /> For help submitting a new request, please contact <a href="mailto: support@addtoevent.co.uk">support@addtoevent.co.uk</a> with information about your event and denied request.',
        };
        eventStatusMessages.noQuotes.simpleMessage =
          'Your service request has been denied so cannot be sent at this time.';
        break;
      case EventRequestStatusEnum.AWAITING_APPROVAL:
        eventStatusMessages.description =
          'Our team is reviewing your service request. This usually takes a few minutes.';
        eventStatusMessages.step = 0;
        eventStatusMessages.progressAccordion.subtitle =
          'Currently awaiting approval...';
        eventStatusMessages.closeServiceRequestButton = true;
        eventStatusMessages.noQuotes.detailedMessage = {
          noQuotesHeader: 'Your request is awaiting approval',
          svg: 'assets/states/awaiting-approval.svg',
          message: `Our team is reviewing your service request. This usually takes less than a minute.`,
          displayRefresh: true,
        };
        break;
      case EventRequestStatusEnum.CLOSED_NO_MATCHES:
        eventStatusMessages.footerButton = true;
        eventStatusMessages.description =
          'We’re sorry, at the moment no suppliers are available to fulfil your request. <b>Click below to open a new request for your upcoming event.</b>';

        eventStatusMessages.requestStageInfo = {
          title: 'Why did this happen?',
          description:
            'Unfortunately at this time no suppliers are available to attend your event, so your request has been closed.' +
            '<br/><br/>Sometimes this happens because suppliers don’t have enough information. Try adding more details like the number of guests or specific location, changing the date & location, or submitting a new request for a different category (e.g. if you submitted a request for ‘horsebox bars’, try a more generic search like ‘mobile bars’ to get matched with more available suppliers).',
          displayCta: true,
        };
        break;
      case EventRequestStatusEnum.AWAITING_QUOTES:
        eventStatusMessages.description =
          '<b>Your service request is live and has been sent to matched suppliers.</b> Check back soon for quotes!';
        eventStatusMessages.progressAccordion = {
          ...eventStatusMessages.progressAccordion,
          subtitle: 'Waiting for personalised quotes...',
        };
        eventStatusMessages.step = 1;
        eventStatusMessages.closeServiceRequestButton = true;
        eventStatusMessages.noQuotes = {
          detailedMessage: {
            noQuotesHeader: 'Your quotes will live here',
            svg: 'assets/states/awaiting-quotes.svg',
            message: `Your service request is live and has been sent to matched suppliers.
            You should start receiving your first quotes in a few hours. You’ll receive these quotes via email too.`,
          },
        };
        break;
      case EventRequestStatusEnum.ALL_QUOTES_DECLINED:
        eventStatusMessages.description =
          '<b>Your service request is still live with suppliers.</b> Check back soon for more quotes!';
        eventStatusMessages.progressAccordion = {
          ...eventStatusMessages.progressAccordion,
          subtitle: 'Waiting for personalised quotes...',
        };
        eventStatusMessages.step = 1;
        eventStatusMessages.closeServiceRequestButton = true;
        eventStatusMessages.noQuotes.simpleMessage =
          'Awaiting quotes! Check back soon for quotes on your request.';
        break;
      case EventRequestStatusEnum.FIRST_QUOTE:
        eventStatusMessages.description = `Congrats! 🎉 You have your first quote from <b>${quotes[0]?.listingTitle}</b>. Review it below. `;
        eventStatusMessages.step = 2;
        eventStatusMessages.closeServiceRequestButton = true;
        break;
      case EventRequestStatusEnum.NEW_QUOTE:
        eventStatusMessages.description = `You have <b>1 new quote</b> from <b>${
          quotes[quotes?.length - 1]?.listingTitle
        }</b>. Review it below.`;
        eventStatusMessages.step = 2;
        eventStatusMessages.closeServiceRequestButton = true;
        break;
      case EventRequestStatusEnum.QUOTES_RECEIVED:
        eventStatusMessages.description =
          'You’ve received a range of quotes for your request. <b>Review, compare, and choose your favourite below.</b>';
        eventStatusMessages.step = 2;
        eventStatusMessages.closeServiceRequestButton = true;
        break;
      case EventRequestStatusEnum.SUPPLIER_CHOSEN_OPEN:
      case EventRequestStatusEnum.SUPPLIER_CHOSEN_NO_OPEN:
        eventStatusMessages.description =
          '<b>Great! You’ve chosen your supplier for this service request.</b> Now sit back and relax! 😎';
        eventStatusMessages.step = 4;
        eventStatusMessages.progressAccordion.subtitle =
          'You’ve chosen your supplier!';
        eventStatusMessages.closeServiceRequestButton = true;
        break;
      case EventRequestStatusEnum.EXPIRED_SUPPLIER_CHOSEN:
        eventStatusMessages.description =
          '<b>Great! You’ve chosen your supplier for this service request.</b> Now sit back and relax! 😎';
        eventStatusMessages.step = 4;
        eventStatusMessages.progressAccordion.subtitle =
          'You’ve chosen your supplier!';
        break;
      case EventRequestStatusEnum.CLOSED_EXPIRED_NO_QUOTES:
        eventStatusMessages.footerButton = true;
        eventStatusMessages.description =
          'We’re sorry, at the moment no suppliers are available to fulfil your request. <b>Click below to open a new request for your upcoming event.</b>';

        eventStatusMessages.requestStageInfo = {
          title: 'Why did this happen?',
          description:
            'Unfortunately at this time no suppliers are available to attend your event, so your request has been closed.' +
            '<br/><br/>Sometimes this happens because suppliers don’t have enough information. Try adding more details like the number of guests or specific location, changing the date & location, or submitting a new request for a different category (e.g. if you submitted a request for ‘horsebox bars’, try a more generic search like ‘mobile bars’ to get matched with more available suppliers).',
          displayCta: true,
        };
        eventStatusMessages.noQuotes.simpleMessage =
          'Your service request has been closed as no suppliers were available to fulfil your request.';
        break;

      case EventRequestStatusEnum.EXPIRED_ALL_DECLINED_QUOTES:
        eventStatusMessages.footerButton = true;
        eventStatusMessages.description =
          'Your request has been closed because suppliers can no longer quote on it.';
        eventStatusMessages.requestStageInfo = {
          title: 'Why did this happen?',
          description:
            'More than seven days have passed since you submitted your request so we have closed the request because it is unlikely any more suppliers will quote after this length of time.' +
            '<br/><br/>We are sorry that none of the quotes you received were suitable for your event.' +
            '<br/><br/>To ensure that our suppliers can provide you with an exceptional quote, kindly submit a new request for your event, including any additional details or information that would be helpful.',
          displayCta: true,
        };
        eventStatusMessages.noQuotes.simpleMessage =
          'Your service request has been closed as no suppliers were available to fulfil your request.';
        break;

      case EventRequestStatusEnum.EXPIRED_ACTIVE_QUOTES:
        eventStatusMessages.description =
          'You’ve received a range of quotes for your request. <b>Review, compare, and choose your favourite below.</b>';
        eventStatusMessages.step = 2;
        break;
      case EventRequestStatusEnum.CLOSED_PASSED:
        eventStatusMessages.footerButton = true;
        eventStatusMessages.description =
          'The date you listed for your event has passed so your request has been closed. We hope your event went off without a hitch!';
        eventStatusMessages.noQuotes.simpleMessage =
          'Your event date has passed. Please submit a new request.';
        eventStatusMessages.requestStageInfo = {
          title: 'Request Summary',
          description:
            'The date you listed for your event has passed so your request has been closed. We hope your event went off without a hitch!',
          ctaMessage:
            'Start planning your next one with a range of unique suppliers by submitting a new request.',
          displayCta: true,
        };
        break;
      case EventRequestStatusEnum.CLOSED_REASON_NO_QUOTES:
      case EventRequestStatusEnum.CLOSED_REASON_OPEN_QUOTES:
      case EventRequestStatusEnum.CLOSED_REASON_ALL_DECLINED_QUOTES:
        eventStatusMessages.footerButton = true;
        eventStatusMessages.description =
          'You have chosen to close your service request & will no longer receive quotes. <br/> <br/><b>Need something different? Find more services for your upcoming event or start planning your next one by submitting a new request.</b>';
        eventStatusMessages.requestStageInfo = {
          title: 'Request Summary',
          description:
            'You have chosen to close your service request & will no longer receive quotes.',
          ctaMessage:
            'Need something different? Find more services for your upcoming event or start planning your next one by submitting a new request.',
          displayCta: true,
        };
        break;
      case EventRequestStatusEnum.CLOSED_REASON_SUPPLIER_CHOSEN_OPEN:
      case EventRequestStatusEnum.CLOSED_REASON_SUPPLIER_CHOSEN_NO_OPEN:
        eventStatusMessages.description =
          '<b>Great! You’ve chosen your supplier for this service request.</b> Now sit back and relax! 😎';
        eventStatusMessages.step = 4;
        eventStatusMessages.progressAccordion.subtitle =
          'You’ve chosen your supplier!';
        break;
    }

    return eventStatusMessages;
  }

  public closeEvent(
    eventRequestUuid: string,
    eventUuid: string,
    reasons: EventRequestCloseReasons,
  ): Observable<void> {
    return this.http
      .post<void>(
        environment.apiUrl + `/org/event-request/${eventRequestUuid}/close`,
        {
          reasons,
        },
        {
          headers: {
            'Content-Type': 'application/ate.organiser.v1+json',
          },
        },
      )
      .pipe(
        map(() => {
          // Refresh the event with the closed state
          this._store.dispatch(
            eventActions.GetEvent({ payload: { eventUuid, eventRequestUuid } }),
          );
        }),
      );
  }

  // @TODO
  // abstract
  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error,
      );
    }

    // if 400 error issue with app, wrong version?
    if (error.status >= 400 && error.status < 500 && error.status !== 401) {
      this.router.navigate(['error']);
      return throwError(() => new Error('error'));
    }

    // Service offline, not issue with app version so allow try again
    return throwError(
      () => new Error('Something bad happened; please try again later.'),
    );
  }
}
