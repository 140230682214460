import { Component, OnDestroy, OnInit } from '@angular/core';
import { formatDuration, intervalToDuration } from 'date-fns';

import {
  EventRequestSingleType,
  IFormResponse,
  FormResponse,
} from '../../services/event-request/event-request.type';
import { EventRequestService } from '../../services/event-request/event-request.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { Store } from '@ngrx/store';
import { EventState, selectEvent } from '../../state';
import { Subscription, filter } from 'rxjs';

@Component({
  selector: 'app-event-request-details',
  templateUrl: './event-request-details.component.html',
  styleUrls: ['./event-request-details.component.scss'],
})
export class EventRequestDetailsComponent implements OnInit, OnDestroy {
  apiKey = environment.googleMapsApiKey;
  isLoading = true;
  eventRequest: EventRequestSingleType;
  public eventDescription: string;
  public isError = false;
  private eventSubscription: Subscription;

  formResponses: FormResponse[];
  eventStartDate: string;
  eventStartTime: string;
  eventDuration: string;

  constructor(
    public eventRequestService: EventRequestService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<EventState>,
  ) {}

  public ngOnInit(): void {
    this.subscribeToEvent();
  }

  public ngOnDestroy(): void {
    this.eventSubscription.unsubscribe();
  }

  goBack(): void {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  public subscribeToEvent(): void {
    this.isLoading = true;
    this.isError = false;
    this.eventSubscription = this.store
      .select(selectEvent.getEvent)
      .pipe(filter(value => value !== undefined))
      .subscribe({
        next: data => {
          if (data) {
            this.isLoading = false;
            this.eventRequest = data;
            const {
              startDate,
              startTime,
              duration,
              description,
            } = this.eventRequest.event;

            this.eventDescription = description;
            this.eventStartDate = this.processStartDate(startDate);
            this.eventStartTime = this.processStartTime(startTime);
            this.eventDuration = this.processDuration(duration);

            this.formResponses = this.handleFormResponse(
              this.eventRequest.responses,
            );
          }
        },
        error: msg => {
          this.isError = true;
          console.error(msg);
          this.isLoading = false;
        },
      });
  }

  /**
   * Returns the time as an internationalised string.
   * @param startTime The string representation of the time
   */
  private processStartTime(startTime: string): string {
    if (!startTime) {
      return '';
    }
    const time = new Date(startTime);
    const intlTime = new Intl.DateTimeFormat('en-GB', {
      hour: 'numeric',
      minute: 'numeric',
    }).format(time);

    return intlTime;
  }

  /**
   * Returns the time as an internationalised string. (e.g. 2 hours 20 minutes)
   * @param duration The number of seconds
   */
  private processDuration(duration: number): string {
    if (!duration) {
      return '';
    }
    return formatDuration(
      intervalToDuration({ start: 0, end: duration * 1000 }),
      { format: ['days', 'hours', 'minutes'] },
    );
  }

  /**
   * Returns the date as an internationalised string.
   * @param startDate The string representation of the date
   */
  private processStartDate(startDate: string): string {
    const date = new Date(startDate);

    return new Intl.DateTimeFormat('en-GB', {
      dateStyle: 'medium',
    }).format(date);
  }

  /**
   * Parses the form response so that we can display the responses easily in
   * the view.
   *
   * @param formResponse The form response object
   */
  private handleFormResponse(formResponse: IFormResponse): FormResponse[] {
    return Object.values(formResponse).reduce<FormResponse[]>(
      (responses, answer) => {
        if (!answer.value) {
          return;
        }

        return [
          ...responses,
          { ...answer, isArray: Array.isArray(answer.value) },
        ];
      },
      [],
    );
  }
}
