import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListingReviewsComponent } from '../../ui/components/listing-reviews/listing-reviews.component';
import { ListingComponent } from './pages/listing/listing.component';

const routes: Routes = [
  {
    path: ':uuid',
    component: ListingComponent,
  },
  {
    path: ':uuid/reviews',
    component: ListingReviewsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ListingRoutingModule {}
