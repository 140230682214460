import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { EventRequestStatusEnum } from '../../services/event-request/event-request-status.enum';
import { EventRequestType } from '../../services/event-request/event-request.type';
import { EventRequestColorEnum } from '../../../../ui/components/status-pill/status-pill-color.enum';
import { formatDistanceToNow } from 'date-fns';
import { EventRequestService } from '../../services/event-request/event-request.service';

@Component({
  selector: 'app-event-request-list-item',
  templateUrl: './event-request-list-item.component.html',
  styleUrls: ['./event-request-list-item.component.scss'],
})
export class EventRequestListItemComponent implements OnInit, OnChanges {
  @Input() eventRequest: EventRequestType;

  eventStartTime: string;
  description: string;
  statusText: string;
  status: EventRequestColorEnum;
  updatedAt: string;
  active: boolean;

  constructor(public eventRequestService: EventRequestService) {}

  ngOnInit(): void {
    this.processEventRequestData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.eventRequest.currentValue) {
      this.processEventRequestData();
    }
  }

  processEventRequestData(): void {
    this.processStatus();
    this.processStartDate();
    this.processLastUpdated();
  }

  processStartDate(): void {
    const date = new Date(this.eventRequest.eventRequestServiceDate);
    this.eventStartTime = new Intl.DateTimeFormat('en-GB', {
      dateStyle: 'medium',
    }).format(date);
  }

  processLastUpdated(): void {
    this.updatedAt = formatDistanceToNow(
      new Date(this.eventRequest.dateUpdated),
    );
  }

  processStatus(): void {
    const {
      active,
      status,
      statusText,
    } = this.eventRequestService.processStatus(
      this.eventRequest.eventRequestOrganiserStatus,
    );

    this.status = status;
    this.statusText = statusText;
    this.active = active;

    switch (
      this.eventRequest.eventRequestOrganiserStatus as EventRequestStatusEnum
    ) {
      case EventRequestStatusEnum.UNAUTHENTICATED:
        this.description = '';
        break;
      case EventRequestStatusEnum.CLOSED_BEFORE_APPROVAL:
        this.description =
          'You have chosen to close your service request and will no longer receive quotes';
        break;
      case EventRequestStatusEnum.DENIED_FLOOD:
        this.description =
          'Your service request cannot be sent at this time. Please try again in 24 hours.';
        break;
      case EventRequestStatusEnum.DENIED_EMAILS:
        this.description = 'Your service request cannot be sent at this time.';
        break;
      case EventRequestStatusEnum.DENIED_DUPLICATE:
        this.description = 'Your service request cannot be sent at this time.';
        break;
      case EventRequestStatusEnum.DENIED_MANUAL:
        this.description = 'Your service request cannot be sent at this time.';
        break;
      case EventRequestStatusEnum.AWAITING_APPROVAL:
        this.description =
          'Our team is reviewing your service request. This usually takes a few minutes.';
        break;
      case EventRequestStatusEnum.CLOSED_NO_MATCHES:
        this.description =
          'We’re sorry. At the moment no suppliers are available to fulfil your request.';
        break;
      case EventRequestStatusEnum.AWAITING_QUOTES:
        this.description =
          'Your service request is live and has been sent to matched suppliers. Check back soon for quotes!';
        break;
      case EventRequestStatusEnum.ALL_QUOTES_DECLINED:
        this.description =
          'Your service request is still live with suppliers. Check back soon for more quotes!';
        break;
      case EventRequestStatusEnum.FIRST_QUOTE:
        this.description =
          'Congrats 🎉. You have your <b>first quote</b>. Review it now.';
        break;
      case EventRequestStatusEnum.NEW_QUOTE:
        this.description = `You have <b>a new quote!</b> Review it now.`;
        break;
      case EventRequestStatusEnum.QUOTES_RECEIVED:
        this.description =
          'You’ve received a range of quotes for your request. Review, compare, and choose your favourite now.';
        break;
      case EventRequestStatusEnum.EXPIRED_ACTIVE_QUOTES:
        this.description =
          'You’ve received a range of quotes for your request. Review, compare, and choose your favourite now.';
        break;
      case EventRequestStatusEnum.SUPPLIER_CHOSEN_OPEN:
      case EventRequestStatusEnum.SUPPLIER_CHOSEN_NO_OPEN:
      case EventRequestStatusEnum.CLOSED_REASON_SUPPLIER_CHOSEN_OPEN:
      case EventRequestStatusEnum.CLOSED_REASON_SUPPLIER_CHOSEN_NO_OPEN:
        this.description =
          'Great! You’ve chosen your supplier for this service request. Now sit back and relax! 😎';
        break;
      case EventRequestStatusEnum.EXPIRED_SUPPLIER_CHOSEN:
        this.description =
          'Great! You’ve chosen your supplier for this service request. Now sit back and relax! 😎';
        break;
      case EventRequestStatusEnum.CLOSED_EXPIRED_NO_QUOTES:
        this.description =
          'We’re sorry. At the moment no suppliers are available to fulfil your request.';
        break;
      case EventRequestStatusEnum.EXPIRED_ALL_DECLINED_QUOTES:
        this.description =
          'Your request has been closed because suppliers can no longer quote on it.';
        break;
      case EventRequestStatusEnum.CLOSED_PASSED:
        this.description =
          'The date you listed for your event has passed so your request has been closed.';
        break;
      case EventRequestStatusEnum.CLOSED_REASON_NO_QUOTES:
      case EventRequestStatusEnum.CLOSED_REASON_OPEN_QUOTES:
      case EventRequestStatusEnum.CLOSED_REASON_ALL_DECLINED_QUOTES:
        this.description =
          'You have chosen to close your service request & will no longer receive quotes.';
        break;
    }
  }
}
