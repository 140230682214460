import { Component, Input, ViewChild } from '@angular/core';
import { LightboxComponent } from '../lightbox/lightbox.component';

@Component({
  selector: 'app-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss'],
})
export class ImageCarouselComponent {
  @ViewChild(LightboxComponent) lightbox: LightboxComponent;
  @Input() images: string[] = [];

  openImageLightbox(image: number): void {
    this.lightbox.selectImage(image);
  }
}
