/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Database,
  ref,
  push,
  serverTimestamp,
  update,
  onValue,
  orderByChild,
  query,
} from '@angular/fire/database';
import { map, retryWhen, delay } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  IMessage,
  MessageType,
} from '../../messages-panel/message/message.type';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  constructor(private _database: Database) {}

  public getChatThread(quoteUuid: string): Observable<[IMessage[], string[]]> {
    const messages: Observable<IMessage[]> = this.getMessages(quoteUuid);
    const users: Observable<string[]> = this.getUserUuids(quoteUuid);

    return combineLatest([messages, users]).pipe(
      /**
       * ASYNC creation chat is not handle in API
       * For created quote sometimes app is querying chat before it's initialised
       * permission_denied is error for that case
       * so there's no way to break retrying for permission_denied
       */
      retryWhen(err => {
        return err.pipe(delay(1000));
      }),
    );
  }
  /**
   * Gets the users for a given quote.
   * @param quoteUuid the quote to get the users for.
   *
   * TODO: Get this working with the angular/fire methods.
   */
  private getUserUuids(quoteUuid: string): Observable<string[]> {
    const reference = ref(this._database, `chat/threads/${quoteUuid}/users`);

    return new Observable<string[]>(observer => {
      return onValue(
        reference,
        snapshot => observer.next(snapshot.val()),
        error => observer.error(error.message),
      );
    }).pipe(map(users => Object.keys(users)));
  }

  /**
   * Gets the messages for a given quote.
   * @param quoteUuid the quote to get the messages for.
   *
   * TODO: Get this working with the angular/fire methods.
   */
  private getMessages(quoteUuid: string): Observable<IMessage[]> {
    const reference = ref(this._database, `chat/threads/${quoteUuid}/messages`);

    return new Observable<string[]>(observer => {
      return onValue(
        query(reference, orderByChild('timestamp')),
        snapshot => observer.next(snapshot.val()),
        error => observer.error(error.message),
      );
    }).pipe(
      map((list: any) =>
        Object.keys(list).map(key => ({ id: key, ...list[key] })),
      ),
      map((list: any) =>
        list.sort((a, b) => {
          if (a.timestamp < b.timestamp) {
            return -1;
          } else if (a.timestamp > b.timestamp) {
            return 1;
          } else {
            return 0;
          }
        }),
      ),
    );
  }

  public sendMessage(text: string, quoteUuid: string, userId: string) {
    const reference = ref(this._database, `chat/threads/${quoteUuid}/messages`);

    return push(reference, {
      text,
      type: MessageType.MESSAGE,
      userId,
      timestamp: serverTimestamp(),
    });
  }

  public sendNote(
    organisationId: string,
    userId: string,
    quoteUuid: string,
    note: string,
  ) {
    const reference = ref(
      this._database,
      `/chat/notes/${organisationId}/${userId}/${quoteUuid}`,
    );

    push(reference, {
      note,
      timestamp: serverTimestamp(),
    });
  }

  public markAsSeen(quoteUuid: string, message: IMessage, userId: string) {
    const reference = ref(
      this._database,
      `chat/threads/${quoteUuid}/messages/${message.id}`,
    );
    const seen = {};
    seen[userId] = serverTimestamp();

    update(reference, {
      seen,
    });
  }
}
