import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { QuoteStatus } from '../quote.type';
import { Router } from '@angular/router';
import { EventRequestColorEnum } from '../../status-pill/status-pill-color.enum';
import { QuoteService } from '../../../../features/quotes/services/quote.service';
import { EventDeclineReason } from '../../../../api/enums';
import { isNull, isUndefined } from 'lodash-es';

@Component({
  selector: 'app-quote-list-item',
  templateUrl: './quote-list-item.component.html',
  styleUrls: ['./quote-list-item.component.scss'],
})
export class QuoteListItemComponent implements OnInit, OnChanges {
  @Input() quote: {
    uuid: string;
    dateCreated: string;
    dateUpdated: string;
    quoteOrganiserStatus?: QuoteStatus;
    listingReviewRating: number;
    listingReviewCount: number;
    quotePrice: number;
    listingTitle: string;
    listingServiceImageURI: string;
    totalMessageCount?: number;
    reason?: EventDeclineReason;
  };

  statusText = '';
  statusColor: EventRequestColorEnum;
  statusReason = null;

  constructor(public quoteService: QuoteService, private _router: Router) {}

  ngOnInit(): void {
    this.processStatus();
    this.processReason();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.quote.currentValue) {
      this.processStatus();
    }
  }

  processStatus(): void {
    const { statusText, statusColor } = this.quoteService.processStatus(
      this.quote.quoteOrganiserStatus,
    );

    this.statusText = statusText;
    this.statusColor = statusColor;
  }

  processReason(): void {
    if (!isUndefined(this.quote.reason) && !isNull(this.quote.reason)) {
      this.statusReason = this.quoteService.processSupplierDelineReason(
        this.quote.reason,
      );
    }
  }

  goToQuote(): void {
    if (!this.statusReason) {
      this._router.navigate(['/quote/', this.quote.uuid]);
    }
  }
}
