import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { EventRequestColorEnum } from '../../../../../ui/components/status-pill/status-pill-color.enum';
import { WindowService } from '../../../../../shared/services/window/window.service';
import { QuoteBodyType } from '../../../components/quote-body/quote-body.type';
import { QuoteResponse } from '../../../services/quote.type';
import { SupplierListingModalComponent } from '../../../../../shared/standalone/modals/supplier-listing-modal/supplier-listing-modal.component';

@Component({
  selector: 'app-quote-request-desktop',
  templateUrl: './quote-request-desktop.component.html',
  styleUrls: ['./quote-request-desktop.component.scss'],
})
export class QuoteRequestDesktopComponent {
  @ViewChild(SupplierListingModalComponent)
  public supplierListingModalComponent: SupplierListingModalComponent;
  @Input() public quote: QuoteResponse;
  @Input() public quoteBody: QuoteBodyType;
  @Input() public statusText = '';
  @Input() public statusColor: EventRequestColorEnum;
  @Input() public quoteUuid: string;

  constructor(public windowService: WindowService, private router: Router) {}

  public goBack(): void {
    this.router.navigate([
      '../../event-request',
      this.quote.event.eventUuid,
      this.quote.event.eventRequestUuid,
    ]);
  }

  /**
   * Triggers a phone call to the number provided.
   */
  public callPhoneNumber() {
    if (!this.quote?.listing?.phoneNumber) {
      return;
    }
    this.windowService.open(`tel:${this.quote?.listing?.phoneNumber}`);
  }

  public goToListing(): void {
    this.supplierListingModalComponent.open();
  }
}
