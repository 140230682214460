import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventTypePipeline } from './pipes/eventType.pipeline';
import { FilenamePipeline } from './pipes/filename.pipeline';
import { LinebreakPipeline } from './pipes/linebreak.pipeline';
import { LongFormDatePipeline } from './pipes/longFormDate.pipeline';
import { ReplaceStringPipeline } from './pipes/replaceString.pipeline';
import { ShortFormDatePipeline } from './pipes/shortFormDate.pipeline';
import { ShortNamePipeline } from './pipes/shortName.pipeline';
import { AuthenticationWrapperGuard } from './guards/auth/authentication-wrapper.guard';
import { AuthenticationGuard } from './guards/auth/authentication.guard';
import { MaintenanceGuard } from './guards/maintenance/maintenance.guard';
import { AuthService } from './services/auth/auth.service';
import { MaintenanceService } from './services/maintence/maintenance.service';
import { JWTService } from './services/jwt/jwt.service';
import { OutsideClickDirective } from './directives/outside-click.directive';
import { AppversionService } from './services/version/appversion_service';
import { DatalayerService } from './services/datalayer/datalayer.service';
import { PageContextService } from './services/page-context/page-context.service';

@NgModule({
  declarations: [
    EventTypePipeline,
    FilenamePipeline,
    LinebreakPipeline,
    LongFormDatePipeline,
    ReplaceStringPipeline,
    ShortFormDatePipeline,
    ShortNamePipeline,
    OutsideClickDirective,
  ],
  exports: [
    ShortNamePipeline,
    ShortFormDatePipeline,
    ReplaceStringPipeline,
    LongFormDatePipeline,
    LinebreakPipeline,
    FilenamePipeline,
    EventTypePipeline,
    OutsideClickDirective,
  ],
  providers: [
    MaintenanceGuard,
    MaintenanceService,
    AuthenticationGuard,
    AuthenticationWrapperGuard,
    AuthService,
    JWTService,
    AppversionService,
    DatalayerService,
    AppversionService,
    PageContextService,
  ],
  imports: [CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
