<app-page-container>
  <section>
    <header>
      <div>
        <h1>My Requests</h1>
      </div>
    </header>

    <app-tabs (TabSelected)="tabSelected($event)">
      <app-tab [title]="'Active Requests'">
        <div class="container" *ngIf="!isLoading && !isError">
          <app-event-request-list-item
            *ngFor="let eventRequest of activeData"
            [eventRequest]="eventRequest"
            (click)="
              openEventRequest(
                eventRequest.eventUuid,
                eventRequest.eventRequestUuid
              )
            "
          ></app-event-request-list-item>

          <app-active-empty-message-box *ngIf="activeData.length === 0">
            <ate-button-control
              class="empty-message-button"
              color="cta"
              (click)="openRequestQuotes()"
            >
              <ate-icon name="plus" slot="start"></ate-icon>
              Add Service Request
            </ate-button-control>
          </app-active-empty-message-box>

          <button
            class="submit-request desktop"
            [ngClass]="inactiveData.length % 2 === 0 ? 'full' : ''"
            *ngIf="activeData.length !== 0"
            color="cta"
            (click)="openRequestQuotes()"
          >
            <ate-icon name="plus" slot="start"></ate-icon>
            Submit a new service request
          </button>
        </div>
      </app-tab>
      <app-tab [title]="'Inactive Requests'">
        <div class="container" *ngIf="!isLoading && !isError">
          <app-event-request-list-item
            *ngFor="let eventRequest of inactiveData"
            [eventRequest]="eventRequest"
            (click)="
              openEventRequest(
                eventRequest.eventUuid,
                eventRequest.eventRequestUuid
              )
            "
          ></app-event-request-list-item>
          <app-empty-message
            *ngIf="inactiveData.length === 0"
            [message]="'You don’t have any inactive service requests'"
          ></app-empty-message>

          <button
            class="submit-request desktop"
            [ngClass]="inactiveData.length % 2 === 0 ? 'full' : ''"
            *ngIf="inactiveData.length !== 0"
            color="cta"
            (click)="openRequestQuotes()"
          >
            <ate-icon name="plus" slot="start"></ate-icon>
            Submit a new service request
          </button>
        </div>
      </app-tab>
    </app-tabs>

    <app-empty-message
      *ngIf="isError"
      class="error"
      icon="alert-v2"
      message="Unknown Error: Something went wrong"
    ></app-empty-message>

    <div class="spinner">
      <ate-spinner *ngIf="isLoading"></ate-spinner>
    </div>

    <ate-button-control color="cta" (click)="openRequestQuotes()">
      <ate-icon name="plus" slot="start"></ate-icon>
      Add Service Request
    </ate-button-control>
  </section>
</app-page-container>
