import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventRequestColorEnum } from '../../../../../ui/components/status-pill/status-pill-color.enum';
import { EventRequestService } from '../../../services/event-request/event-request.service';
import {
  EventRequestSingleType,
  IAffiliate,
  NamedUpsells,
} from '../../../services/event-request/event-request.type';
import { EventOverviewPageConfig } from '../../../interfaces/event-overview-page-config.interface';
import { eventActions, selectEvent } from '../../../state';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DatalayerService } from '../../../../../shared/services/datalayer/datalayer.service';
import { affiliates } from '../affiliates';

@Component({
  selector: 'app-event-request-overview-mobile',
  templateUrl: './event-request-overview-mobile.component.html',
  styleUrls: ['./event-request-overview-mobile.component.scss'],
})
export class EventRequestOverviewMobileComponent implements OnInit {
  @Input() public eventRequest: EventRequestSingleType;
  @Input() public statusText: string;
  @Input() public status: EventRequestColorEnum;
  @Input() public config: EventOverviewPageConfig;
  public eventStartTime: string;
  public upsells: NamedUpsells;
  public affiliate: IAffiliate;
  public isRefreshing$: Observable<boolean>;

  private currentRouteEventUuid: string;
  private currentRouteRequestUuid: string;

  constructor(
    public eventRequestService: EventRequestService,
    private _store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private _dataLayer: DatalayerService,
  ) {}

  ngOnInit(): void {
    this.currentRouteEventUuid = this.route.snapshot.paramMap.get('eventUuid');
    this.currentRouteRequestUuid = this.route.snapshot.paramMap.get(
      'eventRequestUuid',
    );

    this.isRefreshing$ = this._store.select(selectEvent.getIsLoading);

    this.upsells = this.eventRequest?.upsells;
    this.affiliate = this.getAffiliate();

    this.processStartDate();
  }

  goBack(): void {
    this.router.navigate(['/']);
  }

  openEventRequestDetails(): void {
    this.router.navigate(['details'], { relativeTo: this.route });
  }

  processStartDate(): void {
    const date = new Date(this.eventRequest?.event?.startDate);
    if (date) {
      this.eventStartTime = new Intl.DateTimeFormat('en-GB', {
        dateStyle: 'medium',
      }).format(date);
    }
  }

  openCloseEventRequest(): void {
    this.router.navigate(['close'], { relativeTo: this.route });
  }

  upsellEvent(name: string, type: string): void {
    this._dataLayer.sendEvent(name, {
      type,
      eventRequestUuid: this.currentRouteRequestUuid,
    });
  }

  getAffiliate(): IAffiliate {
    const affiliate = this.eventRequest?.affiliate;
    if (affiliate?.title) {
      Object.assign(
        affiliate,
        affiliates.find(item => item.title === affiliate.title),
      );
    }
    return affiliate;
  }

  public onRefreshPage() {
    this._store.dispatch(
      eventActions.GetEvent({
        payload: {
          eventUuid: this.currentRouteEventUuid,
          eventRequestUuid: this.currentRouteRequestUuid,
        },
      }),
    );
  }
}
