import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreModule } from '@ngrx/store';
import { ApiErrorHandlerService } from '../shared/services/errors/api-error-handler.service';
import {
  API_FEATURE_KEY,
  apiReducer,
  initialState,
} from '../store/reducers/api.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(API_FEATURE_KEY, apiReducer, {
      initialState: initialState,
    }),
  ],
})
export class ApiModule {
  /**
   * Singleton forRoot - passes in the environment to use with API Services
   * @param environment
   */
  static forRoot(environment): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        ApiErrorHandlerService,
        { provide: 'environment', useValue: environment },
      ],
    };
  }
}
