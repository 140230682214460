import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  AUTHENTICATION_FEATURE_KEY,
  AuthenticationState,
} from '../reducers/authentication.reducer';

const getAuthenticationState = createFeatureSelector<AuthenticationState>(
  AUTHENTICATION_FEATURE_KEY,
);

const getAuth = createSelector(getAuthenticationState, state => state.auth);

const getUser = createSelector(getAuthenticationState, state => state.user);

const getOrganisation = createSelector(
  getAuthenticationState,
  state => state.organisation,
);

const getBeforeLogoutRoute = createSelector(
  getAuthenticationState,
  state => state.beforeLogoutRoute,
);

const getIsLoggedIn = createSelector(
  getAuthenticationState,
  state => state.isLoggedIn,
);

export const authenticationQuery = {
  getAuthenticationState,
  getAuth,
  getUser,
  getOrganisation,
  getBeforeLogoutRoute,
  getIsLoggedIn,
};
