<div class="header">
  <ate-logo (click)="handleLogoClick()"></ate-logo>
  <ate-menu-burger
    [attr.is-open]="open"
    (click)="toggleMenu()"
  ></ate-menu-burger>
</div>

<nav>
  <ate-list type="menu">
    <ate-link color="grey-blue" hover-color="pink" [href]="'/'">
      <ate-item><ate-icon name="inbox"></ate-icon>My Requests</ate-item>
    </ate-link>
    <ate-link (click)="goToProfile()" color="grey-blue" hover-color="pink">
      <ate-item
        ><ate-icon name="profile-circle-v2"></ate-icon> My Account</ate-item
      >
    </ate-link>
    <ate-link
      [href]="eventOrganiserSupportUrl"
      color="grey-blue"
      hover-color="pink"
    >
      <ate-item><ate-icon name="question-v2"></ate-icon>Help Centre</ate-item>
    </ate-link>
    <ate-link
      *ngIf="isSupplier"
      (click)="goToSupplier()"
      class="mobile"
      color="grey-blue"
      hover-color="pink"
    >
      <ate-item>Switch to Supplier View</ate-item>
    </ate-link>
    <hr />
    <ate-link color="grey-blue" [href]="blogUrl" hover-color="pink">
      <ate-item><ate-icon name="external-link"></ate-icon>Blog</ate-item>
    </ate-link>
    <ate-link [href]="browseSuppliersUrl" color="grey-blue" hover-color="pink">
      <ate-item
        ><ate-icon name="external-link"></ate-icon>Additional Services</ate-item
      >
    </ate-link>
    <ate-link
      (click)="logout()"
      class="mobile"
      color="grey-blue"
      hover-color="pink"
    >
      <ate-item>Log out</ate-item>
    </ate-link>
  </ate-list>
</nav>

<ate-button-control color="cta" (click)="openRequestQuotes()">
  <ate-icon name="plus" slot="start"></ate-icon>
  New Service Request
</ate-button-control>
