import { Component, Input } from '@angular/core';
import { EventRequestQuotes } from '../../../features/event/services/event-request/event-request.type';

@Component({
  selector: 'app-quotes-list',
  templateUrl: './quotes-list.component.html',
  styleUrls: ['./quotes-list.component.scss'],
})
export class QuotesListComponent {
  @Input() activeQuotes: EventRequestQuotes[] = [];
}
