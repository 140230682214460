<div>
  <ate-logo size="large"></ate-logo>
</div>
<mat-dialog-content class="dialog-header">
  <h3>
    We are sorry, there was a problem with the site. Please refresh this page.
  </h3>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="dialog-content">
    <div>
      <ate-button expand="wide" [mat-dialog-close]="true" cdkFocusInitial
        >Refresh</ate-button
      >
    </div>
  </div>
</mat-dialog-actions>
