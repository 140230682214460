/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @angular-eslint/no-output-on-prefix */
import { ActivatedRoute } from '@angular/router';
import { ReplaySubject, tap } from 'rxjs';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  AfterViewInit,
  HostListener,
  ViewChildren,
  EventEmitter,
  TemplateRef,
  QueryList,
  ViewChild,
  Component,
  OnInit,
  Output,
  Input,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventRequestTypeEnum } from '../../../../features/event/services/event-request/event-request-type.enum';
import { QuoteService } from '../../../../features/quotes/services/quote.service';
import { QuoteResponse } from '../../../../features/quotes/services/quote.type';
import { ComponentsModule } from '../../../../ui/components/components.module';
import { ListingItem } from '../../../../ui/components/listing-list/listing-list-item/listing-list-item.component';
import { ModalTypeEnum } from '../../../enums/modal-type.enum';
import { QuoteActionType } from '../../../enums/quote-action-type.enum';
import { SharedModule } from '../../../shared.module';
import { EventRequestService } from '../../../../features/event/services/event-request/event-request.service';

@Component({
  selector: 'app-accept-quote-modal',
  templateUrl: './accept-quote-modal.component.html',
  styleUrls: ['./accept-quote-modal.component.scss'],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, SharedModule, ComponentsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcceptQuoteModalComponent implements OnInit, AfterViewInit {
  @ViewChildren('footerStep0, footerStep1, footerStep2, footerStep3', {
    read: TemplateRef,
  })
  footerTemplates: QueryList<TemplateRef<any>>;

  @ViewChildren('sectionStep0, sectionStep1, sectionStep2, sectionStep3', {
    read: TemplateRef,
  })
  sectionTemplates: QueryList<TemplateRef<any>>;

  @ViewChild('acceptTemplate')
  acceptTemplate: TemplateRef<any>;

  @Input() quote: QuoteResponse;
  @Input() listingItems: ListingItem[];
  @Input() currentListing: ListingItem;
  isLoading = false;
  @Input() public isMobile = false;
  @Output() onDeclineOtherQuote = new EventEmitter<{
    listingItem: ListingItem;
    modalType: ModalTypeEnum;
  }>();
  @Output() onClose = new EventEmitter();

  public footer$ = new ReplaySubject<TemplateRef<any>>();
  public section$ = new ReplaySubject<TemplateRef<any>>();
  public confirmed = false;
  public headerIcon = 'close-v2';
  public keepRequestOpen = false;
  public isRequestOpen = false;

  title = 'Choose Supplier';
  eventType: EventRequestTypeEnum;

  step = 0;
  stepTitle = '';
  stepSubTitle = '';

  checkboxSelection = JSON.stringify([
    { label: 'Price', key: 'price' },
    { label: 'Quality', key: 'quality' },
    { label: 'Reviews', key: 'reviews' },
    { label: 'Communication', key: 'communication' },
    {
      label: 'Other (please specify)',
      key: 'other',
      customInput: true,
      customInputMaxLength: 60,
    },
  ]);

  confirmRequest: {
    selectReason?: { [key: string]: boolean | string };
  };

  constructor(
    private route: ActivatedRoute,
    public quoteService: QuoteService,
    private cd: ChangeDetectorRef,
    private eventRequestService: EventRequestService,
  ) {}

  ngOnInit(): void {
    if (this.isMobile) {
      this.headerIcon = 'chevron-left-v2';
    }
    this.isRequestOpen = this.eventRequestService.processStatus(
      this.quote?.event?.eventRequestOrganiserStatus,
    ).active;
  }

  ngAfterViewInit(): void {
    this.updateStep(0);
    this.footer$.next(this.footerTemplates?.toArray()[0]);
    this.section$.next(this.sectionTemplates?.toArray()[0]);
    this.cd.detectChanges();
  }

  updateStep(newStep: number): void {
    this.step = newStep;
    this.footer$.next(this.footerTemplates?.toArray()[this.step]);
    this.section$.next(this.sectionTemplates?.toArray()[this.step]);

    switch (this.step) {
      case 0:
        this.title = 'Choose Supplier';
        this.stepTitle = `Choose your supplier for your ${this.quote?.event?.serviceName} Request`;
        this.stepSubTitle = `We’ll notify the supplier once you have confirmed.`;
        break;
      case 1:
        this.title = 'Supplier chosen';
        this.stepTitle = `Supplier chosen!`;
        this.stepSubTitle = `You have chosen the following supplier for your <b>${this.quote?.event?.serviceName} Request:</b>`;
        this.currentListing = {
          ...this.currentListing,
          selected: false,
        };
        break;
      case 2:
        this.title = 'Supplier chosen';
        this.stepTitle = `Tell us why you chose ${this.quote?.listing?.title}`;
        this.stepSubTitle = `${this.quote?.listing?.title} would love to know what made you choose them for your event.`;
        break;
      case 3:
        this.title = 'Your Other Quotes';
        this.stepTitle = `Do you want to accept any quotes from other suppliers?`;
        this.stepSubTitle = `You still have live quotes for your ${this.quote?.event?.serviceName} Request from the following suppliers:`;
        break;
    }
  }

  confirmQuote() {
    this._confirmQuotes$().subscribe({
      next: () => {
        this.updateStep(1);

        this.confirmed = true;
      },
      error: msg => {
        console.error(msg);
        this.isLoading = false;
      },
    });
  }

  onSkip() {
    if (this.listingItems.length !== 0) {
      this.updateStep(3);
    } else {
      // No more quotes to accept or decline
      this.closeUp();
    }
  }

  confirmQuoteReason() {
    this._confirmQuotes$().subscribe({
      next: () => {
        if (this.listingItems?.length !== 0) {
          this.updateStep(3);
        } else {
          // No more quotes to accept or decline
          this.closeUp();
        }
      },
      error: msg => {
        console.error(msg);
        this.isLoading = false;
      },
    });
  }

  closeUp() {
    this.onClose.emit();
  }

  confirmKeepRequestOpen() {
    if (!this.keepRequestOpen) {
      return this.updateStep(2);
    }
    this.isLoading = true;
    this.quoteService
      .acceptQuotes([this.currentListing.quoteUuid], null, true)
      .pipe(tap(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.updateStep(2);
        },
        error: msg => {
          console.error(msg);
          this.isLoading = false;
        },
      });
  }

  onKeepRequestOpenChange(event: any) {
    if (event.detail.id === 'keepRequestOpen') {
      this.keepRequestOpen = event.detail.checked;
    }
  }

  @HostListener('onValueChanges', ['$event'])
  onClick(event: {
    detail: { selectReason?: { value: { [key: string]: boolean } } };
  }) {
    this.confirmRequest = {
      selectReason:
        event?.detail?.selectReason?.value || this.confirmRequest?.selectReason,
    };
  }

  public open() {
    this.footer$.next(this.footerTemplates.toArray()[0]);
    this.section$.next(this.sectionTemplates.toArray()[0]);
  }

  public listingItemClick(action: QuoteActionType, listing: ListingItem) {
    if (action === 'book') {
      this.currentListing = {
        ...listing,
        declined: false,
        selected: true,
        actionButtons: false,
      };
      this.updateStep(0);
    }

    if (action === 'decline') {
      this.onDeclineOtherQuote.emit({
        listingItem: listing,
        modalType: ModalTypeEnum.DECLINE_QUOTE,
      });
    }
  }

  private _confirmQuotes$() {
    this.isLoading = true;
    let reasons = this.confirmRequest?.selectReason
      ? Object.keys(this.confirmRequest?.selectReason).filter(key => {
          return this.confirmRequest?.selectReason[key];
        })
      : [];

    // add other reason
    if (this.confirmRequest?.selectReason?.other) {
      reasons = [
        ...reasons,
        this.confirmRequest?.selectReason?.other as string,
      ];
    }

    return this.quoteService
      .acceptQuotes([this.currentListing.quoteUuid], reasons)
      .pipe(tap(() => (this.isLoading = false)));
  }
}
