import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListingRoutingModule } from './listing-routing.module';
import { ListingComponent } from './pages/listing/listing.component';
import { SharedModule } from '../../shared/shared.module';
import { ContainerModule } from '../../ui/containers/container.module';
import { SocialContextMenuComponent } from './components/social-context-menu/social-context-menu.component';
import { ComponentsModule } from '../../ui/components/components.module';
import { CarouselLightboxModule } from '../../ui/carousel-lightboxes/carousel-lightbox.module';

@NgModule({
  declarations: [ListingComponent, SocialContextMenuComponent],
  imports: [
    CommonModule,
    ListingRoutingModule,
    SharedModule,
    ContainerModule,
    ComponentsModule,
    CarouselLightboxModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ListingModule {}
