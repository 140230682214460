import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'linebreak' })
export class LinebreakPipeline implements PipeTransform {
  transform(value: string) {
    if (typeof value !== 'string') {
      return value;
    }

    return value.replace(/\n/g, '<br>'); //replace all \n with a line break
  }
}
