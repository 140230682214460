import { AfterViewInit, Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { ListingService } from '../../../shared/services/listing/listing.service';
import { ListingReviews } from '../../../shared/services/listing/interfaces';

@Component({
  selector: 'app-listing-reviews',
  templateUrl: './listing-reviews.component.html',
  styleUrls: ['./listing-reviews.component.scss'],
})
export class ListingReviewsComponent implements OnInit, AfterViewInit {
  @Input() public listingUuid: string;
  isInitialLoading = true;
  isLoading = false;
  isError = false;
  @Input() public modalView = false;
  @Input() public scrollToId: string;

  page = 1;
  limit = 10;

  listingReview: ListingReviews;

  constructor(
    private route: ActivatedRoute,
    public listingService: ListingService,
    private router: Router,
    @Inject(DOCUMENT) readonly document: Document,
  ) {}

  ngOnInit(): void {
    if (!this.listingUuid) {
      this.listingUuid = this.route.snapshot.paramMap.get('uuid');
    }
    this.getListingReviews();
  }

  ngAfterViewInit() {
    setTimeout(() => this.autoScroll());
  }

  autoScroll() {
    const id =
      this.scrollToId || this.router.parseUrl(this.router.url).fragment;
    if (id && id !== 'done!') {
      const el = this.document.querySelector('#' + id);
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
        this.scrollToId = 'done!';
      }
    }
  }

  goBack(): void {
    this.router.navigate(['..'], {
      relativeTo: this.route,
    });
  }

  getListingReviews(): void {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.isError = false;

    this.listingService
      .getOneListingReviews(this.listingUuid, this.page, this.limit)
      .subscribe({
        next: data => {
          this.isLoading = false;

          if (this.isInitialLoading) {
            this.listingReview = data;
            setTimeout(() => this.autoScroll());
          } else {
            const oldItems = [...this.listingReview.items];
            this.listingReview = {
              ...this.listingReview,
              items: [...oldItems, ...data.items],
            };
          }

          this.isInitialLoading = false;
          this.page++;
        },
        error: msg => {
          console.error(msg);
          this.isLoading = false;
          this.isError = true;
        },
      });
  }
}
