import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { EventRequestService } from '../../services/event-request/event-request.service';
import { WindowService } from '../../../../shared/services/window/window.service';
import { EventRequestSingleType } from '../../services/event-request/event-request.type';
import { EventOverviewPageConfig } from '../../interfaces/event-overview-page-config.interface';
import { EventRequestColorEnum } from '../../../../ui/components/status-pill/status-pill-color.enum';
import { environment } from '../../../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { PageContextService } from '../../../../shared/services/page-context/page-context.service';
import { EnhancedFormsLauncherService } from '../../../../shared/services/enhanced-forms-launcher/enhanced-forms-launcher.service';
import { FormPartialEnum } from '../../../../shared/enums/partial-status.enum';
import { user } from '@angular/fire/auth';
import { EventRequestOverviewService } from './event-request-overview.service';
import { Subject, combineLatest, first, takeUntil } from 'rxjs';
import { EventRequestStatusEnum } from '../../services/event-request/event-request-status.enum';
import { AuthService } from '../../../../shared/services/auth/auth.service';
import { EventState, eventActions } from '../../state';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-event-request-overview',
  templateUrl: './event-request-overview.component.html',
  styleUrls: ['./event-request-overview.component.scss'],
})
export class EventRequestOverviewComponent implements OnInit, OnDestroy {
  isMobile: boolean;
  eventRequest: EventRequestSingleType;
  isLoading = true;
  status: EventRequestColorEnum;
  statusText: string;
  config: EventOverviewPageConfig;
  private _destroy$ = new Subject();
  private _openEventRequestStatuses = [
    EventRequestStatusEnum.AWAITING_APPROVAL,
    EventRequestStatusEnum.AWAITING_QUOTES,
    EventRequestStatusEnum.FIRST_QUOTE,
    EventRequestStatusEnum.NEW_QUOTE,
    EventRequestStatusEnum.QUOTES_RECEIVED,
    EventRequestStatusEnum.ALL_QUOTES_DECLINED,
    EventRequestStatusEnum.SUPPLIER_CHOSEN_OPEN,
  ];

  constructor(
    public eventRequestService: EventRequestService,
    private _windowService: WindowService,
    private _route: ActivatedRoute,
    private _pageContextService: PageContextService,
    private _enhancedFormsLauncherService: EnhancedFormsLauncherService,
    private _eventRequestOverviewService: EventRequestOverviewService,
    private _authService: AuthService,
    private _store: Store<EventState>,
  ) {}

  ngOnInit(): void {
    this.isMobile = this._windowService.isMobile();

    combineLatest([
      this._authService.getAuthToken$(),
      this._eventRequestOverviewService.getEvent$(),
    ])
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: ([token, eventRequestSingleType]) => {
          this.handleEventResponse(eventRequestSingleType);
          this.initForm(eventRequestSingleType, token);
        },
        error: error => this.handleError(error),
      });

    this.refreshRequestOnFormClose();
  }

  ngOnDestroy() {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  @HostListener('window:resize')
  onResize() {
    this.isMobile = this._windowService.isMobile();
  }

  processStatus(): void {
    const statusToProcess = this.eventRequest.event.eventOrganiserStatus;

    const { status, statusText } = this.eventRequestService.processStatus(
      statusToProcess,
    );

    this.status = status;
    this.statusText = statusText;
    this.config = this.eventRequestService.getEventOverviewPageConfig(
      statusToProcess,
      this.eventRequest.quotes,
    );
  }

  openRequestQuotes() {
    this._windowService.open(environment.requestQuotesUrl, '_self');
  }

  private handleError(error) {
    console.error(error);
    this.isLoading = false;
  }

  private handleEventResponse(eventRequestSingleType: EventRequestSingleType) {
    const { event } = eventRequestSingleType;
    const { paramMap } = this._route.snapshot;

    if (event.uuid === paramMap.get('eventUuid')) {
      this.handleCurrentEvent(
        eventRequestSingleType,
        paramMap.get('eventRequestUuid'),
      );
    }
  }

  private initForm(
    eventRequestSingleType: EventRequestSingleType,
    token: string,
  ) {
    const { event, formStage, partialFormUuid } = eventRequestSingleType;
    if (!token) {
      console.error(`Error: Could not get token for user: ${user}`);
      return;
    }

    // Only open the supplementary form if the request is open to quotes.
    if (
      this._openEventRequestStatuses.includes(
        this.eventRequest.event.eventOrganiserStatus,
      )
    ) {
      this.saveAuthTokenToLocalStorage(token);
      this.tryLaunchSupplementaryForm(formStage, partialFormUuid, event.uuid);
    }
  }

  private refreshRequestOnFormClose() {
    const { paramMap } = this._route.snapshot;

    this._enhancedFormsLauncherService
      .onFormClose()
      .pipe(first())
      .subscribe({
        next: () => {
          this._store.dispatch(
            eventActions.GetEvent({
              payload: {
                eventUuid: paramMap.get('eventUuid'),
                eventRequestUuid: paramMap.get('eventRequestUuid'),
              },
            }),
          );
        },
        error: error => this.handleError(error),
      });
  }

  /**
   * TODO: Automatically refresh the token in local storage on expiry.
   * @param token The auth token from Firebase.
   */
  private saveAuthTokenToLocalStorage(token: string) {
    console.log(`Auth token set at ${Date.now()}`);
    window.localStorage.setItem(
      this._enhancedFormsLauncherService.localStorageAuthKey,
      token,
    );
  }

  private handleCurrentEvent(
    eventRequestSingleType: EventRequestSingleType,
    eventRequestUuid: string,
  ) {
    this.isLoading = false;
    this.eventRequest = eventRequestSingleType;
    this.processStatus();
    this._pageContextService.setEventRequestUuid(eventRequestUuid);
  }

  private tryLaunchSupplementaryForm(
    formStage: FormPartialEnum,
    partialFormUuid: string,
    eventUuid: string,
  ) {
    const isPartialPending = formStage === FormPartialEnum.PARTIAL_PENDING;
    const hasPartialCompletionFormUuid = !!partialFormUuid;

    if (isPartialPending && hasPartialCompletionFormUuid) {
      this._enhancedFormsLauncherService.launchForm(partialFormUuid, eventUuid);
    }
  }
}
