import { createAction, props } from '@ngrx/store';
import { FlashMessage } from '../models/flash-message.model';

const SetIsLoading = createAction(
  '[App] Set Is Loading',
  props<{ payload: { isLoading: boolean } }>(),
);

const ToggleShowingFlashMessage = createAction(
  '[App] Toggle Showing Flash Message',
  props<{ payload: boolean }>(),
);

export const SetFlashMessage = createAction(
  '[App] Set Flash Message',
  props<{ flashMessage: FlashMessage }>(),
);

const RemoveFlashMessage = createAction('[App] Remove Flash Message');

export const appActions = {
  SetIsLoading,
  ToggleShowingFlashMessage,
  SetFlashMessage,
  RemoveFlashMessage,
};
