import { createSelector, createFeatureSelector } from '@ngrx/store';
import { QuotesState, QUOTES_FEATURE_KEY } from '../reducers/quotes.reducer';

const getQuoteState = createFeatureSelector<QuotesState>(QUOTES_FEATURE_KEY);

const getQuote = createSelector(
  getQuoteState,
  (state: QuotesState) => state.selectedQuote,
);

export const selectQuotes = {
  getQuote,
};
