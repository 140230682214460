/* eslint-disable @typescript-eslint/no-explicit-any */
import { catchError, first, switchMap, take } from 'rxjs/operators';
import { Observable, throwError, EMPTY } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
} from '@angular/common/http';
import { AuthService } from '../services/auth/auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private _authService: AuthService, private _router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return this._authService.getToken().pipe(
      first(),
      switchMap(token => {
        return next.handle(this.addTokenHeader(req, token)).pipe(
          catchError(error => {
            if (error instanceof HttpErrorResponse && error.status === 401) {
              return this.handle401(req, next);
            }

            return throwError(() => error);
          }),
        );
      }),
    );
  }

  private handle401(request: HttpRequest<any>, next: HttpHandler) {
    if (request?.url.includes('flg/flags')) {
      // getting flags is called on setting token action
      // below refresh token casues infinity loop
      return EMPTY;
    }

    return this._authService.checkAuthorised$().pipe(
      first(),
      switchMap(user => {
        if (!user) {
          this._authService.unauthenticate();
          this._router.navigate(['user/login']);
          return next.handle(request);
        }
        return this._authService.refreshToken(user).pipe(
          switchMap((token: any) => {
            return next.handle(this.addTokenHeader(request, token));
          }),
          catchError(err => {
            return throwError(() => err);
          }),
        );
      }),
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`),
    });
  }
}
