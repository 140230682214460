<app-page-container>
  <img src="/assets/support-pages/error-illustration.svg" />

  <h1>Oops! We couldn’t find the page you’re looking for.</h1>
  <p>
    Sorry, the page you requested could not be found. Please go back to My
    Requests.
  </p>

  <button (click)="goToHome()">
    <ate-icon name="return-v2"></ate-icon>
    Back to My Requests
  </button>
</app-page-container>
