import { Component, Input } from '@angular/core';
import { QuoteStatus } from '../../../../ui/components/quotes-list/quote.type';

@Component({
  selector: 'app-quote-status-info',
  templateUrl: './status-info.component.html',
  styleUrls: ['./status-info.component.scss'],
})
export class StatusInfoComponent {
  @Input() quoteEstimate = 0;
  @Input() status: QuoteStatus;
}
