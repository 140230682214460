import { Component, Input } from '@angular/core';
import { QuoteListing } from '../../features/quotes/services/quote.type';
import { QuoteStatus } from '../../ui/components/quotes-list/quote.type';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent {
  @Input() public quoteUuid: string;
  @Input() public listing: QuoteListing;
  @Input() public phoneNumber: string;
  @Input() public fixedMessageBox = false;
  @Input() public displayControls = false;
  @Input() status: QuoteStatus;
}
