import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Output,
  QueryList,
} from '@angular/core';
import { TabComponent } from './tab/tab.component';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements AfterContentInit {
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
  @Output() TabSelected = new EventEmitter<{
    tab: TabComponent;
    index: number;
  }>();

  // contentChildren are set
  public ngAfterContentInit() {
    // get all active tabs
    const activeTabs = this.tabs.filter(tab => tab.active);

    // if there is no active tab set, activate the first
    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first, 0);
    }
  }

  public selectTab(tab: TabComponent, index: number) {
    if (!this.tabs || !this.tabs.length) return;
    // deactivate all tabs
    this.tabs.toArray().forEach((tab: TabComponent) => (tab.active = false));

    // activate the tab the user has clicked on.
    tab.active = true;

    this.TabSelected.emit({ tab, index });
  }
}
