/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

export interface IIntercomSettings {
  app_id: string;
  user_id: string;
  email: string;
  created_at: number;
  custom_data?: any;
  company?: {
    company_id?: string;
    name?: string;
    created_at?: string;
  };
}

declare global {
  interface Window {
    Intercom: any;
    intercomSettings: IIntercomSettings;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AteSupportService {
  public supportLoaded$ = new Subject<boolean>();
  public unreadNotifications$ = new Subject<number>();

  public hasSupport = false;
  public isOpen = false;

  constructor(private _http: HttpClient) {
    this.checkLoadedSupport();

    this.supportLoaded$.subscribe(loaded => {
      this.hasSupport = loaded;

      if (!this.hasSupport) {
        return;
      }

      this.updateIntercomSettings();

      window.Intercom('onShow', () => {
        this.isOpen = true;
      });
      window.Intercom('onHide', () => {
        this.isOpen = false;
      });

      window.Intercom('onUnreadCountChange', count => {
        this.unreadNotifications$.next(count);
      });
    });
  }

  /**
   * Updates the intercom settings to trigger a search for new
   * messages and log an impression of the current URL.
   *
   * https://developers.intercom.com/installing-intercom/docs/intercom-javascript#intercomupdate
   */
  private updateIntercomSettings(): void {
    if (!window.intercomSettings) {
      return;
    }

    this.getSettings()
      .pipe(first())
      .subscribe((settings: IIntercomSettings) => {
        window.Intercom('update', settings);
      });
  }

  /**
   * Gets the Intercom settings for the user from Drupal.
   */
  private getSettings(): Observable<IIntercomSettings> {
    const { app_id } = window.intercomSettings ?? {};
    const httpOptions = {
      params: { appId: app_id },
      headers: new HttpHeaders({
        'Content-Type': 'application/ate.organiser.v1+json',
      }),
    };

    return this._http.get<IIntercomSettings>(
      `${environment.apiUrl}/org/intercom/settings`,
      httpOptions,
    );
  }

  /**
   * Create new support message.
   * Opens up Intercom if present, or initates a mailto: link.
   *
   * @param subject Subject line.
   */
  public newMessage(subject?: string) {
    if (this.hasSupport) {
      window.Intercom('showNewMessage', subject);
    } else {
      window.location.href = this.supportMailTo(subject);
    }
  }

  /**
   * Open Support (intercom).
   *
   * @param fallback If true, initiate a mailto: when intercom isnt present.
   */
  public open(fallback = true) {
    if (this.hasSupport) {
      window.Intercom('show');
    } else if (fallback) {
      window.location.href = this.supportMailTo();
    }
  }

  /**
   * Close Support (intercom).
   */
  public close() {
    if (this.hasSupport) {
      window.Intercom('hide');
    }
  }

  /**
   * Toggle Support (intercom).
   */
  public toggle() {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  }

  /**
   * Checks if support loaded (intercom)
   *
   * @returns Promise
   */
  public checkLoadedSupport() {
    const timeout = setTimeout(() => {
      clearInterval(interval);
    }, 10000);

    const interval = setInterval(() => {
      if (!!window.Intercom && window.Intercom.booted) {
        this.supportLoaded$.next(true);
        clearInterval(interval);
        clearTimeout(timeout);
      }
    }, 1000);
  }

  /**
   * Get a mailto: link.
   * @param subject Subject Line.
   * @param body Body text.
   */
  public supportMailTo(subject?: string, body?: string) {
    return this.buildMailTo(this.getSupportEmail(), subject, body);
  }

  /**
   * Build a mailto: link.
   * @param email Email Address.
   * @param subject Subject Line.
   * @param body Body text.
   */
  private buildMailTo(email: string, subject?: string, body?: string): string {
    let params = '';
    params += subject ? `subject=${subject}&` : '';
    params += body ? `body=${body}` : '';
    return `mailto:${email}?${params}`;
  }

  /**
   * Returns Support email address.
   *
   * @returns string Support email.
   */
  private getSupportEmail(): string {
    return environment.supportEmailAddress;
  }
}
