import { DOCUMENT } from '@angular/common';
import { Injectable, Inject, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Subject, Observable, takeUntil, first, SubscriptionLike } from 'rxjs';
import { AuthenticationState } from '../../../store/reducers/authentication.reducer';
import { authenticationQuery } from '../../../store/selectors/authentication.selectors';

@Injectable()
export class AuthenticationGuard implements OnDestroy {
  public _unsubscribeAll: Subject<SubscriptionLike>;

  constructor(
    @Inject('environment') private environment,
    private _store: Store<AuthenticationState>,
    @Inject(DOCUMENT) readonly document: Document,
  ) {
    this._unsubscribeAll = new Subject();
  }

  canActivate(): Observable<boolean> {
    return new Observable(observer => {
      this._store
        .pipe(
          takeUntil(this._unsubscribeAll),
          first(),
          select(authenticationQuery.getIsLoggedIn),
        )
        .subscribe((loggedIn: boolean) => {
          if (loggedIn) {
            observer.next(true);
            return;
          }

          observer.next(false);
          this.document.defaultView.open(this.environment.loginUrl, '_self');
        });
    });
  }

  canActivateChild(): Observable<boolean> {
    return this.canActivate();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.complete();
  }
}
