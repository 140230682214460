/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
})
export class LinkComponent {
  @Output() onLink = new EventEmitter();
}
