import { createSelector, createFeatureSelector } from '@ngrx/store';
import { EventState, EVENT_FEATURE_KEY } from './event.reducer';

const getEventState = createFeatureSelector<EventState>(EVENT_FEATURE_KEY);

const getEvent = createSelector(
  getEventState,
  (state: EventState) => state.selectedEvent,
);

const getIsLoading = createSelector(
  getEventState,
  (state: EventState) => state.isLoading,
);

export const selectEvent = {
  getEvent: getEvent,
  getIsLoading: getIsLoading,
};
