<div class="spinner">
  <ate-spinner *ngIf="isLoading"></ate-spinner>
</div>

<ng-container *ngIf="!isLoading">
  <ng-container *ngIf="isMobile; else desktopView"
    ><app-quote-request-mobile
      [quote]="quote"
      [quoteBody]="quoteBody"
      [statusText]="statusText"
      [statusColor]="statusColor"
      [quoteUuid]="quoteUuid"
    ></app-quote-request-mobile
  ></ng-container>

  <ng-template #desktopView>
    <app-quote-request-desktop
      [quote]="quote"
      [quoteBody]="quoteBody"
      [statusText]="statusText"
      [statusColor]="statusColor"
      [quoteUuid]="quoteUuid"
    ></app-quote-request-desktop>
  </ng-template>
</ng-container>

<router-outlet></router-outlet>
