import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-lightbox-image',
  templateUrl: './lightbox-image.component.html',
  styleUrls: ['./lightbox-image.component.scss'],
})
export class LightboxImageComponent {
  @Input() url: string;
  active = false;

  setActive(active: boolean): void {
    this.active = active;
  }
}
