<header [ngClass]="{ header__border: maxHeight }" (click)="togglePanel()">
  <p class="quote-count">
    {{ declinedQuotes.length }}
    {{ declinedQuotes.length === 1 ? 'supplier' : 'suppliers' }} declined
  </p>
  <ate-icon [attr.open]="open" name="chevron-down-v2"></ate-icon>
</header>

<section #mainSection [style.max-height]="maxHeight">
  <app-quote-list-item
    *ngFor="let quote of declinedQuotes"
    [quote]="quote"
  ></app-quote-list-item>
</section>
