import {
  AfterContentInit,
  Component,
  ContentChildren,
  HostBinding,
  HostListener,
  Input,
  QueryList,
  ViewChild,
} from '@angular/core';
import { CarouselItemComponent } from './carousel-item/carousel-item.component';
import { CarouselType } from './carouselType.enum';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements AfterContentInit {
  @Input() mode: CarouselType = CarouselType.Throttle;
  @Input() pips = false;
  @Input() perPage = 1;
  @Input() movePerPage = 1;
  @Input() firstAndLastChildMargin = 20;

  currentPage = 0;

  @ViewChild('carousel') carouselInner;

  mouseDown = false;
  mousePosX = 0;
  carouselX = 0;

  pages = [];

  @ContentChildren(CarouselItemComponent) items: QueryList<
    CarouselItemComponent
  >;
  @HostBinding('style.overflow') private overflow =
    this.mode === CarouselType.Throttle ? 'auto' : 'hidden';

  setWidthOfChildren(): void {
    if (this.perPage === 1) {
      return;
    }

    this.items
      .toArray()
      .forEach((tab: CarouselItemComponent) =>
        tab.setInternalWidth(100 / this.perPage),
      );
  }

  setMarginChildren(): void {
    if (this.perPage === 1) {
      return;
    }

    this.items
      .toArray()
      .forEach((tab: CarouselItemComponent, index: number) => {
        let margin = null;

        if (index === 0) {
          margin = `0 20px 0 ${this.firstAndLastChildMargin}px`;
        } else if (index === this.items.length - 1) {
          margin = `0 ${this.firstAndLastChildMargin}px 0 0`;
        }

        return tab.setInternalMargin(margin ?? `0 20px 0 0`);
      });
  }

  ngAfterContentInit(): void {
    this.setWidthOfChildren();
    this.setMarginChildren();
    this.calculatePages();
    this.overflowStates();
  }

  @HostListener('mousedown', ['$event'])
  @HostListener('touchstart', ['$event'])
  onMouseDown(e) {
    const action = e.changedTouches ? e.changedTouches[0] : e;

    this.mousePosX = action.clientX;
    this.mouseDown = true;
  }

  @HostListener('document:mouseup', ['$event'])
  @HostListener('document:touchend', ['$event'])
  onMouseUp(e) {
    if (!this.mouseDown) {
      return;
    }

    const action = e.changedTouches ? e.changedTouches[0] : e;

    this.mouseDown = false;
    const xMove = this.mousePosX - action.clientX;
    this.carouselX = xMove + this.carouselX;

    switch (this.mode) {
      case CarouselType.PerPage:
        this.perPageMode(action);
        break;
    }
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e) {
    if (!this.mouseDown) {
      return;
    }

    switch (this.mode) {
      case CarouselType.Throttle:
        this.throttleMovement(e);
        break;
    }
  }

  throttleMovement(e): void {
    let xMove = this.carouselX + (this.mousePosX - e.clientX);

    const nativeCarousel = this.carouselInner.nativeElement;
    if (nativeCarousel.scrollWidth - nativeCarousel.offsetWidth < xMove) {
      xMove = nativeCarousel.scrollWidth - nativeCarousel.offsetWidth;
    }

    nativeCarousel.style = `transform: translateX(-${xMove}px);`;
  }

  perPageMode(e): void {
    const xMove = this.mousePosX - e.clientX;
    let newPage;
    if (xMove > 150) {
      newPage = this.currentPage + 1;
    } else if (xMove < -150) {
      newPage = this.currentPage - 1;
    }

    if (newPage === undefined || newPage === this.currentPage) {
      return;
    }

    this.setPage(newPage);
  }

  calculatePages(): number {
    const pages = Math.ceil(this.items.toArray().length * this.perPage);

    this.pages = [...Array(pages)].map(() => 0);

    return pages;
  }

  setPage(index: number): void {
    const nativeCarousel = this.carouselInner.nativeElement;

    const maxPages = this.calculatePages();

    if (index >= maxPages) {
      index = 0;
    }

    if (index < 0) {
      index = maxPages - 1;
    }

    let margin = 0;
    if (this.perPage !== 1) {
      margin = 20;
    }

    const pageSize = nativeCarousel.offsetWidth / this.perPage + margin;

    nativeCarousel.style = `transform: translateX(-${pageSize * index}px);`;
    this.currentPage = index;
  }

  overflowStates(): void {
    this.overflow = this.mode === CarouselType.Throttle ? 'auto' : 'hidden';
  }
}
