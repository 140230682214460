<div class="wrapper" (click)="$event.stopPropagation()" [attr.open]="open">
  <header>
    <ate-icon (click)="closeLightBox()" name="close-v2"></ate-icon>
    <p>{{ currentImage + 1 }}/{{ images.length }}</p>
    <ate-icon (click)="downloadImage()" name="download-secondary"></ate-icon>
  </header>

  <section #imageSection>
    <ng-content></ng-content>
  </section>

  <div class="arrows">
    <ate-icon
      *ngIf="images.length > 1"
      name="chevron-left-v2"
      (click)="selectImage(currentImage - 1)"
    ></ate-icon>
    <ate-icon
      *ngIf="images.length > 1"
      name="chevron-right-v2"
      (click)="selectImage(currentImage + 1)"
    ></ate-icon>
  </div>
</div>
