import { createAction, props } from '@ngrx/store';

export enum UserActionEnum {
  SetUserDetailsState = '[User] Setting User Details State',
}

export const SetUserDetails = createAction(
  UserActionEnum.SetUserDetailsState,
  props<{
    payload: {
      isSupplier: boolean;
      profileImageUrl: string;
    };
  }>(),
);
