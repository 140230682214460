import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import {
  ActivatedRoute,
  NavigationEnd,
  ResolveStart,
  Router,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigationStateService {
  private _resolvingUrl: string;

  constructor(private _route: ActivatedRoute, private _router: Router) {}

  public handlingRouterEvents() {
    this._router.events
      .pipe(
        switchMap(e => {
          return combineLatest([of(e), this._route.queryParams]);
        }),
      )
      .subscribe(([e, params]) => {
        if (e instanceof ResolveStart) {
          this._resolvingUrl = e.url;
        }

        if (
          e instanceof NavigationEnd &&
          (params?.tk || params?.redirectionMessage)
        ) {
          this._router.navigate([], {
            queryParams: {
              tk: null,
              redirectionMessage: null,
            },
            queryParamsHandling: 'merge',
          });
        }
      });
  }

  public getResolvingUrl(): string {
    return this._resolvingUrl;
  }
}
