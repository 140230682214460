import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-list-item',
  templateUrl: './service-list-item.component.html',
  styleUrls: ['./service-list-item.component.scss'],
})
export class ServiceListItemComponent implements OnInit {
  @Input() closed = false;
  @Input() serviceTitle = '';
  @Input() image = '';
  @Input() eventType = '';
  @Input() date = '';

  @HostBinding('class.closed') closedClass = false;

  ngOnInit(): void {
    this.closedClass = this.closed;
  }
}
