import packageInfo from '../../package.json';

const webUrl = 'https://migration-uat.atedev.co.uk';

export const environment = {
  production: false,
  sentry: {
    dsn:
      'https://177107c974814a94965bc634f42b0543@o139805.ingest.sentry.io/4504638031265792',
    tracePropagationTargets: [
      'localhost',
      'https://dev-organiser-web-client.web.app/',
    ],
  },
  googleMapsApiKey: 'AIzaSyA7ZlDJ0LjfutjZH-PeqAHS5XYxdYQYPEI',
  apiUrl: 'https://apis.ateapi.co.uk',
  version: packageInfo.version,
  webUrl,
  logoutUrl: `${webUrl}/user/logout`,
  supplierApp: 'https://supplier-uat.addtoevent.dev/',
  requestQuotesUrl: `${webUrl}/request-quotes?form=open`,
  browseSuppliersUrl: `${webUrl}/browse-suppliers`,
  blogUrl: `${webUrl}/blog`,
  termsOfUseUrl: `${webUrl}/terms-of-use`,
  privacyAndCookiePolicyUrl: `${webUrl}/privacy-and-cookie-policy`,
  eventOrganiserSupportUrl:
    'https://support.addtoevent.co.uk/en/collections/69572-event-organisers',
  loginUrl: `${webUrl}/user/login?destination=user/me`,
  firebase: {
    apiKey: 'AIzaSyCs0TzdxRFksvHAhjilAtM8WGb0appfvP0',
    authDomain: 'ate-mp-uat-a16b.firebaseapp.com',
    databaseURL: 'https://ate-mp-uat-a16b-default-rtdb.firebaseio.com',
  },
  supportEmailAddress: 'support-team@addtoevent.co.uk',
};
