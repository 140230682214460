import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../../environments/environment';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthenticationState } from '../../../store/reducers/authentication.reducer';
import { AuthService } from '../../../shared/services/auth/auth.service';
import { authenticationQuery } from '../../../store/selectors/authentication.selectors';
import { AuthUser } from '../../../store/models';
import { WindowService } from '../../../shared/services/window/window.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Input() open = false;
  @Input() isSupplier = false;
  public browseSuppliersUrl = environment.browseSuppliersUrl;
  public blogUrl = environment.blogUrl;
  public eventOrganiserSupportUrl = environment.eventOrganiserSupportUrl;

  @Output() ToggleMenu = new EventEmitter<{ open: boolean }>();
  user: AuthUser;

  constructor(
    private router: Router,
    private _store: Store<AuthenticationState>,
    public authService: AuthService,
    private _windowService: WindowService,
    @Inject(DOCUMENT) readonly document: Document,
  ) {}

  public toggleMenu() {
    this.open = !this.open;
    this.ToggleMenu.emit({ open: this.open });
  }

  public handleLogoClick() {
    this._windowService.open(environment.webUrl, '_self');
  }

  public openRequestQuotes() {
    this._windowService.open(environment.requestQuotesUrl, '_self');
  }

  public goToProfile() {
    this._windowService.open(
      `${environment.webUrl}/user/${this.user.userId}/edit`,
      '_self',
    );
  }

  public goToSupplier() {
    this._windowService.open(environment.supplierApp, '_self');
  }

  public logout() {
    this.authService.unauthenticate().subscribe(() => {
      this._windowService.open(environment.logoutUrl, '_self');
    });
  }

  ngOnInit(): void {
    this._store.select(authenticationQuery.getUser).subscribe({
      next: value => {
        this.user = value;
      },
    });
  }
}
