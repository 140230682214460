import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EventRequestDetailsComponent } from './pages/event-request-details/event-request-details.component';
import { EventRequestOverviewComponent } from './pages/event-request-overview/event-request-overview.component';
import { EventResolver } from './resolvers/event.resolver';

const routes: Routes = [
  {
    path: ':eventUuid/:eventRequestUuid',
    resolve: [EventResolver],
    component: EventRequestOverviewComponent,
    children: [
      {
        path: 'close',
        loadComponent: () =>
          import(
            '../../shared/standalone/request-actions/request-actions.component'
          ).then(m => m.RequestActionsComponent),
        data: { action: 'close' },
      },
    ],
  },

  {
    path: ':eventUuid/:eventRequestUuid/details',
    resolve: [EventResolver],
    component: EventRequestDetailsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EventRoutingModule {}
