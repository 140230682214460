import { ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { first, Observable, of, switchMap } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { EventState, eventActions, selectEvent } from '../state';

@Injectable({ providedIn: 'root' })
export class EventResolver {
  constructor(private _store: Store<EventState>) {}

  /**
   * Resolve Event
   * Call API to get Event if event matching route eventUuid does not exist in state
   *
   * @param route
   * @returns true
   */
  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    const eventUuid = route.paramMap.get('eventUuid');
    const eventRequestUuid = route.paramMap.get('eventRequestUuid');

    return this._store.pipe(select(selectEvent.getEvent), first()).pipe(
      switchMap(event => {
        if (!event || (event && eventUuid !== event.event.uuid)) {
          this.getEvent(eventUuid, eventRequestUuid);
        }

        return of(true);
      }),
    );
  }

  /*
   * Trigger fetching event in effects
   * dispatch GetEvent action
   */
  private getEvent(eventUuid: string, eventRequestUuid: string): void {
    this._store.dispatch(
      eventActions.GetEvent({ payload: { eventUuid, eventRequestUuid } }),
    );
  }
}
