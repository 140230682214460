import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-action-panel',
  templateUrl: './action-panel.component.html',
  styleUrls: ['./action-panel.component.scss'],
})
export class ActionPanelComponent {
  @Input() open = false;
  @Input() isMobile = true;

  toggleOpen(open?: boolean): void {
    if (typeof open === 'boolean') {
      this.open = open;
      return;
    }

    this.open = !this.open;
  }
}
