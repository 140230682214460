<!-- Modal Header -->
<div class="modal">
  <div class="header">
    <span class="left"></span>
    <div class="center">
      <h1 header>{{ title }}</h1>
    </div>
    <div (click)="closeUp()" class="right">
      <!-- TODO change icon ??? -->
      <ate-icon slot="right" [name]="headerIcon" class="clear"></ate-icon>
    </div>
  </div>

  <!-- Modal Body -->
  <main>
    <div class="body">
      <div class="spinner">
        <ate-spinner *ngIf="isLoading"></ate-spinner>
      </div>

      <div *ngIf="!isLoading" class="inner-container">
        <header>
          <div *ngIf="step === 1" class="step_header_icon">
            <ate-icon name="check-circle-v2"></ate-icon>
          </div>
          <h2>{{ stepTitle }}</h2>
          <p [innerHTML]="stepSubTitle"></p>
        </header>
        <ng-container [ngTemplateOutlet]="section$ | async"> </ng-container>
      </div>
    </div>
  </main>
</div>

<!-- Modal Footer -->
<div class="footer">
  <div class="footer_button">
    <ng-container [ngTemplateOutlet]="footer$ | async"> </ng-container>
  </div>
</div>

<ng-template #footerStep0>
  <button class="green" (click)="confirmQuote()">
    <ate-icon name="check-circle-v2"></ate-icon>
    Choose Supplier
  </button>
</ng-template>

<ng-template #footerStep1>
  <button class="red-gradient" (click)="confirmKeepRequestOpen()">
    Continue
  </button>
</ng-template>

<ng-template #footerStep2>
  <button
    class="red-gradient"
    (click)="confirmQuoteReason()"
    [disabled]="!confirmRequest?.selectReason"
  >
    Submit Reason
  </button>
</ng-template>

<ng-template #footerStep3>
  <button class="basic" (click)="closeUp()">
    <span>I'll do this later</span>
  </button>
</ng-template>

<ng-template #sectionStep0>
  <section class="body-section-small">
    <app-service-list-item
      [serviceTitle]="quote?.event?.serviceName"
      [image]="quote?.primaryServiceImageURI"
      [date]="quote?.event?.startDate"
      [eventType]="quote?.event?.eventType | eventType"
    ></app-service-list-item>

    <p class="listing-item-subtitle">Choose the following supplier:</p>

    <app-listing-list-item *ngIf="currentListing" [listing]="currentListing">
    </app-listing-list-item>
  </section>
</ng-template>

<ng-template #sectionStep1>
  <section class="body-section-full">
    <app-listing-list-item
      *ngIf="currentListing"
      [listing]="currentListing"
      class="gradient"
    >
    </app-listing-list-item>
    <div class="keep-request-open" *ngIf="isRequestOpen">
      <h4>Do you want to carry on receiving new quotes from suppliers?</h4>
      <ate-checkbox-group-control
        [modelKey]="'keepRequestOpen'"
        [options]="
          [
            {
              label: 'Yes, I want to continue to receive new quotes',
              key: 'keepRequestOpen'
            }
          ] | json
        "
        (onValueChanges)="onKeepRequestOpenChange($event)"
      ></ate-checkbox-group-control>
    </div>
  </section>
</ng-template>

<ng-template #sectionStep2>
  <section class="body-section-medium">
    <ate-checkbox-group-control
      [attr.model-key]="'selectReason'"
      [attr.options]="checkboxSelection"
      size="xsmall"
    ></ate-checkbox-group-control>

    <div class="skip">
      <button (click)="onSkip()">
        <span>Skip</span> <ate-icon name="skip-arrow"></ate-icon>
      </button>
    </div>
  </section>
</ng-template>

<ng-template #sectionStep3>
  <div class="other_listings">
    <app-listing-list-item
      *ngFor="let listingItem of listingItems"
      (SendAction)="listingItemClick($event, listingItem)"
      [listing]="listingItem"
      [actionButtons]="true"
    >
    </app-listing-list-item>
  </div>
</ng-template>
