import { createSelector, createFeatureSelector } from '@ngrx/store';
import { APP_FEATURE_KEY, AppState } from '../reducers/app.reducer';

const getAppState = createFeatureSelector<AppState>(APP_FEATURE_KEY);

const getIsLoading = createSelector(
  getAppState,
  (state: AppState) => state.isLoading,
);

const getShowingFlashMessage = createSelector(
  getAppState,
  (state: AppState) => {
    return state.showingFlashMessage;
  },
);

const getFlashMessage = createSelector(getAppState, (state: AppState) => {
  return state.flashMessage;
});

export const selectApp = {
  getIsLoading,
  getShowingFlashMessage,
  getFlashMessage,
};
