import { IAffiliate } from '../../services/event-request/event-request.type';

export const affiliates: IAffiliate[] = [
  {
    name: 'wedinsure',
    title: 'WedInsure',
    link:
      'https://wedinsure.co.uk/affiliate/Z1pWNndQQWVpYllUTUZUM3Q4VWowSWdjb1U4b2ExcTMwLjMwOTQxNzAwMTY5MDQ1ODY4Nw==',
    image: '/assets/affiliates/wedinsure.png',
    logo: '/assets/affiliates/wedinsure-logo.png',
    heading: 'Protect your wedding today with Wedinsure Wedding Insurance',
    body: 'Cover starting from only £43.99',
  },
  {
    name: 'wedcover',
    title: 'WedCover',
    link:
      'https://www.wedcover.com/s/get-a-quote?FacilitatorID=wtR4dreu9h&utm_source=addtoevent&utm_medium=affiliate&utm_campaign=affiliatesales',
    image: '/assets/affiliates/wedcover.png',
    logo: '/assets/affiliates/wedcover-logo.png',
    heading: 'Cover your perfect day with WedCover wedding insurance',
    body: 'Choose from 10 levels of cover',
  },
];
