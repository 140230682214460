<ate-icon
  class="context-opener"
  name="dots-box"
  (click)="toggleOpen()"
></ate-icon>

<nav
  [ngClass]="isMobile ? 'context-menu-mobile' : 'context-menu-desktop'"
  [attr.open]="open"
>
  <ate-icon class="close" name="close-v2" (click)="toggleOpen()"></ate-icon>

  <ng-content></ng-content>
</nav>
