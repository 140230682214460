<ate-spinner *ngIf="isRefreshing$ | async"> </ate-spinner>

<section
  class="event-request-background-image"
  [style.background-image]="'url(' + eventRequest?.primaryServiceImageURI + ')'"
>
  <ate-icon (click)="goBack()" name="chevron-left-v2"></ate-icon>
</section>

<section class="main-content">
  <div class="titles">
    <h1>{{ eventRequest?.primaryServiceTitle }}</h1>
    <h4>
      {{ eventRequest?.event?.eventType | eventType }} • {{ eventStartTime }}
    </h4>
  </div>

  <app-status-pill [text]="statusText" [status]="status"></app-status-pill>

  <p class="description" [innerHtml]="config?.description"></p>

  <app-progress-accordion
    *ngIf="config?.step !== -1"
    [currentStep]="config?.step"
    [title]="config?.progressAccordion?.title"
    [subtitle]="config?.progressAccordion?.subtitle"
  >
    <app-progress-step [title]="'Submit a service request'">
      <p>
        Submit a request for as many services as you need to make your event
        perfect. Each request will be reviewed and published by a member of our
        team within minutes.
      </p>
    </app-progress-step>
    <app-progress-step [title]="'Receive personalised quotes'">
      <p>
        Suppliers who can attend your event will send you a quote with an
        estimated price & more details on their services. These will appear
        below & via email.
      </p>
    </app-progress-step>
    <app-progress-step [title]="'Chat with suppliers'">
      <p>
        Chat with suppliers for more details. View their profile, reviews, and
        images.
      </p>
    </app-progress-step>
    <app-progress-step [title]="'Hire the best fit'">
      <p>
        Compare suppliers & choose the one that’s right for you. Click the
        ‘Select Supplier’ button & arrange payment with them.
      </p>
    </app-progress-step>
  </app-progress-accordion>

  <app-info-accordion
    *ngIf="config?.requestStageInfo"
    [headerText]="config?.requestStageInfo?.title"
  >
    <p
      class="info-accordion-message"
      [innerHTML]="config?.requestStageInfo?.description"
    ></p>
  </app-info-accordion>
</section>

<div class="request-details" (click)="openEventRequestDetails()">
  <p>Request Details</p>
  <ate-icon name="chevron-right-v2"></ate-icon>
</div>
<div class="request-info-container">
  <section class="quotes">
    <app-quotes-list [activeQuotes]="eventRequest?.quotes">
      <div class="quotes-list" *ngIf="config?.noQuotes?.detailedMessage">
        <img class="status-svg" [src]="config?.noQuotes.detailedMessage.svg" />
        <h3 class="quotes-list__header">
          {{ config?.noQuotes.detailedMessage?.noQuotesHeader }}
        </h3>
        <p class="quotes-list__subtext">
          {{ config?.noQuotes.detailedMessage?.message }}
        </p>
        <div *ngIf="config?.noQuotes.detailedMessage?.displayRefresh">
          <app-link (onLink)="onRefreshPage()">
            Refresh page
          </app-link>
        </div>
      </div>

      <ng-container *ngIf="config?.noQuotes?.simpleMessage">
        {{ config?.noQuotes.simpleMessage }}
      </ng-container>
    </app-quotes-list>
    <p class="quote-count" *ngIf="!eventRequest?.supplierDeclines?.length">
      {{ eventRequest?.quotes?.length }} supplier
      {{ eventRequest?.quotes?.length === 1 ? 'quote' : 'quotes' }}
    </p>
    <app-quotes-declined
      *ngIf="eventRequest?.supplierDeclines?.length"
      [declinedQuotes]="eventRequest?.supplierDeclines"
    ></app-quotes-declined>
  </section>
</div>

<div *ngIf="affiliate" [class]="'affiliate-banner ' + affiliate?.name">
  <a
    [href]="affiliate.link"
    (click)="upsellEvent('upsellClicked', affiliate.title)"
    target="_blank"
    class="affiliate-link"
  >
    <span class="affiliate-image">
      <img
        [src]="affiliate.image"
        [alt]="affiliate.title"
        [title]="affiliate.title"
        (load)="upsellEvent('upsellViewed', affiliate.title)"
        loading="lazy"
        class="bg"
      />
      <img
        *ngIf="affiliate.logo"
        [src]="affiliate.logo"
        alt="affiliate logo"
        loading="lazy"
        class="logo"
      />
    </span>
    <span class="affiliate-text">
      <span class="affiliate-heading">
        {{ affiliate.heading || affiliate.title }}
      </span>
      <span class="affiliate-body">{{ affiliate.body }}</span>
    </span>
    <img src="/assets/svg/ate-chevron-right-v2.svg" class="arrow" />
  </a>
</div>

<p
  class="cancel-request"
  (click)="openCloseEventRequest()"
  *ngIf="config?.closeServiceRequestButton"
>
  Cancel Service Request
</p>

<section
  class="upsells"
  *ngIf="upsells?.serviceRelated?.length || upsells?.eventType?.length"
>
  <h2>
    Anything else you need?
  </h2>

  <div class="upsells-service-related" *ngIf="upsells?.serviceRelated?.length">
    <p>
      Customers who have requested {{ eventRequest?.primaryServiceTitle }} also
      booked these services:
    </p>
    <div class="upsells_grid">
      <div *ngFor="let upsell of upsells.serviceRelated">
        <a [href]="upsell.url" target="_blank" class="upsells_grid_element">
          <img [src]="upsell.imgUrl" [alt]="upsell.name" />
          <span class="upsells_grid_element_name"> {{ upsell.name }} </span>
          <div class="upsells_grid_element_rating">
            <ate-star size="small" color="orange"></ate-star>
            <span class="upsells_grid_element_rating_number">
              {{ upsell.rating }}
            </span>
          </div>
        </a>
      </div>
    </div>
  </div>

  <div class="upsells-event-type" *ngIf="upsells?.eventType?.length">
    <p>
      Customers organising
      {{ eventRequest?.event?.eventType?.toLowerCase().replace('_', ' ') }}s
      also booked these services:
    </p>
    <div class="upsells_grid">
      <div *ngFor="let upsell of upsells.eventType">
        <a [href]="upsell.url" target="_blank" class="upsells_grid_element">
          <img [src]="upsell.imgUrl" [alt]="upsell.name" />
          <span class="upsells_grid_element_name"> {{ upsell.name }} </span>
          <div class="upsells_grid_element_rating">
            <ate-star size="small" color="orange"></ate-star>
            <span class="upsells_grid_element_rating_number">
              {{ upsell.rating }}
            </span>
          </div>
        </a>
      </div>
    </div>
  </div>
</section>
