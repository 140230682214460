import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContainerModule } from '../../ui/containers/container.module';
import { SharedModule } from '../../shared/shared.module';
import { QuoteResolver } from './resolvers/quote.resolver';
import { QuoteRequestComponent } from './pages/quote-request/quote-request.component';
import { QuoteRequestContainer } from './pages/quote-request/quote-request.container';
import { RequestActionsComponent } from '../../shared/standalone/request-actions/request-actions.component';

const routes: Routes = [
  {
    path: ':uuid',
    component: QuoteRequestContainer,
    resolve: {
      quote: QuoteResolver,
    },
    children: [
      {
        path: '',
        component: QuoteRequestComponent,
        children: [
          {
            path: 'accept',
            component: RequestActionsComponent,
            resolve: {
              quote: QuoteResolver,
            },
            data: { action: 'accept' },
          },
          {
            path: 'decline',
            component: RequestActionsComponent,
            resolve: {
              quote: QuoteResolver,
            },
            data: { action: 'decline' },
          },
        ],
      },
      {
        path: 'listing',
        loadChildren: () =>
          import('../../features/listings/listing.module').then(
            m => m.ListingModule,
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), SharedModule, ContainerModule],
  exports: [RouterModule],
})
export class QuotesRoutingModule {}
