import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LightboxComponent } from './lightbox/lightbox.component';
import { LightboxImageComponent } from './lightbox/lightbox-image/lightbox-image.component';
import { ImageCarouselComponent } from './image-carousel/image-carousel.component';
import { CarouselComponent } from './carousel/carousel.component';
import { CarouselItemComponent } from './carousel/carousel-item/carousel-item.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [
    LightboxComponent,
    LightboxImageComponent,
    ImageCarouselComponent,
    CarouselComponent,
    CarouselItemComponent,
  ],
  exports: [
    LightboxComponent,
    LightboxImageComponent,
    ImageCarouselComponent,
    CarouselComponent,
    CarouselItemComponent,
  ],
  imports: [CommonModule, SharedModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CarouselLightboxModule {}
