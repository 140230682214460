import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PageContextService {
  private eventRequestUuid = new BehaviorSubject('');
  public getEventRequestUuid = this.eventRequestUuid.asObservable();

  setEventRequestUuid(uuid: string) {
    this.eventRequestUuid.next(uuid);
  }
}
