import { Component, Inject } from '@angular/core';
import { EventRequestService } from '../../services/event-request/event-request.service';
import { EventRequestType } from '../../services/event-request/event-request.type';
import { TabComponent } from '../../../../ui/containers/tabs/tab/tab.component';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-event-request-inbox',
  templateUrl: './event-request-inbox.component.html',
  styleUrls: ['./event-request-inbox.component.scss'],
})
export class EventRequestInboxComponent {
  isLoading = true;
  isError = false;
  activeData: EventRequestType[] = [];
  inactiveData: EventRequestType[] = [];

  constructor(
    private router: Router,
    public eventRequestService: EventRequestService,
    @Inject(DOCUMENT) readonly document: Document,
  ) {}

  get window(): Window {
    return this.document.defaultView;
  }
  tabSelected(selected: { tab: TabComponent; index: number }): void {
    if (selected.index === 0) {
      this.getEventRequestsActive();
    } else if (selected.index === 1) {
      this.getEventRequestsInActive();
    }
  }

  getEventRequestsActive(): void {
    this.isLoading = true;
    this.isError = false;

    this.eventRequestService.getActiveEventRequests().subscribe({
      next: data => {
        this.isLoading = false;
        this.activeData = data.data;
      },
      error: msg => {
        console.error(msg);
        this.isLoading = false;
        this.isError = true;
      },
    });
  }

  getEventRequestsInActive(): void {
    this.isLoading = true;

    this.eventRequestService.getInactiveEventRequests().subscribe({
      next: data => {
        this.isLoading = false;
        this.inactiveData = data.data;
      },
      error: msg => {
        console.error(msg);
        this.isLoading = false;
        this.isError = true;
      },
    });
  }

  openEventRequest(eventUuid: string, eventRequestUuid: string): void {
    this.router.navigate(['/event-request/', eventUuid, eventRequestUuid]);
  }

  openRequestQuotes(): void {
    this.window.open(environment.requestQuotesUrl, '_self');
  }
}
