import { createAction, props } from '@ngrx/store';

export interface ISetError {
  data: {
    statusCode: number;
    message: string;
  };
  entity: string;
  options: {
    customErrorHandling?: boolean;
  };
  status: number;
}

export interface ISetNotAuthorisedError {
  data?: {
    statusCode: number;
    message: string;
  };
  status?: number;
}

const SetError = createAction(
  '[App] Set Error',
  props<{ payload: ISetError }>(),
);

const RemoveError = createAction('[App] Remove Error');

const SetNotAuthorisedError = createAction(
  '[App] Set Error',
  props<{ payload: ISetNotAuthorisedError }>(),
);

const RemoveNotAuthorisedError = createAction('[App] Set Error');

export const apiActions = {
  SetError,
  RemoveError,
  SetNotAuthorisedError,
  RemoveNotAuthorisedError,
};
