import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { EventRequestColorEnum } from '../../../../../ui/components/status-pill/status-pill-color.enum';
import { QuoteBodyType } from '../../../components/quote-body/quote-body.type';
import { QuoteResponse } from '../../../services/quote.type';
import { QuoteStatus } from '../../../../../ui/components/quotes-list/quote.type';

@Component({
  selector: 'app-quote-request-mobile',
  templateUrl: './quote-request-mobile.component.html',
  styleUrls: ['./quote-request-mobile.component.scss'],
})
export class QuoteRequestMobileComponent {
  @Input() public quote: QuoteResponse;
  @Input() public quoteBody: QuoteBodyType;
  @Input() public statusText = '';
  @Input() public statusColor: EventRequestColorEnum;
  @Input() public quoteUuid: string;
  @Input() status: QuoteStatus;

  constructor(private router: Router, private route: ActivatedRoute) {}

  goBack(): void {
    this.router.navigate([
      '../../event-request',
      this.quote.event.eventUuid,
      this.quote.event.eventRequestUuid,
    ]);
  }

  goToListing(): void {
    this.router.navigate(['listing', this.quote?.listing?.uuid], {
      relativeTo: this.route,
    });
  }
}
