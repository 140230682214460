import { Injectable } from '@angular/core';
import { Observable, filter, map } from 'rxjs';
import { EventRequestSingleType } from '../../services/event-request/event-request.type';
import { EventState, selectEvent } from '../../state';
import { Store } from '@ngrx/store';

@Injectable()
export class EventRequestOverviewService {
  constructor(private _store: Store<EventState>) {}

  public getEvent$(): Observable<EventRequestSingleType> {
    return this._store.select(selectEvent.getEvent).pipe(
      map(result => result),
      filter(
        eventRequestSingleType =>
          eventRequestSingleType?.event !== undefined &&
          eventRequestSingleType?.event !== null,
      ),
    );
  }
}
