import { ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { first, Observable, of, switchMap } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { quotesActions } from '../../../store/actions/quotes.actions';
import { QuotesState } from '../../../store/reducers/quotes.reducer';
import { selectQuotes } from '../../../store/selectors/quotes.selectors';

@Injectable({ providedIn: 'root' })
export class QuoteResolver {
  constructor(private _store: Store<QuotesState>) {}

  /**
   * resolve Quote
   * call API to get Quote if quote matching route uuid does not exist in state
   *
   * @param route
   * @returns true
   */
  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    const quoteUuid = route.params.uuid || route.parent.params.uuid;

    return this._store.pipe(select(selectQuotes.getQuote), first()).pipe(
      switchMap(quote => {
        if (!quote) {
          this.getQuote(quoteUuid);
        }

        if (quote && quoteUuid !== quote.uuid) {
          this.getQuote(quoteUuid);
        }

        return of(true);
      }),
    );
  }

  /*
   * trigger fetching quote in effects
   * dispatch GetQuote action
   */
  private getQuote(id: string) {
    this._store.dispatch(quotesActions.GetQuote({ payload: { id } }));
  }
}
