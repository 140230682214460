import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss'],
})
export class ReadMoreComponent implements AfterViewInit {
  @Input() details = '';
  @Input() icon = true;
  @Input() open = false;
  @Input() readAll = false;
  @Input() lines = 6;
  @Input() moreText = 'Read More';
  @Input() lessText = 'Read Less';
  @Input() toggleCallback: (toggleCallback) => boolean;

  @ViewChild('paragraph') paragraph: ElementRef;

  needsReadMore = false;

  ngAfterViewInit() {
    setTimeout(() => this.onResize());
  }

  @HostListener('window:resize')
  onResize() {
    if (this.readAll) {
      return;
    }
    if (this.open && this.needsReadMore) {
      return;
    }
    const domEl = this.paragraph?.nativeElement;
    if (!domEl) {
      return;
    }
    this.needsReadMore = domEl.scrollHeight > domEl.clientHeight;
  }

  toggleOpen() {
    // Skip default behavior if custom callback does not return true.
    if (this.toggleCallback && this.toggleCallback(this) !== true) {
      return;
    }
    this.open = !this.open;
  }
}
