<div class="indicator" [attr.complete]="complete" [attr.active]="active">
  <div class="dot">
    <ate-icon *ngIf="complete" name="tick"></ate-icon>
  </div>
  <div class="line"></div>
</div>
<section [attr.complete]="complete" [attr.active]="active">
  <h4>{{ title }}</h4>
  <div [hidden]="complete" class="context">
    <ng-content></ng-content>
  </div>
</section>
