<p class="status-text" *ngIf="statusText">{{ statusText }}</p>

<header *ngIf="headerText">
  <p>{{ headerText }}</p>
</header>

<section>
  <ng-content select="[slot=content]"></ng-content>

  <p class="cta-message" *ngIf="ctaMessage">{{ ctaMessage }}</p>
  <ate-button-control
    *ngIf="displayCta"
    slot="cta"
    color="cta"
    (click)="ctaClick.emit()"
  >
    <ate-icon name="plus" slot="start"></ate-icon>
    {{ ctaButtonText }}
  </ate-button-control>
</section>
