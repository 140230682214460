/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  constructor(private http: HttpClient) {}

  downloadImage(imageUrl: string): void {
    const imgName = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
    this.http
      .get(imageUrl, { responseType: 'blob' as 'json' })
      .subscribe((res: any) => {
        const file = new Blob([res], { type: res.type });
        const blob = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = blob;
        link.download = imgName;

        link.click();

        window.URL.revokeObjectURL(blob);
        link.remove();
      });
  }

  downloadFile(fileUrl: string): void {
    const imgName = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = fileUrl;
    link.download = imgName;

    link.click();

    window.URL.revokeObjectURL(fileUrl);
    link.remove();
  }
}
