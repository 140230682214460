import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { EventRequestSupplierDeclines } from '../../../../features/event/services/event-request/event-request.type';

@Component({
  selector: 'app-quotes-declined',
  templateUrl: './quotes-declined.component.html',
  styleUrls: ['./quotes-declined.component.scss'],
})
export class QuotesDeclinedComponent {
  @Input() declinedQuotes: EventRequestSupplierDeclines[] = [];

  @Input() open = false;

  @ViewChild('mainSection', { static: true, read: ElementRef })
  mainContent: ElementRef;

  maxHeight = null;

  public togglePanel(open?: boolean): void {
    this.open = open ? open : !this.open;

    if (this.open) {
      this.maxHeight = this.mainContent.nativeElement.scrollHeight + 'px';
    } else {
      this.maxHeight = null;
    }
  }
}
