<app-page-container [mainMargin]="false">
  <div class="spinner">
    <ate-spinner *ngIf="isLoading"></ate-spinner>
  </div>
  <app-empty-message
    *ngIf="isError"
    class="error"
    icon="alert-v2"
    message="Unknown Error: Something went wrong"
  ></app-empty-message>

  <div class="center">
    <div class="container">
      <header *ngIf="!isLoading && !isError">
        <ate-icon (click)="goBack()" name="chevron-left-v2"></ate-icon>
        <div class="titles">
          <h3>{{ eventRequest?.primaryServiceTitle }}</h3>
          <h1>Request Details</h1>
        </div>
      </header>
      <section class="row triple">
        <div>
          <p><b>Event Type</b></p>
          <p>{{ eventRequest?.event?.eventType | eventType }}</p>
        </div>
        <div>
          <p><b>Services Required</b></p>
          <p>
            {{
              eventRequest?.requiredServices ||
                eventRequest?.primaryServiceTitle
            }}
          </p>
        </div>
      </section>
      <section class="row triple">
        <div>
          <p><b>Event Date</b></p>
          <p>{{ eventStartDate }}</p>
        </div>
        <div *ngIf="eventStartTime">
          <p><b>Start Time</b></p>
          <p>{{ eventStartTime }}</p>
        </div>
        <div *ngIf="eventDuration">
          <p><b>Duration required</b></p>
          <p>{{ eventDuration }}</p>
        </div>
      </section>
      <section class="row">
        <p><b>Event Location</b></p>
        <p>{{ eventRequest?.event?.geocode?.fullAddress }}</p>
        <div class="map">
          <ate-map
            [attr.api-key]="apiKey"
            [attr.lat]="eventRequest?.event?.geocode?.lat"
            [attr.lng]="eventRequest?.event?.geocode?.lng"
          ></ate-map>
        </div>
      </section>
      <section class="row" *ngFor="let response of formResponses">
        <p>
          <b>{{ response.label }}</b>
        </p>
        <p *ngIf="!response.isArray">{{ response.value }}</p>
        <ul *ngIf="response.isArray">
          <li *ngFor="let listItem of response.value">{{ listItem }}</li>
        </ul>
      </section>
      <section class="row">
        <p>
          <b>Organiser's Notes</b>
        </p>
        <p>{{ eventDescription }}</p>
      </section>
    </div>
  </div>
</app-page-container>
