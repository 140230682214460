<section class="inner-wrapper">
  <textarea
    #messageInput
    appTextAutoGrow
    (input)="updateMessage($event)"
    rows="1"
    [value]="value"
    [minRows]="1"
    [maxRows]="4"
    [placeholder]="placeholderText"
    [ngClass]="{ 'control-margin': !displayControls }"
  ></textarea>
  <!-- TODO: Use Text Area from Form Components -->
  <a *ngIf="displayControls && phoneNumber" [href]="'tel:' + phoneNumber"
    ><ate-icon class="phone" name="phone-v2"></ate-icon
  ></a>
  <ate-icon
    *ngIf="displayControls"
    class="send"
    (click)="sendMessage()"
    [ngClass]="{ active: value.length }"
    name="send-mail"
  ></ate-icon>
  <ate-button-control
    *ngIf="!displayControls"
    color="cta"
    href="#"
    class="send"
    (click)="sendMessage()"
    [ngClass]="{ active: value.length }"
    name="send-mail"
    >Send <ate-icon name="send-mail"></ate-icon
  ></ate-button-control>
</section>
