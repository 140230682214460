<app-listing-profile-picture
  [listingImage]="image ? (image | replace: '{style}':'square_thumbnail') : ''"
></app-listing-profile-picture>

<section>
  <h3>{{ listing?.title }}</h3>
  <div class="ratings">
    <ate-star-bar
      color="orange"
      [attr.percent]="listing?.stars * 20"
    ></ate-star-bar>
    <span>({{ listing?.ratings }})</span>
  </div>
</section>

<span *ngIf="listing.selected">
  <ate-icon name="tick-v2"></ate-icon>
</span>

<footer *ngIf="listing.actionButtons || listing.viewQuoteButton">
  <button
    class="action-button"
    *ngIf="listing.actionButtons"
    (click)="selectAction(quoteActionTypes.BOOK)"
  >
    Choose
  </button>
  <button
    class="action-button"
    *ngIf="listing.actionButtons"
    (click)="selectAction(quoteActionTypes.DECLINE)"
  >
    Decline
  </button>

  <ate-button-control
    class="view-quote-button"
    color="secondary"
    *ngIf="listing.viewQuoteButton"
    (click)="selectAction(quoteActionTypes.VIEW)"
  >
    <ate-icon name="chat-message"></ate-icon> View Quote
  </ate-button-control>
</footer>
