import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss'],
})
export class InfoBoxComponent {
  @Input() public statusText?;
  @Input() public headerText?;
  @Input() public ctaMessage?;
  @Input() public ctaButtonText?;
  @Input() public displayCta = false;
  @Output() ctaClick = new EventEmitter();
}
