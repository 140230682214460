<!-- MODALS  -->
<ng-container *ngIf="isLoading | async">
  <div class="spinner">
    <ate-spinner *ngIf="isLoading | async"></ate-spinner>
  </div>
  <app-version-modal></app-version-modal>
</ng-container>

<updates-available-modal></updates-available-modal>

<router-outlet></router-outlet>
