<section #carousel [class]="mode === 1 ? 'carousel' : null">
  <ng-content></ng-content>
</section>

<div *ngIf="pips" class="pips">
  <div
    *ngFor="let pip of pages; let i = index"
    (click)="setPage(i)"
    [ngClass]="{ active: i === currentPage }"
  ></div>
</div>
