import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-info-accordion',
  templateUrl: './info-accordion.component.html',
  styleUrls: ['./info-accordion.component.scss'],
})
export class InfoAccordionComponent implements OnInit {
  @Input() headerText = '';
  @Input() open = false;

  @ViewChild('mainSection', { static: true, read: ElementRef })
  mainContent: ElementRef;

  maxHeight = '0';
  public innerWidth: number;

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }

  public togglePanel(open?: boolean): void {
    this.open = open ? open : !this.open;

    if (this.open) {
      this.maxHeight = this.mainContent.nativeElement.scrollHeight + 'px';
    } else {
      this.maxHeight = null;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
}
