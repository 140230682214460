import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-step',
  templateUrl: './progress-step.component.html',
  styleUrls: ['./progress-step.component.scss'],
})
export class ProgressStepComponent {
  @Input() title: string;
  @Input() complete = false;
  @Input() active = false;
}
