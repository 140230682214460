import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { FullPageComponent } from './full-page/full-page.component';
import { HeaderComponent } from './header/header.component';
import { PageContainerComponent } from './page-container/page-container.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SubheaderComponent } from './subheader/subheader.component';
import { SharedModule } from '../../shared/shared.module';
import { TabComponent } from './tabs/tab/tab.component';
import { TabsComponent } from './tabs/tabs.component';
import { ActionPanelComponent } from './action-panel/action-panel.component';
import { ComponentsModule } from '../components/components.module';

@NgModule({
  declarations: [
    FooterComponent,
    FullPageComponent,
    HeaderComponent,
    PageContainerComponent,
    SidebarComponent,
    SubheaderComponent,
    TabsComponent,
    TabComponent,
    ActionPanelComponent,
  ],
  exports: [
    FooterComponent,
    FullPageComponent,
    HeaderComponent,
    PageContainerComponent,
    SidebarComponent,
    SubheaderComponent,
    TabsComponent,
    TabComponent,
    ActionPanelComponent,
  ],
  imports: [CommonModule, SharedModule, ComponentsModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ContainerModule {}
