import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { AppState } from './store/reducers/app.reducer';
import { Store } from '@ngrx/store';
import {
  Observable,
  Subject,
  combineLatest,
  first,
  of,
  switchMap,
  takeUntil,
} from 'rxjs';
import { MatSnackBar, MatSnackBarDismiss } from '@angular/material/snack-bar';
import { selectApp } from './store/selectors/app.selectors';
import { environment } from '../environments/environment';
import { authenticationQuery } from './store/selectors/authentication.selectors';
import * as Sentry from '@sentry/angular-ivy';
import { DatalayerService } from './shared/services/datalayer/datalayer.service';
import { FlashMessageService } from './shared/services/flash-message/flash-message.service';
import { FlashMessage } from './store/models/flash-message.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  public env: string = environment.version;
  public isLoading: Observable<boolean>;
  public title = 'organiser-app';
  public _unsubscribeAll$: Subject<boolean>;

  constructor(
    private _store: Store<AppState>,
    private _dataLayer: DatalayerService,
    private _snackBar: MatSnackBar,
    private _flashMessageService: FlashMessageService,
  ) {
    this.isLoading = this._store.select(selectApp.getIsLoading);
    this._unsubscribeAll$ = new Subject();
  }

  public ngAfterViewInit(): void {
    this._dataLayer.sendEvent('pageInitialised');
  }

  /**
   * Handle App wide Flash Messages
   */
  private handleFlashMessages() {
    this._flashMessageService
      .flashMessage$()
      .pipe(
        takeUntil(this._unsubscribeAll$),
        switchMap(flashMessage => {
          if (flashMessage.message) {
            return combineLatest([
              of(flashMessage),
              this._snackBar
                .open(flashMessage.message, flashMessage.action || 'Close', {
                  duration: flashMessage.timeout,
                  verticalPosition: 'top',
                  panelClass: ['snack-action'],
                })
                .afterDismissed(),
            ]);
          } else {
            return of([{}, {}]);
          }
        }),
      )
      .subscribe(
        ([flashMessage, event]: [FlashMessage, MatSnackBarDismiss]) => {
          if (event.dismissedByAction && flashMessage.actionCallback) {
            flashMessage.actionCallback(event);
          }
        },
      );

    this._flashMessageService
      .isShowingFlashMessage$()
      .pipe(takeUntil(this._unsubscribeAll$))
      .subscribe(isShowing => {
        if (!isShowing) {
          this._snackBar.dismiss();
        }
      });
  }

  sendUserIdToSentry() {
    this._store
      .select(authenticationQuery.getUser)
      .pipe(first())
      .subscribe({
        next: data => {
          if (data?.userId) {
            Sentry.setUser({
              id: data?.userId,
            });
          }
        },
        error: msg => {
          console.error(msg);
        },
      });
  }

  ngOnInit(): void {
    this.sendUserIdToSentry();
    console.info('AddToEvent Marketplace Version: v' + this.env);
    this.handleFlashMessages();
  }

  /**
   * Destroy Application Subscriptions
   */
  public ngOnDestroy(): void {
    this._unsubscribeAll$.complete();
  }
}
