<div class="container">
  <header (click)="togglePanel()">
    <ate-progress
      size="small"
      show-remaining="false"
      [attr.total]="this.steps.toArray().length"
      [attr.color]="'#476AF5'"
      [attr.completed]="currentStep"
    >
    </ate-progress>

    <div class="headings">
      <h4>{{ title }}</h4>
      <p>{{ progress !== 0 ? progress + '% -' : '' }} {{ subtitle }}</p>
    </div>

    <ate-icon [attr.open]="open" name="chevron-down-v2"></ate-icon>
  </header>

  <section
    #mainSection
    [style.max-height]="innerWidth < 990 ? maxHeight : null"
  >
    <ng-content></ng-content>
  </section>
</div>
