<header [attr.active]="active">
  <figure class="photo">
    <img [src]="eventRequest?.primaryServiceImageURI" />
  </figure>
  <div class="service-title">
    <h4>{{ eventRequest?.eventType | eventType }} • {{ eventStartTime }}</h4>
    <h3>{{ eventRequest?.primaryServiceTitle }}</h3>
  </div>
</header>

<app-status-pill [text]="statusText" [status]="status"></app-status-pill>
<p [attr.active]="active" [innerHTML]="description"></p>

<footer [attr.active]="active">
  <app-quote-count [count]="eventRequest?.quoteCount"></app-quote-count>
  <p>Updated {{ updatedAt }} ago</p>
</footer>
