import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventRequestDetailsComponent } from './pages/event-request-details/event-request-details.component';
import { EventRequestInboxComponent } from './pages/event-request-inbox/event-request-inbox.component';
import { EventRequestOverviewComponent } from './pages/event-request-overview/event-request-overview.component';
import { EventRoutingModule } from './event-routing.module';
import { ComponentsModule } from '../../ui/components/components.module';
import { ContainerModule } from '../../ui/containers/container.module';
import { EmptyMessageComponent } from './components/empty-message/empty-message.component';
import { ActiveEmptyMessageBoxComponent } from './components/empty-message/active-empty-message-box/active-empty-message-box.component';
import { InfoAccordionComponent } from './components/info-accordion/info-accordion.component';
import { ProgressStepComponent } from './components/progress-accordion/progress-step/progress-step.component';
import { ProgressAccordionComponent } from './components/progress-accordion/progress-accordion.component';
import { EventRequestService } from './services/event-request/event-request.service';
import { EventRequestOverviewMobileComponent } from './pages/event-request-overview/event-request-overview-mobile/event-request-overview-mobile.component';
import { EventRequestOverviewDesktopComponent } from './pages/event-request-overview/event-request-overview-desktop/event-request-overview-desktop.component';
import { InfoBoxComponent } from './components/info-box/info-box.component';
import { StoreModule } from '@ngrx/store';
import { EVENT_FEATURE_KEY, eventReducer } from './state';
import { EventEffects } from './state/event.effects';
import { EffectsModule } from '@ngrx/effects';
import { RequestActionsComponent } from '../../shared/standalone/request-actions/request-actions.component';
import { CloseEventRequestModalComponent } from '../../shared/standalone/modals/close-event-request-modal/close-event-request-modal.component';
import { SharedModule } from '../../shared/shared.module';
import { EnhancedFormsLauncherService } from '../../shared/services/enhanced-forms-launcher/enhanced-forms-launcher.service';
import { EventRequestOverviewService } from './pages/event-request-overview/event-request-overview.service';

@NgModule({
  declarations: [
    EventRequestDetailsComponent,
    EventRequestInboxComponent,
    EventRequestOverviewComponent,
    EventRequestOverviewMobileComponent,
    EventRequestOverviewDesktopComponent,
    EmptyMessageComponent,
    ActiveEmptyMessageBoxComponent,
    InfoAccordionComponent,
    ProgressStepComponent,
    ProgressAccordionComponent,
    InfoBoxComponent,
  ],
  imports: [
    CommonModule,
    EventRoutingModule,
    ComponentsModule,
    ContainerModule,
    CloseEventRequestModalComponent,
    RequestActionsComponent,
    StoreModule.forFeature(EVENT_FEATURE_KEY, eventReducer),
    EffectsModule.forFeature([EventEffects]),
    SharedModule,
  ],
  providers: [
    EventRequestService,
    EnhancedFormsLauncherService,
    EventRequestOverviewService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [EventRequestInboxComponent],
})
export class EventModule {}
