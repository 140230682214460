import { Injectable, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { appActions } from '../../../store/actions/app.actions';
import { FlashMessage } from '../../../store/models/flash-message.model';
import { AppState } from '../../../store/reducers/app.reducer';
import { selectApp } from '../../../store/selectors/app.selectors';

@Injectable()
export class FlashMessageService {
  constructor(
    @Inject('environment') private environment,
    private _store: Store<AppState>,
  ) {}

  /**
   * Accessor method for ShowingFlashMessage
   */
  public isShowingFlashMessage$(): Observable<boolean> {
    return this._store.pipe(select(selectApp.getShowingFlashMessage));
  }

  /**
   * Accessor method for FlashMessage
   */
  public flashMessage$(): Observable<FlashMessage> {
    return this._store.pipe(select(selectApp.getFlashMessage));
  }

  /**
   * Show a flash message through flash message state
   * @param flashMessage - FlashMessage eg: Success, Error, Warning, Info
   */
  public show(flashMessage: FlashMessage): void {
    if (typeof flashMessage.timeout === 'undefined') {
      flashMessage.timeout = this.environment.flashMessageTimeout || 0;
    }
    this._store.dispatch(
      appActions.SetFlashMessage({ flashMessage: flashMessage }),
    );
    this._store.dispatch(
      appActions.ToggleShowingFlashMessage({ payload: true }),
    );
  }

  /**
   * Hide a flash message through flash message state
   */
  public hide(): void {
    this._store.dispatch(
      appActions.ToggleShowingFlashMessage({ payload: false }),
    );
    this._store.dispatch(appActions.RemoveFlashMessage());
  }
}
