import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { WindowService } from '../../../../shared/services/window/window.service';
import { QuoteStatus } from '../../../../ui/components/quotes-list/quote.type';
import { QuoteListing, QuoteReview } from '../../services/quote.type';
import { environment } from '../../../../../environments/environment';
import { QuoteService } from '../../services/quote.service';
import { first } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/reducers/app.reducer';
import { appActions } from '../../../../store/actions/app.actions';
import { ModalClickEvent } from '@addtoevent/form-components/dist/types/enums';

@Component({
  selector: 'app-quote-context-menu',
  templateUrl: './quote-context-menu.component.html',
  styleUrls: ['./quote-context-menu.component.scss'],
})
export class QuoteContextMenuComponent implements OnInit {
  public emailHref: string;

  @Input() quoteUuid = '';
  @Input() listingUuid = '';
  @Input() listing: QuoteListing;
  @Input() supplierPhoneNumber: string;
  @Input() status: QuoteStatus;
  @Input() review?: QuoteReview;
  @Input() open = false;

  @Input() set listingTitle(value: string) {
    this.emailHref = `mailto:support@addtoevent.co.uk?subject=Complaint about quote from ${value} &body=Dear Add To Event,%2C%0A%0AI%20would%20like%20to%20make%20a%20complaint%20about%20a quote%20from ${value}`;
  }

  @Output() listingClick = new EventEmitter<void>();
  @ViewChild('undoModal') undoModal: ElementRef;
  public isMobile = true;

  constructor(
    private router: Router,
    private windowService: WindowService,
    private quoteService: QuoteService,
    private _store: Store<AppState>,
  ) {}

  private setIsLoading(isLoading: boolean): void {
    this._store.dispatch(appActions.SetIsLoading({ payload: { isLoading } }));
  }

  ngOnInit(): void {
    this.isMobile = this.windowService.isMobile();
  }

  toggleOpen(open?: boolean): void {
    if (typeof open === 'boolean') {
      this.open = open;
      return;
    }

    this.open = !this.open;
  }

  goToListing(): void {
    if (this.isMobile) {
      this.router.navigate(['listing/', this.listingUuid]);
    } else {
      this.listingClick.next();
    }
  }

  acceptQuote(): void {
    this.router.navigate(['/quote/', this.quoteUuid, 'accept'], {
      replaceUrl: true,
    });
  }

  declineQuote(): void {
    this.router.navigate(['/quote/', this.quoteUuid, 'decline'], {
      replaceUrl: true,
    });
  }

  leaveReview(): void {
    this.windowService.open(
      `${environment.webUrl}/node/${this.quoteUuid}/review/submit`,
      '_self',
    );
  }

  viewReview(): void {
    this.windowService.open(this.review.url, '_self');
  }

  isAccepted(): boolean {
    return this.status === QuoteStatus.ACCEPTED_FUTURE;
  }

  isDeclined(): boolean {
    return (
      this.status === QuoteStatus.REJECTED_FUTURE ||
      this.status === QuoteStatus.REJECTED_PAST
    );
  }

  confirmUndo(event: CustomEvent<{ modalClickEvent: ModalClickEvent }>) {
    if (
      event.detail?.modalClickEvent === ModalClickEvent.CONFIRM_BUTTON_CLICKED
    ) {
      this.undo(true);
    }
  }

  undo(confirmed = false) {
    const accepted = this.isAccepted();
    if (!accepted && !this.isDeclined()) {
      return;
    }
    if (!confirmed) {
      this.undoModal?.nativeElement?.open();
      return;
    }
    const uuid = this.quoteUuid;
    const service = this.quoteService;
    this.setIsLoading(true);
    const resp = accepted
      ? service.undoAcceptQuote(uuid)
      : service.undoDeclineQuote(uuid);
    resp.pipe(first()).subscribe({
      error: msg => {
        console.error(msg);
      },
      complete: () => {
        this.setIsLoading(false);
      },
    });
  }
}
