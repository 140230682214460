import { createReducer, on } from '@ngrx/store';
import {
  AddForgotPasswordAttempt,
  RemoveAuth,
  RemoveBeforeLogoutRoute,
  RemoveOrganisation,
  RemoveUser,
  SetAuth,
  SetBeforeLogoutRoute,
  SetOrganisation,
  SetUser,
  ToggleIsLoggedIn,
} from '../actions/authentication.actions';
import { Auth } from '../models/auth.model';
import { AuthUser } from '../models';
import { Organisation } from '../models/organisation.model';

export const AUTHENTICATION_FEATURE_KEY = 'authentication';

export interface AuthenticationPartialState {
  readonly [AUTHENTICATION_FEATURE_KEY]: AuthenticationState;
}

export interface AuthenticationState {
  isLoggedIn: boolean;
  beforeLogoutRoute?: string;
  loginAttempts: number;
  auth: Auth;
  user: AuthUser;
  organisation: Organisation;
  forgotPasswordAttempts: number;
}

export const initialState: AuthenticationState = {
  isLoggedIn: false,
  beforeLogoutRoute: undefined,
  loginAttempts: 0,
  forgotPasswordAttempts: 0,
  auth: {
    accessToken: undefined,
    refreshToken: undefined,
    expirationTime: undefined,
    jwt: undefined,
  },
  user: undefined,
  organisation: {
    uuid: undefined,
    access: undefined,
    groups: undefined,
  },
};

export const authReducer = createReducer(
  initialState,
  on(SetAuth, (state, { payload }) => {
    return {
      ...state,
      auth: { ...payload },
    };
  }),
  on(RemoveAuth, state => {
    return {
      ...state,
      auth: {
        ...initialState.auth,
      },
    };
  }),
  on(SetUser, (state, { payload }) => {
    return {
      ...state,
      user: {
        ...payload,
      },
    };
  }),
  on(RemoveUser, state => {
    return {
      ...state,
      user: {
        ...initialState.user,
      },
    };
  }),
  on(SetBeforeLogoutRoute, (state, { payload }) => {
    return {
      ...state,
      beforeLogoutRoute: payload,
    };
  }),
  on(RemoveBeforeLogoutRoute, state => {
    return {
      ...state,
      beforeLogoutRoute: undefined,
    };
  }),
  on(ToggleIsLoggedIn, (state, { payload }) => {
    return {
      ...state,
      isLoggedIn: payload,
    };
  }),
  on(SetOrganisation, (state, { payload }) => {
    return {
      ...state,
      organisation: {
        ...payload,
      },
    };
  }),
  on(RemoveOrganisation, state => {
    return {
      ...state,
      organisation: {
        ...initialState.organisation,
      },
    };
  }),
  on(AddForgotPasswordAttempt, state => {
    return {
      ...state,
      forgotPasswordAttempts: state.forgotPasswordAttempts + 1,
    };
  }),
);
