import { Component, Input } from '@angular/core';
import { NavigationStateService } from '../../../shared/services/navigation/navigation-state.service';

@Component({
  selector: 'app-page-container',
  templateUrl: './page-container.component.html',
  styleUrls: ['./page-container.component.scss'],
})
export class PageContainerComponent {
  @Input() mainMargin = true;
  @Input() mainPadding: 'small' | 'large' | '';
  @Input() footerPadding: 'small' | 'large' | '';

  constructor(private _navigationStateService: NavigationStateService) {
    this._navigationStateService.handlingRouterEvents();
  }
}
