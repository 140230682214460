import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'app-carousel-item',
  templateUrl: './carousel-item.component.html',
  styleUrls: ['./carousel-item.component.scss'],
})
export class CarouselItemComponent {
  @HostBinding('style.width') private width = '100%';
  @HostBinding('style.margin') private margin = '0';

  setInternalWidth(width: number): void {
    this.width = width + '%';
  }

  setInternalMargin(margin: string): void {
    this.margin = margin;
  }
}
