import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { compareVersions } from 'compare-versions';
import { ModalClickEvent } from '@addtoevent/form-components/dist/types/enums';
import { environment } from '../../../../environments/environment';
import { AppversionService } from '../../../shared/services/version/appversion_service';

@Component({
  selector: 'updates-available-modal',
  templateUrl: './updates-available.component.html',
  styleUrls: ['./updates-available.component.scss'],
})
export class UpdatesAvailableModalComponent implements OnInit {
  @ViewChild('ateModal') modal: ElementRef;

  constructor(private appVersionService: AppversionService) {}

  ngOnInit() {
    this.appVersionService.getAppVersionValueChanges().subscribe(
      ({ snapshot }) => {
        const appVersion = snapshot.val();
        const currentVersion = environment.version;

        if (compareVersions(currentVersion, appVersion) === -1) {
          this.promptUser();
        }
      },
      error => {
        console.error(error);
      },
    );
  }

  private promptUser() {
    if (
      this.modal?.nativeElement &&
      typeof this.modal.nativeElement.open === 'function'
    ) {
      this.modal.nativeElement.open();
    }
  }

  async reloadPage(event: CustomEvent<{ modalClickEvent: ModalClickEvent }>) {
    if (
      event.detail?.modalClickEvent === ModalClickEvent.CONFIRM_BUTTON_CLICKED
    ) {
      this.reload();
    }
  }

  reload() {
    window.location.reload();
  }
}
