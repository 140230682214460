<header>
  <h3>Quotes</h3>
  <div class="active-quote-count">{{ activeQuotes?.length }}</div>
</header>

<section class="quotes-section" *ngIf="activeQuotes?.length !== 0">
  <app-quote-list-item
    class="quotes-section__item"
    *ngFor="let quote of activeQuotes"
    [quote]="quote"
  ></app-quote-list-item>
</section>

<section class="no-quotes-section" *ngIf="activeQuotes?.length === 0">
  <div class="no-quotes-section__content">
    <ng-content></ng-content>
  </div>
</section>
