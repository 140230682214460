import { createReducer, Action, on } from '@ngrx/store';
import { EventRequestSingleType } from '../services/event-request/event-request.type';
import { eventActions } from './event.actions';

export const EVENT_FEATURE_KEY = 'event';

export interface EventState {
  selectedEvent: EventRequestSingleType;
  isLoading: boolean;
}

export const initialState: EventState = {
  selectedEvent: undefined,
  isLoading: false,
};

const reducer = createReducer(
  initialState,
  on(eventActions.SetEventSuccess, (state, { payload }) => ({
    ...state,
    selectedEvent: payload,
    isLoading: false,
  })),
  on(eventActions.SetEventFailure, state => ({
    ...state,
    selectedEvent: null,
    isLoading: false,
  })),
  on(eventActions.GetEvent, state => ({
    ...state,
    selectedEvent: null,
    isLoading: true,
  })),
);

export function eventReducer(state: EventState, action: Action): EventState {
  return reducer(state, action);
}
