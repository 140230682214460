import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers, metaReducers } from './store/reducers';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { AuthInterceptor, ErrorsInterceptor } from './shared/interceptors';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ListingModule } from './features/listings/listing.module';
import { QuotesModule } from './features/quotes/quotes.module';
import { EventModule } from './features/event/event.module';
import { LandingPagesModule } from './features/landing-pages/landing-pages.module';
import { SharedModule } from './shared/shared.module';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { UpdatesAvailableModalComponent } from './ui/components/updates-available/updates-available.component';
import { ErrorChunkDialogContainer } from './ui/containers/error-chunk-dialog/error-chunk-dialog.container';
import { GlobalErrorHandler } from './shared/services/errors/load-chunk-error.service';
import { ErrorHandlerService } from './shared/services/errors/error-handler.service';
import { FlashMessageService } from './shared/services/flash-message/flash-message.service';
import { ApiModule } from './api/api.module';
import { ApplicationEffects } from './store/effects/app.effects';

export const interceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorsInterceptor, multi: true },
];
@NgModule({
  declarations: [
    AppComponent,
    UpdatesAvailableModalComponent,
    ErrorChunkDialogContainer,
  ],
  imports: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideDatabase(() => getDatabase()),
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    EffectsModule.forRoot([]),
    StoreModule.forRoot(reducers, {
      metaReducers,
    }),
    ApiModule.forRoot(environment),
    StoreDevtoolsModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    ListingModule,
    QuotesModule,
    EventModule,
    LandingPagesModule,
    SharedModule,
    MatDialogModule,
    EffectsModule.forFeature([ApplicationEffects]),
    BrowserAnimationsModule,
    MatSnackBarModule,
  ],
  providers: [
    { provide: 'environment', useValue: environment },
    interceptorProviders,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    ErrorHandlerService,
    FlashMessageService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
