/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import {
  AUTHENTICATION_FEATURE_KEY,
  AuthenticationState,
  authReducer,
} from './authentication.reducer';
import { localStorageSync } from 'ngrx-store-localstorage';
import { userReducer, UserState, USER_FEATURE_KEY } from './user.reducer';
import {
  QUOTES_FEATURE_KEY,
  QuotesState,
  quotesReducer,
} from './quotes.reducer';
import { APP_FEATURE_KEY, appReducer } from './app.reducer';
import { AppState } from './app.reducer';

export interface State {
  readonly [AUTHENTICATION_FEATURE_KEY]: AuthenticationState;
  readonly [USER_FEATURE_KEY]: UserState;
  readonly [QUOTES_FEATURE_KEY]: QuotesState;
  readonly [APP_FEATURE_KEY]: AppState;
}

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function(state, action) {
    // Uncomment the following lines for console debugging information.
    // console.log('state', state);
    // console.log('action', action);

    return reducer(state, action);
  };
}

export function localStorageSyncReducer(
  reducer: ActionReducer<any>,
): ActionReducer<any> {
  return localStorageSync({
    keys: [AUTHENTICATION_FEATURE_KEY],
    rehydrate: true,
  })(reducer);
}

export const reducers: ActionReducerMap<State> = {
  [AUTHENTICATION_FEATURE_KEY]: authReducer,
  [USER_FEATURE_KEY]: userReducer,
  [QUOTES_FEATURE_KEY]: quotesReducer,
  [APP_FEATURE_KEY]: appReducer,
};

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [localStorageSyncReducer, debug]
  : [localStorageSyncReducer];
