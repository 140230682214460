import { Actions, ofType, createEffect } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

import { QuoteAPIService } from '../../api/services/quote-api.service';
import { quotesActions } from '../actions/quotes.actions';

@Injectable()
export class QuotesEffects {
  constructor(
    private _actions$: Actions,
    private _quoteService: QuoteAPIService,
  ) {}

  getQuote$ = createEffect(() =>
    this._actions$.pipe(
      ofType(quotesActions.GetQuote),
      switchMap(({ payload }) => {
        return this._quoteService.getOneQuote(payload.id).pipe(
          map(result => {
            return quotesActions.SetQuoteSuccess({
              payload: result,
            });
          }),
          catchError(() => {
            return of(quotesActions.SetQuoteFailure());
          }),
        );
      }),
    ),
  );
}
