import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState, USER_FEATURE_KEY } from '../reducers/user.reducer';

const getUserState = createFeatureSelector<UserState>(USER_FEATURE_KEY);

const getUserDetails = createSelector(getUserState, state => state?.details);

export const userQuery = {
  getUserDetails,
};
