/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { ReplaySubject } from 'rxjs';

import { ComponentsModule } from '../../../../ui/components/components.module';
import { SharedModule } from '../../../shared.module';
import { environment } from '../../../../../environments/environment';
import { BusinessType } from '../../../../features/listings/pages/listing/enums/business.enum';
import { Listing, SocialLink } from '../../../services/listing/interfaces';
import { ListingService } from '../../../services/listing/listing.service';
import { WindowService } from '../../../services/window/window.service';
import { CarouselLightboxModule } from '../../../../ui/carousel-lightboxes/carousel-lightbox.module';
import { LightboxComponent } from '../../../../ui/carousel-lightboxes/lightbox/lightbox.component';
import { CarouselComponent } from '../../../../ui/carousel-lightboxes/carousel/carousel.component';

@Component({
  selector: 'app-supplier-listing-modal',
  templateUrl: './supplier-listing-modal.component.html',
  styleUrls: ['./supplier-listing-modal.component.scss'],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    SharedModule,
    ComponentsModule,
    CarouselLightboxModule,
  ],
})
export class SupplierListingModalComponent implements OnInit {
  readonly SUPPLIER_PROFILE_IMAGE_LIMIT = 10;

  @ViewChild('ateModal') modal: ElementRef;
  @Input() public listingUuid: string;
  @Input() public phoneNumber: string;
  @ViewChildren('footerStep0, footerStep1', {
    read: TemplateRef,
  })
  public footerTemplates: QueryList<TemplateRef<any>>;

  @ViewChildren('bodyStep0, bodyStep1', {
    read: TemplateRef,
  })
  public bodyTemplates: QueryList<TemplateRef<any>>;
  public footer$ = new ReplaySubject<TemplateRef<any>>();
  public body$ = new ReplaySubject<TemplateRef<any>>();
  public title = 'Supplier Profile';
  public isLoading = true;
  public listing: Listing;
  public businessType: string;
  public socialLinks: SocialLink[] = [];
  public termsOfUseUrl = environment.termsOfUseUrl;
  public step = 0;
  public displayBackButton = false;
  public fullReviewId: string;

  @ViewChild(CarouselComponent) carousel: CarouselComponent;
  @ViewChild(LightboxComponent) lightbox: LightboxComponent;
  mouseX = 0;
  imageSelected = 0;

  constructor(
    public listingService: ListingService,
    @Inject(DOCUMENT) readonly document: Document,
    private windowService: WindowService,
  ) {}

  public open(): void {
    this.updateStep(0);
    this.footer$.next(this.footerTemplates.toArray()[0]);
    this.body$.next(this.bodyTemplates.toArray()[0]);
    this.modal.nativeElement.open();
  }

  public ngOnInit(): void {
    this.getListing();
  }

  public goToListingReview(uuid?: string): void {
    this.fullReviewId = uuid ? 'review-' + uuid : null;
    this.updateStep(1);
  }

  public toggleReviewCallback(uuid: string): () => void {
    return () => this.goToListingReview(uuid);
  }

  public navigateToSection(section: string) {
    window.location.hash = '';
    window.location.hash = section;
  }

  public goBackToFirstStep(): void {
    this.updateStep(0);
  }

  private getListing(): void {
    this.isLoading = true;

    this.listingService.getOneListing(this.listingUuid).subscribe({
      next: data => {
        this.isLoading = false;
        this.listing = data;

        this.businessType = BusinessType[this.listing.businessType];

        this.generateSocialLinks();
      },
      error: msg => {
        console.error(msg);
        this.isLoading = false;
      },
    });
  }

  /**
   * Triggers a phone call to the number provided.
   */
  public callPhoneNumber() {
    if (!this.phoneNumber) {
      return;
    }
    this.windowService.open(`tel:${this.phoneNumber}`);
  }

  private generateSocialLinks(): void {
    const social = this.listing?.socialLinks;
    if (!this.listing?.socialLinks) {
      return;
    }

    const websiteLink = social.findIndex(value => value.type === 'website');

    // if website link exists push to the top
    if (websiteLink) {
      const topWebsite = { ...social[websiteLink] };
      social.splice(websiteLink, 1);
      this.socialLinks = [topWebsite, ...social];
      return;
    }

    this.socialLinks = social;
  }

  public updateStep(newStep: number): void {
    this.step = newStep;
    this.footer$.next(this.footerTemplates?.toArray()[this.step]);
    this.body$.next(this.bodyTemplates?.toArray()[this.step]);

    switch (this.step) {
      case 0:
        this.displayBackButton = false;
        this.title = 'Supplier Profile';
        break;
      case 1:
        this.displayBackButton = true;
        this.title = 'All Reviews';
        break;
    }
  }

  public openImageLightbox(image: number, e): void {
    this.imageSelected = image;
    const action = e.changedTouches ? e.changedTouches[0] : e;

    this.mouseX = action.clientX;
  }

  onMouseUp(e) {
    const action = e.changedTouches ? e.changedTouches[0] : e;

    if (Math.abs(action.clientX - this.mouseX) < 100) {
      this.lightbox.selectImage(this.imageSelected);
    }
  }

  changeImage(direction: 'right' | 'left') {
    if (this.listing.images?.length === 0) return;

    const imageCount = Math.min(
      this.listing.images?.length || 0,
      this.SUPPLIER_PROFILE_IMAGE_LIMIT,
    );

    if (direction === 'right') {
      this.imageSelected =
        this.imageSelected + 1 < imageCount ? this.imageSelected + 1 : 0;
    } else {
      this.imageSelected =
        this.imageSelected > 0 ? this.imageSelected - 1 : imageCount - 1;
    }

    this.carousel.setPage(this.imageSelected);
  }
}
