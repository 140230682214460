<header>
  <ate-menu-burger
    class="header_element"
    [attr.is-open]="open"
    (click)="toggleMenu()"
  ></ate-menu-burger>
  <ate-logo class="header_element" (click)="handleLogoClick()"></ate-logo>
  <div class="header_element">
    <ate-button-control
      class="new-service-request"
      color="cta"
      (click)="openRequestQuotes()"
    >
      <ate-icon name="plus" slot="start"></ate-icon>
      New Service Request
    </ate-button-control>
    <div
      class="profile-menu"
      (outsideClick)="outsideClick()"
      (click)="toggleUserMenu()"
    >
      <app-profile-picture
        [shortName]="user?.displayName | shortname"
        [image]="profileImage$ | async"
      ></app-profile-picture>
      <p>{{ user?.displayName }}</p>
      <ate-icon name="chevron-down-v2"></ate-icon>

      <div
        class="user-dropdown"
        (click)="toggleUserMenu(false)"
        [attr.open]="userMenu"
      >
        <ul>
          <li (click)="goToEventRequestInbox()">
            <ate-icon name="inbox"></ate-icon> My Requests
          </li>
          <li (click)="goToProfile()">
            <ate-icon name="profile-circle-v2"></ate-icon> My Account
          </li>
          <li (click)="goToHelp()">
            <ate-icon name="question-v2"></ate-icon>Help Centre
          </li>
          <li *ngIf="isSupplier$ | async" (click)="goToSupplier()">
            <ate-icon name="switch-supplier"></ate-icon>Switch to Supplier View
          </li>
          <li (click)="logout()" class="slim">Log out</li>
        </ul>
      </div>
    </div>
  </div>
</header>

<aside [attr.open]="open">
  <app-sidebar
    [isSupplier]="isSupplier$ | async"
    [open]="open"
    (ToggleMenu)="toggleMenu()"
  ></app-sidebar>
</aside>
