import {
  AfterViewInit,
  Component,
  ContentChildren,
  Input,
  QueryList,
} from '@angular/core';
import { LightboxImageComponent } from './lightbox-image/lightbox-image.component';
import { DownloadService } from '../../../shared/services/download/download.service';

@Component({
  selector: 'app-lightbox',
  templateUrl: './lightbox.component.html',
  styleUrls: ['./lightbox.component.scss'],
})
export class LightboxComponent implements AfterViewInit {
  @Input() currentImage = 0;
  @Input() open = false;

  @ContentChildren(LightboxImageComponent) images: QueryList<
    LightboxImageComponent
  >;

  constructor(private downloadService: DownloadService) {}

  ngAfterViewInit(): void {
    if (this.open) this.selectImage(this.currentImage);
  }

  public selectImage(activeImage: number) {
    this.open = true;

    if (activeImage < 0) activeImage = this.images.length - 1;
    if (activeImage >= this.images.length) activeImage = 0;

    this.currentImage = activeImage;
    // complete all tabs before active step and activate current
    this.images.toArray().forEach((image: LightboxImageComponent, index) => {
      if (activeImage === index) {
        image.setActive(true);
        return;
      }

      image.setActive(false);
    });
  }

  public downloadImage(): void {
    const imageUrl = this.images.toArray()[this.currentImage].url;

    this.downloadService.downloadImage(imageUrl);
  }

  public closeLightBox(): void {
    this.open = false;
  }
}
