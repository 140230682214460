/* eslint-disable @typescript-eslint/no-explicit-any */
import { ErrorHandler, Inject, Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';

import * as Sentry from '@sentry/angular-ivy';
import { TimeoutError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorChunkDialogContainer } from '../../../ui/containers/error-chunk-dialog/error-chunk-dialog.container';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    @Inject(DOCUMENT) readonly document: Document,
    private _dialog: MatDialog,
    private _ngZone: NgZone,
  ) {}

  get window(): Window {
    return this.document.defaultView;
  }

  handleError(error: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
      this.handleChunkFailedError();
    } else {
      // Sentry log
      try {
        if (
          !(error instanceof HttpErrorResponse) &&
          !(error instanceof TimeoutError)
        ) {
          Sentry.captureException(error);
          // Log all non-chunk errors to the console
          console.error(error);
        }
      } catch (e) {
        /* empty */
      }
    }
  }

  public handleChunkFailedError() {
    this._ngZone.run(() => {
      const dialogRef = this._dialog.open(ErrorChunkDialogContainer, {
        backdropClass: 'error-chunk-modal',
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.window.location.reload();
        }
      });
    });
  }
}
