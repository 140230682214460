<ate-form-flow-modal
  #ateModal
  show-footer-buttons="false"
  [displayBackButton]="displayBackButton"
  (onBackButtonClick)="goBackToFirstStep()"
>
  <!-- Modal Header -->
  <h1 slot="header" header>{{ title }}</h1>

  <!-- Modal Body -->
  <section slot="body">
    <div class="spinner">
      <ate-spinner *ngIf="isLoading"></ate-spinner>
    </div>

    <div *ngIf="!isLoading">
      <ng-container [ngTemplateOutlet]="body$ | async"> </ng-container>
    </div>
  </section>

  <!-- Modal Footer -->
  <footer slot="footer">
    <ate-button-control
      *ngIf="step > 2"
      (click)="updateStep(step > 0 ? step - 1 : 0)"
      class="back-button"
      size="xsmall"
      color="back"
      fill="false"
      ><ate-icon name="chevron-left-v2" slot="start"></ate-icon
      >Back</ate-button-control
    >
    <ng-container [ngTemplateOutlet]="footer$ | async"> </ng-container>
  </footer>
</ate-form-flow-modal>

<ng-template #bodyStep0>
  <div class="inner-container">
    <section class="main-content" *ngIf="!isLoading">
      <div class="image-range">
        <ng-container *ngIf="listing?.images?.length > 1">
          <ate-icon
            class="image-range__action-left"
            (click)="$event.stopPropagation(); changeImage('left')"
            name="chevron-left-v2"
          ></ate-icon>
          <ate-icon
            class="image-range__action-right"
            (click)="$event.stopPropagation(); changeImage('right')"
            name="chevron-right-v2"
          ></ate-icon>
        </ng-container>
        <app-carousel
          [mode]="2"
          class="image-carousel"
          [pips]="true"
          (touchend)="onMouseUp($event)"
          (mouseup)="onMouseUp($event)"
        >
          <app-carousel-item
            *ngFor="
              let image of listing?.images
                | slice: 0:SUPPLIER_PROFILE_IMAGE_LIMIT;
              let i = index
            "
          >
            <img
              draggable="false"
              (touchstart)="openImageLightbox(i, $event)"
              (mousedown)="openImageLightbox(i, $event)"
              (click)="openImageLightbox(i, $event)"
              [src]="image | replace: '{style}':'one_half'"
            />
          </app-carousel-item>
        </app-carousel>

        <app-lightbox>
          <app-lightbox-image
            *ngFor="let image of listing?.images"
            [url]="image | replace: '{style}':'x_large'"
          >
          </app-lightbox-image>
        </app-lightbox>
      </div>
      <div class="details-container">
        <div class="title-container">
          <h1>{{ listing?.title }}</h1>
          <ate-button-control
            class="phone-button"
            color="secondary"
            (click)="callPhoneNumber()"
          >
            <ate-icon name="phone-v2"></ate-icon
            >{{ phoneNumber }}</ate-button-control
          >
        </div>

        <div class="meta">
          <ate-star color="orange"></ate-star>
          <a fragment="reviews" (click)="navigateToSection('reviews')"
            >{{ listing?.reviewRating }}
          </a>
          <a fragment="reviews" (click)="navigateToSection('reviews')"
            >({{ listing?.reviewCount }} reviews)
          </a>
          <span *ngIf="listing?.address?.city && listing?.address?.region"
            >•</span
          >

          <a fragment="business" (click)="navigateToSection('business')">
            <span *ngIf="listing?.address?.city"
              >{{ listing?.address?.city }},</span
            >
            {{ listing?.address?.region }}
          </a>
        </div>

        <section class="listing-description" *ngIf="listing?.description">
          <p>{{ listing?.description }}</p>
        </section>

        <ul class="social-links">
          <li *ngFor="let social of socialLinks">
            <a [href]="social.url" target="_blank">
              <ate-icon
                [attr.name]="
                  social.type === 'website' ? 'website-v2' : social.type
                "
              ></ate-icon>
              <span>{{
                social.type === 'website'
                  ? 'Supplier Website'
                  : social.type + ' Profile'
              }}</span>
            </a>
          </li>
        </ul>

        <section class="about-listing" *ngIf="listing?.description">
          <h3>About {{ listing?.title }}</h3>
          <app-read-more [details]="listing?.description"></app-read-more>
        </section>

        <section *ngIf="listing?.clients" class="typical-clients">
          <h3>Typical Clients</h3>
          <p>{{ listing?.clients }}</p>
        </section>

        <section id="reviews" class="review" *ngIf="listing?.reviews.length">
          <header>
            <ate-star color="orange"></ate-star>
            <span>{{ listing?.reviewRating }}</span>
            <span>({{ listing?.reviewCount }}) reviews</span>
          </header>

          <app-carousel
            [perPage]="listing?.reviews.length === 1 ? 1 : 1.2"
            [firstAndLastChildMargin]="32"
          >
            <app-carousel-item *ngFor="let review of listing?.reviews">
              <app-review-list-item
                [review]="review"
                [toggleCallback]="toggleReviewCallback(review.uuid)"
                [moreText]="'View Full Review'"
              ></app-review-list-item>
            </app-carousel-item>
          </app-carousel>

          <a class="full-reviews" (click)="goToListingReview()">
            <span>View All Reviews</span>
          </a>
        </section>

        <section id="reviews" class="review" *ngIf="!listing?.reviews.length">
          <header class="no-reviews">
            No Reviews (yet)
          </header>

          <p>This supplier does not yet have any reviews</p>
        </section>

        <section id="business" class="business-details">
          <h3>Business Details</h3>

          <h4 *ngIf="listing?.address">Business Address:</h4>
          <p *ngIf="listing?.address">
            <span>{{ listing?.address?.line1 }}</span>
            <span>{{ listing?.address?.line2 }}</span>
            <span>{{ listing?.address?.city }}</span>
            <span>{{ listing?.address?.postcode }}</span>
            <span>{{ listing?.address?.country }}</span>
          </p>

          <h4>Insurance:</h4>
          <p>Please confirm with supplier</p>
        </section>

        <p class="terms">
          This information has been submitted by <b>{{ listing?.title }}</b
          >.<br />
          Please verify this information before going ahead with any<br />bookings.
          <a [href]="termsOfUseUrl">View our full terms and conditions</a>.
        </p>
      </div>
    </section>
  </div>
</ng-template>

<ng-template #bodyStep1>
  <app-listing-reviews
    [listingUuid]="this.listing?.uuid"
    [modalView]="true"
    [scrollToId]="fullReviewId"
  ></app-listing-reviews>
</ng-template>
