<app-action-panel [open]="open" [isMobile]="isMobile">
  <ul>
    <li (click)="goToListing()">
      <ate-icon name="profile"></ate-icon>
      View Supplier Profile
    </li>
    <a *ngIf="supplierPhoneNumber" [href]="'tel:' + supplierPhoneNumber"
      ><ate-icon class="phone" name="phone"></ate-icon> Call Supplier
    </a>
    <a [href]="emailHref">
      <ate-icon name="flag"></ate-icon>
      Report Supplier
    </a>
    <ng-container>
      <hr *ngIf="isMobile" />

      <li
        class="book"
        (click)="acceptQuote()"
        *ngIf="
          status === 'PUBLISHED_NEW' ||
          status === 'PUBLISHED_VIEWED' ||
          status === 'PUBLISHED_MESSAGED'
        "
      >
        <ate-icon name="tick" aria-label="ate tick v2"></ate-icon>
        Choose Supplier
      </li>

      <li
        class="decline"
        (click)="declineQuote()"
        *ngIf="
          status === 'PUBLISHED_NEW' ||
          status === 'PUBLISHED_VIEWED' ||
          status === 'PUBLISHED_MESSAGED'
        "
      >
        <ate-icon name="close-v2" aria-label="ate tick v2"></ate-icon>
        Decline Supplier
      </li>

      <!--  Is in the future and accepted -->
      <li class="accepted" *ngIf="isAccepted()">
        <ate-icon name="tick" aria-label="ate tick v2"></ate-icon>
        <span>
          You have chosen this supplier
        </span>
      </li>

      <!--  Is in the future and rejected -->
      <li class="rejected" *ngIf="isDeclined()">
        <ate-icon name="close-v2" aria-label="ate cross v2"></ate-icon>
        You have declined this supplier
      </li>

      <li class="undo" *ngIf="isDeclined() || isAccepted()">
        <ate-button-control
          class="undo-button"
          color="secondary"
          (click)="undo()"
          >Undo</ate-button-control
        >
      </li>

      <!--  Is in the future-->
      <!--      <li *ngIf="status === 'ACCEPTED_FUTURE' || status === 'REJECTED_FUTURE'">Undo </li>-->

      <!--  Is in the past -->
    </ng-container>

    <li
      class="leave-review"
      *ngIf="
        (status === 'ACCEPTED_PAST' || status === 'PUBLISHED_PAST') &&
        !review?.dateCreated
      "
      (click)="leaveReview()"
    >
      <ate-icon name="star-empty" aria-label="ate star"></ate-icon>
      Leave a review
    </li>

    <li
      class="decline"
      (click)="declineQuote()"
      *ngIf="status === 'PUBLISHED_PAST'"
    >
      <ate-icon name="close-v2" aria-label="ate tick v2"></ate-icon>
      Supplier Not Used
    </li>

    <li
      class="review"
      [ngClass]="{ reviewed: review?.dateCreated }"
      *ngIf="review?.dateCreated"
    >
      <ate-star-bar
        color="orange"
        [attr.percent]="review.stars * 20"
      ></ate-star-bar>
      <span>You wrote a {{ review.stars }}-star review for this supplier</span>
      <ate-button-control class="view-review" (click)="viewReview()"
        >View Review</ate-button-control
      >
    </li>
  </ul>
</app-action-panel>

<ate-modal
  #undoModal
  [attr.cta-text]="isAccepted() ? 'Deselect' : 'Undecline'"
  (onModalClose)="confirmUndo($event)"
>
  <div slot="header">
    <h3>
      Are you sure you want to {{ isAccepted() ? 'deselect' : 'undecline' }} the
      Quote from {{ listing?.title }}?
    </h3>
  </div>
</ate-modal>
