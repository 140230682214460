import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filename' })
export class FilenamePipeline implements PipeTransform {
  transform(value: string) {
    try {
      return decodeURI(value.substring(value.lastIndexOf('/') + 1)); // get everything after last /
    } catch (e) {
      return value;
    }
  }
}
