import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from './error/error.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { MaintenanceService } from '../../shared/services/maintence/maintenance.service';
import { ContainerModule } from '../../ui/containers/container.module';

@NgModule({
  declarations: [ErrorComponent, MaintenanceComponent],
  exports: [ErrorComponent, MaintenanceComponent],
  imports: [CommonModule, ContainerModule],
  providers: [MaintenanceService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LandingPagesModule {}
