import { catchError, first, of, shareReplay } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { SetUserDetails } from '../../../store/actions/user.actions';
import { userQuery } from '../../../store/selectors/user.selectors';
import { environment } from '../../../../environments/environment';
import { UserState } from '../../../store/reducers/user.reducer';
import { ListingSearch } from '../listing/interfaces';

export interface IUserDetails {
  profileImageUrl?: string;
  listings?: {
    itemCount: number;
    totalItems: number;
    pageCount: number;
    items: ListingSearch[];
  };
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private _store: Store<UserState>,
    private _userStore: Store<UserState>,
    private http: HttpClient,
  ) {}

  /**
   * Return a list of listings for the current user and profile image.
   *
   * The user is derived from the auth token passed in the headers.
   */
  public getUserDetails() {
    return this.http
      .get<IUserDetails>(`${environment.apiUrl}/org/users/details`, {
        headers: {
          'Content-Type': 'application/ate.organiser.v1+json',
        },
      })
      .pipe(shareReplay(1));
  }

  /**
   * SET user details in store
   * determine if user is isSupplier by checking if any listings are available
   * get profileImageUrl
   */
  public setUserDetails(): void {
    this._userStore
      .select(userQuery.getUserDetails)
      .pipe(first())
      .subscribe(details => {
        // User details has already been fetched
        if (details !== undefined) {
          return;
        }
        this.setUserDetailsInState();
      });
  }

  private setUserDetailsInState() {
    this.getUserDetails()
      .pipe(
        first(),
        catchError(error => {
          console.error(error);
          return of();
        }),
      )
      .subscribe((userDetails: IUserDetails): void => {
        this._store.dispatch(
          SetUserDetails({
            payload: {
              isSupplier: userDetails?.listings?.items?.length > 0,
              profileImageUrl: userDetails?.profileImageUrl,
            },
          }),
        );
      });
  }
}
