<ng-container>
  <ng-container *ngIf="isMobile; else desktopView">
    <app-page-container
      [mainMargin]="false"
      [mainPadding]="'small'"
      [footerPadding]="config?.footerButton ? 'small' : ''"
    >
      <div class="spinner">
        <ate-spinner *ngIf="isLoading"></ate-spinner>
      </div>
      <app-event-request-overview-mobile
        *ngIf="!isLoading"
        [eventRequest]="eventRequest"
        [status]="status"
        [statusText]="statusText"
        [config]="config"
      ></app-event-request-overview-mobile
    ></app-page-container>

    <div class="fixed-button" *ngIf="config?.footerButton">
      <ate-button-control color="cta" (click)="openRequestQuotes()">
        <ate-icon name="plus" slot="start"></ate-icon>
        Submit New Request
      </ate-button-control>
    </div>
  </ng-container>

  <ng-template #desktopView>
    <app-page-container
      [mainMargin]="false"
      [mainPadding]="'small'"
      [footerPadding]="''"
    >
      <div class="spinner">
        <ate-spinner *ngIf="isLoading"></ate-spinner>
      </div>

      <app-event-request-overview-desktop
        *ngIf="!isLoading"
        [eventRequest]="eventRequest"
        [status]="status"
        [statusText]="statusText"
        [config]="config"
        (openRequestQuotes)="openRequestQuotes()"
      ></app-event-request-overview-desktop>
    </app-page-container>
  </ng-template>
</ng-container>

<router-outlet></router-outlet>
