import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { WindowService } from '../../../../shared/services/window/window.service';
import { EventRequestColorEnum } from '../../../../ui/components/status-pill/status-pill-color.enum';
import { QuoteBodyType } from '../../components/quote-body/quote-body.type';
import { QuoteResponse } from '../../services/quote.type';
import { QuoteService } from '../../services/quote.service';
import { QuotesState } from '../../../../store/reducers/quotes.reducer';
import { selectQuotes } from '../../../../store/selectors/quotes.selectors';
import { PageContextService } from '../../../../shared/services/page-context/page-context.service';

@Component({
  selector: 'app-quote-request',
  templateUrl: './quote-request.component.html',
  styleUrls: ['./quote-request.component.scss'],
})
export class QuoteRequestComponent implements OnInit {
  public isLoading = true;
  public quoteUuid: string;
  public quote: QuoteResponse;
  public quoteBody: QuoteBodyType;
  public statusText = '';
  public statusColor: EventRequestColorEnum;
  public isMobile = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<QuotesState>,
    public quoteService: QuoteService,
    public windowService: WindowService,
    private _pageContextService: PageContextService,
  ) {
    this.isMobile = this.windowService.isMobile();
  }

  ngOnInit(): void {
    this.quoteUuid = this.route.snapshot.paramMap.get('uuid');
    this.subscribeToQuote();
  }

  @HostListener('window:resize')
  onResize() {
    this.isMobile = this.windowService.isMobile();
  }

  subscribeToQuote(): void {
    this.store
      .select(selectQuotes.getQuote)
      .pipe()
      .subscribe({
        next: data => {
          if (data && data.uuid === this.quoteUuid) {
            this.quote = data;
            this.isLoading = false;
            this.processQuoteBody(data);
            this.processStatus();
            this._pageContextService.setEventRequestUuid(
              data.event.eventRequestUuid,
            );
          }
        },
        error: msg => {
          console.error(msg);
          this.isLoading = false;
        },
      });
  }

  processQuoteBody(quoteResponse: QuoteResponse): void {
    this.quoteBody = {
      description: quoteResponse.description,
      estimate: quoteResponse.quotePrice,
      headerImages: quoteResponse.quoteImages.length
        ? quoteResponse.quoteImages
        : quoteResponse.listing.images,
      attachedImages: quoteResponse.quoteImages,
      attachments: quoteResponse.attachments,
      quoteSent: quoteResponse.sendDate,
      quoteStatus: quoteResponse.quoteStatus,
      uuid: quoteResponse.uuid,
    };
  }

  processStatus(): void {
    const { statusText, statusColor } = this.quoteService.processStatus(
      this.quote.quoteStatus,
    );

    this.statusText = statusText;
    this.statusColor = statusColor;
  }

  goToListing(): void {
    this.router.navigate(['/listing/', this.quote?.listing?.uuid]);
  }
}
