import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Listing, ListingReviews, ListingSearchResponse } from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class ListingService {
  constructor(private http: HttpClient) {}

  getOneListing(uuid: string): Observable<Listing> {
    return this.http.get<Listing>(`${environment.apiUrl}/org/listing/${uuid}`, {
      headers: {
        'Content-Type': 'application/ate.organiser.v1+json',
      },
    });
  }

  /**
   * Return a list of listings for the current user.
   *
   * The user is derived from the auth token passed in the headers.
   */
  getListingsForUser(): Observable<ListingSearchResponse> {
    return this.http
      .get<ListingSearchResponse>(`${environment.apiUrl}/org/user/listings`, {
        headers: {
          'Content-Type': 'application/ate.organiser.v1+json',
        },
      })
      .pipe(shareReplay(1));
  }

  getOneListingReviews(
    uuid: string,
    page: number,
    limit: number,
  ): Observable<ListingReviews> {
    return this.http.get<ListingReviews>(
      `${environment.apiUrl}/org/listing/${uuid}/reviews?page=${page}&limit=${limit}`,
      {
        headers: {
          'Content-Type': 'application/ate.organiser.v1+json',
        },
      },
    );
  }
}
