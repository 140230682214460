<ng-content></ng-content>
<div class="container">
  <header [attr.three]="quoteBody?.headerImages?.length === 3">
    <img
      *ngFor="let image of quoteBody?.headerImages | slice: 0:3; let i = index"
      [attr.three]="quoteBody?.headerImages?.length > 2 && i === 0"
      [src]="image | replace: '{style}':'one_half'"
    />

    <img
      *ngIf="quoteBody?.headerImages?.length === 0"
      [src]="
        '/assets/quote-page/default-quote-image.jpg'
          | replace: '{style}':'one_half'
      "
    />
  </header>

  <p class="quote-estimate" *ngIf="quoteBody?.estimate">
    <span>Quote Estimate:</span> <span>£{{ quoteBody.estimate }}</span>
  </p>

  <app-view-more-details
    [details]="quoteBody?.description"
  ></app-view-more-details>

  <p class="quote-sent" *ngIf="quoteBody?.quoteSent">
    Quote sent on {{ quoteBody?.quoteSent | longFormDate }}
  </p>

  <app-quote-accordion
    *ngIf="quoteBody?.attachedImages?.length"
    [title]="'Image'"
    [count]="quoteBody?.attachedImages?.length"
  >
    <app-image-carousel
      [images]="quoteBody?.attachedImages"
    ></app-image-carousel>
  </app-quote-accordion>
  <app-quote-accordion
    *ngIf="quoteBody?.attachments?.length"
    class="attachment-accordion"
    [title]="'Attachment'"
    [count]="quoteBody?.attachments?.length"
  >
    <app-download-file
      *ngFor="let attachment of quoteBody?.attachments"
      [file]="attachment"
    ></app-download-file>
  </app-quote-accordion>

  <section class="action-buttons" *ngIf="canBook()">
    <ate-button-control (click)="bookSupplier()"
      >Choose Supplier</ate-button-control
    >
    <ate-button-control (click)="declineSupplier()" [attr.color]="'secondary'"
      >Decline</ate-button-control
    >
  </section>

  <section
    class="action-buttons"
    *ngIf="quoteBody?.quoteStatus === 'ACCEPTED_PAST'"
  >
    <ate-button-control class="leave-review" (click)="leaveReview()"
      >Leave a Review</ate-button-control
    >
  </section>

  <section
    class="action-buttons"
    *ngIf="quoteBody?.quoteStatus === 'PUBLISHED_PAST'"
  >
    <ate-button-control class="leave-review" (click)="leaveReview()"
      >Leave a Review</ate-button-control
    >
    <ate-button-control class="not-used-supplier" (click)="declineSupplier()"
      >Supplier Not Used</ate-button-control
    >
  </section>

  <section class="action-buttons" *ngIf="isAccepted()">
    <div class="undo-button">
      <button (click)="undo()">Undo</button>
    </div>
    <div class="accepted">
      <ate-icon name="tick" aria-label="ate tick v2"></ate-icon>
      <span>
        You have chosen this supplier
      </span>
    </div>
  </section>

  <section class="action-buttons" *ngIf="isDeclined()">
    <div class="undo-button">
      <button (click)="undo()">Undo</button>
    </div>
    <div class="rejected">
      <ate-icon name="close-v2" aria-label="ate cross v2"></ate-icon>
      <span>
        You have declined this supplier
      </span>
    </div>
  </section>

  <section
    class="action-buttons"
    *ngIf="
      quoteBody?.quoteStatus === 'PUBLISHED_REVIEWED' && review?.dateCreated
    "
  >
    <div class="reviewed">
      <div>
        <ate-star-bar
          color="orange"
          [attr.percent]="review?.stars * 20"
        ></ate-star-bar>
        <span
          >You wrote a {{ review?.stars }}-star review for this supplier</span
        >
      </div>
      <ate-button-control class="view-review" (click)="viewReview()"
        >View Review</ate-button-control
      >
    </div>
  </section>
</div>

<ate-modal
  #undoModal
  [attr.cta-text]="isAccepted() ? 'Deselect' : 'Undecline'"
  (onModalClose)="confirmUndo($event)"
>
  <div slot="header">
    <h3>
      Are you sure you want to {{ isAccepted() ? 'deselect' : 'undecline' }} the
      Quote from {{ listingTitle }}?
    </h3>
  </div>
</ate-modal>
