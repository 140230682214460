/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { from, throwError, Observable, Subject, of } from 'rxjs';
import {
  mergeMap,
  catchError,
  first,
  tap,
  map,
  switchMap,
} from 'rxjs/operators';

import { Store, select } from '@ngrx/store';
import { JWTService } from '../jwt/jwt.service';
import { AuthenticationState } from '../../../store/reducers/authentication.reducer';

import { authenticationQuery } from '../../../store/selectors/authentication.selectors';
import { Auth as AuthModel } from '../../../store/models/auth.model';
import {
  Login,
  Logout,
  RemoveAuth,
  RemoveBeforeLogoutRoute,
  RemoveOrganisation,
  RemoveUser,
  SetAuth,
  SetBeforeLogoutRoute,
  SetUser,
  ToggleIsLoggedIn,
} from '../../../store/actions/authentication.actions';
import {
  Auth,
  User,
  onIdTokenChanged,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signOut,
} from '@angular/fire/auth';

@Injectable()
export class AuthService implements OnDestroy {
  public _unsubscribeAll$: Subject<any>;

  constructor(
    private _store: Store<AuthenticationState>,
    private _auth: Auth,
    private _jwtService: JWTService,
    private _router: Router,
  ) {
    this._unsubscribeAll$ = new Subject();
  }

  /**
   * Unsubscribe On Destroy
   */
  public ngOnDestroy(): void {
    this._unsubscribeAll$.complete();
  }

  /**
   * Authenticates a user with a email and password setting authentication state
   * @param email - Email string
   * @param password - Password string
   */
  public authenticate$(email: string, password: string): Observable<boolean> {
    // @TODO: Dispatch loginAttempts++ here
    // @TODO: Throw error if Login attempts is greater than 5
    return from(signInWithEmailAndPassword(this._auth, email, password)).pipe(
      // @TODO: Add FireAuthResponse type and type this correctly
      mergeMap(
        (authResp: any): Observable<any> => {
          const user = this.handleAuthorisedResponse(authResp);

          return this.navigateToBeforeLogoutRoute$(user);
        },
      ),
      catchError((error: Observable<any>) => {
        // @TODO: Handle non-auth type errors here
        console.error(error);
        return throwError(error);
      }),
    );
  }

  /**
   * Login into app using custom token
   * @param token - Custom Token
   */
  public signInWithCustomToken$(token: string): Observable<any> {
    return from(signInWithCustomToken(this._auth, token)).pipe(
      mergeMap(
        (authResp: any): Observable<any> => {
          return of(this.handleAuthorisedResponse(authResp));
        },
      ),
      catchError((error: Observable<any>) => {
        console.error(error);
        return throwError(error);
      }),
    );
  }

  /**
   * Unauthenticates a user resetting authentication state with a beforeLogoutRoute
   */
  public unauthenticate(): Observable<any> {
    return from(signOut(this._auth)).pipe(
      tap(() => {
        this._store.dispatch(RemoveAuth());
        this._store.dispatch(RemoveUser());
        this._store.dispatch(RemoveOrganisation());
        this._store.dispatch(Logout());
        this._store.dispatch(ToggleIsLoggedIn({ payload: false }));
        // @TODO: Make sure this works with params in router path
        this._store.dispatch(
          SetBeforeLogoutRoute({ payload: this._router.url }),
        );
      }),
    );
  }

  /**
   * Checks if user is authorised, usually called when there is a 401 or 403
   * @NOTE: Firebase doesn't have a User type :(
   */
  public checkAuthorised$(): Observable<any> {
    const observer = new Subject();

    this._auth.onAuthStateChanged(user => observer.next(user));

    return observer;
  }

  /**
   * Navigates to a before logout route if it exists
   */
  private navigateToBeforeLogoutRoute$(user): Observable<boolean> {
    return this._store.pipe(
      select(authenticationQuery.getBeforeLogoutRoute),
      mergeMap(
        (route: string): Observable<boolean> => {
          if (route) {
            this._router.navigate([route]);
          }

          this._store.dispatch(RemoveBeforeLogoutRoute());

          return of(user);
        },
      ),
    );
  }

  // TODO
  // should be refactored - supplier implementation does not fit here
  /**
   * Handles authorised response and updates the current state
   * @param authResp - Firebase Auth Response
   */
  private handleAuthorisedResponse(authResp: any) {
    const result = authResp.user.toJSON();
    const tokenManager = result.stsTokenManager;
    const jwt = this._jwtService.parseJWT(tokenManager.accessToken);

    const authState = {
      accessToken: tokenManager.accessToken,
      refreshToken: tokenManager.refreshToken,
      expirationTime: tokenManager.expirationTime,
      jwt: jwt,
    };

    const userState = {
      permissions: jwt?.ate?.permissions || [],
      displayName: result.displayName,
      email: result.email,
      phoneNumber: result.phoneNumber,
      photoURL: result.photoURL,
      userId: result.uid,
    };

    this._store.dispatch(Login());
    this._store.dispatch(SetAuth({ payload: authState }));
    this._store.dispatch(SetUser({ payload: userState }));
    // @TODO: Support multiple organisations
    this._store.dispatch(ToggleIsLoggedIn({ payload: true }));

    return userState;
  }

  public getUser$(): Observable<User> {
    const observer = new Subject<User>();

    onIdTokenChanged(this._auth, user => observer.next(user));

    return observer;
  }

  public refreshToken(user: any): Observable<any> {
    const result = user.toJSON();
    const tokenManager = result.stsTokenManager;

    return from(user.getIdToken()).pipe(
      first(),
      tap((token: string) => {
        this._store.dispatch(
          SetAuth({
            payload: {
              accessToken: token,
              refreshToken: user.refreshToken,
              expirationTime: tokenManager.expirationTime,
              jwt: this._jwtService.parseJWT(token),
            },
          }),
        );
      }),
    );
  }

  public getToken(): Observable<string> {
    return this._store
      .select(authenticationQuery.getAuth)
      .pipe(map((auth: AuthModel) => auth.accessToken));
  }

  /**
   * Newer method of requesting an access token.
   * @param force Will force the token to be refreshed if true
   */
  public getAuthToken$(force = false): Observable<string> {
    return this.getUser$().pipe(
      switchMap(user => from(user.getIdToken(force))),
    );
  }
}
