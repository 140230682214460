<nav>
  <div class="inner-container">
    <div
      *ngFor="let tab of tabs; let i = index"
      (click)="selectTab(tab, i)"
      [class.active]="tab.active"
    >
      <a>{{ tab.title }}</a>
    </div>
  </div>
</nav>
<ng-content></ng-content>
