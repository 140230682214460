<div class="container" [ngClass]="{ disabled: !!statusReason }">
  <div class="quote" [attr.declined]="!!statusReason" (click)="goToQuote()">
    <app-listing-profile-picture
      [listingImage]="quote?.listingServiceImageURI"
    ></app-listing-profile-picture>

    <section class="quote-body">
      <h4>{{ quote?.listingTitle }}</h4>
      <div class="stars">
        <ate-star-bar
          color="orange"
          [attr.percent]="quote?.listingReviewRating * 20"
        ></ate-star-bar>
        <span>({{ quote?.listingReviewCount }})</span>
      </div>
    </section>

    <section class="quote-meta">
      <app-status-pill
        [small]="true"
        *ngIf="statusText"
        [text]="statusText"
        [status]="statusColor"
      ></app-status-pill>
      <p *ngIf="quote?.quotePrice">£{{ quote?.quotePrice }}</p>
    </section>
  </div>
  <p *ngIf="statusReason" class="reason">
    <span>REASON:</span>
    <span>{{ statusReason }}</span>
  </p>
</div>
