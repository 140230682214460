import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { EventRequestInboxComponent } from './features/event/pages/event-request-inbox/event-request-inbox.component';
import { ErrorComponent } from './features/landing-pages/error/error.component';
import { AuthenticationWrapperGuard } from './shared/guards/auth/authentication-wrapper.guard';
import { MaintenanceGuard } from './shared/guards/maintenance/maintenance.guard';
import { MaintenanceComponent } from './features/landing-pages/maintenance/maintenance.component';

const routes: Routes = [
  {
    path: 'event-request',
    loadChildren: () =>
      import('./features/event/event.module').then(m => m.EventModule),
    canActivate: [MaintenanceGuard, AuthenticationWrapperGuard],
  },
  {
    path: 'quote',
    loadChildren: () =>
      import('./features/quotes/quotes.module').then(m => m.QuotesModule),
    canActivate: [MaintenanceGuard, AuthenticationWrapperGuard],
  },
  {
    path: 'listing',
    loadChildren: () =>
      import('./features/listings/listing.module').then(m => m.ListingModule),
    canActivate: [MaintenanceGuard, AuthenticationWrapperGuard],
  },
  { path: 'maintenance', component: MaintenanceComponent },
  { path: 'error', component: ErrorComponent },
  {
    path: '**',
    component: EventRequestInboxComponent,
    canActivate: [MaintenanceGuard, AuthenticationWrapperGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
