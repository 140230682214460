import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { timer, Observable } from 'rxjs';
import { delayWhen, tap } from 'rxjs/operators';
import { FlashMessageService } from 'src/app/shared/services/flash-message/flash-message.service';
import { appActions } from '../actions/app.actions';

@Injectable()
export class ApplicationEffects {
  constructor(
    private actions$: Actions,
    private _flashMessageService: FlashMessageService,
  ) {}

  /**
   * Hides a flash message after it's given timeout
   */
  hideFlashMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(appActions.SetFlashMessage),
        delayWhen(
          (action: any): Observable<number> => {
            return timer(action.flashMessage.timeout);
          },
        ),
        tap((action: any): void => {
          if (action.flashMessage.timeout || action.flashMessage.timeout > 0) {
            this._flashMessageService.hide();
          }
        }),
      ),
    { dispatch: false },
  );
}
