import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AuthenticationState } from '../../../store/reducers/authentication.reducer';
import { authenticationQuery } from '../../../store/selectors/authentication.selectors';
import { Store } from '@ngrx/store';
import { AuthUser } from '../../../store/models';
import { Router } from '@angular/router';
import { AuthService } from '../../../shared/services/auth/auth.service';
import { userQuery } from '../../../store/selectors/user.selectors';
import { UserState } from '../../../store/reducers/user.reducer';
import { UserService } from '../../../shared/services/user/user.service';
import { map, Observable, Subject } from 'rxjs';
import { WindowService } from '../../../shared/services/window/window.service';
import { AteSupportService } from '../../../shared/support/ate-support.service';
import { PageContextService } from '../../../shared/services/page-context/page-context.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() public open = false;

  @Output() ToggleMenu = new EventEmitter<{ open: boolean }>();

  public user: AuthUser;
  public userMenu = false;
  public isSupplier$: Observable<boolean>;
  public profileImage$: Observable<string>;
  public unreadNotifications$: Subject<number>;
  public eventRequestUuid: string;

  constructor(
    public authService: AuthService,
    private router: Router,
    private _authStore: Store<AuthenticationState>,
    private _userStore: Store<UserState>,
    private _userService: UserService,
    private _windowService: WindowService,
    private _supportService: AteSupportService,
    private _pageContextService: PageContextService,
  ) {
    this._pageContextService.getEventRequestUuid.subscribe(uuid => {
      this.eventRequestUuid = uuid;
    });
  }

  outsideClick() {
    if (this.userMenu) {
      this.toggleUserMenu();
    }
  }

  public ngOnInit(): void {
    this._authStore.select(authenticationQuery.getUser).subscribe({
      next: value => {
        this.user = value;
      },
    });
    this.checkUserIsSupplier();
    this.unreadNotifications$ = this._supportService.unreadNotifications$;
  }

  /**
   * Determines wether to show the button for switching to the Supplier Web Client.
   */
  private checkUserIsSupplier() {
    this._userService.setUserDetails();
    this.isSupplier$ = this._userStore
      .select(userQuery.getUserDetails)
      .pipe(map(details => details?.isSupplier));
    this.profileImage$ = this._userStore
      .select(userQuery.getUserDetails)
      .pipe(map(details => details?.profileImageUrl));
  }

  public toggleMenu() {
    this.open = !this.open;
    this.ToggleMenu.emit({ open: this.open });
  }

  public toggleUserMenu(open?: boolean): void {
    if (typeof open === 'boolean') {
      this.userMenu = open;
      return;
    }

    this.userMenu = !this.userMenu;
  }

  public handleLogoClick() {
    this._windowService.open(environment.webUrl, '_self');
  }

  public goToProfile() {
    this._windowService.open(
      `${environment.webUrl}/user/${this.user.userId}/edit`,
      '_self',
    );
  }

  public goToHelp() {
    this._windowService.open(environment.eventOrganiserSupportUrl, '_self');
  }

  public goToSupplier() {
    this._windowService.open(environment.supplierApp, '_self');
  }

  public logout() {
    this.authService.unauthenticate().subscribe(() => {
      this._windowService.open(environment.logoutUrl, '_self');
    });
  }

  public goToEventRequestInbox() {
    this.router.navigate(['/']);
  }

  public openRequestQuotes() {
    let url = environment.requestQuotesUrl;
    if (this.eventRequestUuid) {
      url +=
        (url.includes('?') ? '&' : '?') +
        'eventRequestUuid=' +
        this.eventRequestUuid;
    }
    this._windowService.open(url, '_self');
  }
}
