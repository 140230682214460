import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-active-empty-message-box',
  templateUrl: './active-empty-message-box.component.html',
  styleUrls: ['./active-empty-message-box.component.scss'],
})
export class ActiveEmptyMessageBoxComponent {
  @Input()
  title = `You don’t have any active\n service requests. Submit a service\nrequest now!`;
  @Input()
  message = `Fill out a simple form to start finding the best\nsuppliers for your event.`;
}
