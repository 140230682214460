import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  QueryList,
  ViewChild,
} from '@angular/core';
import { ProgressStepComponent } from './progress-step/progress-step.component';

@Component({
  selector: 'app-progress-accordion',
  templateUrl: './progress-accordion.component.html',
  styleUrls: ['./progress-accordion.component.scss'],
})
export class ProgressAccordionComponent implements OnInit, AfterContentInit {
  @Input() title = '';
  @Input() subtitle = '';
  @Input() currentStep = 0;
  @Input() open = false;

  @ContentChildren(ProgressStepComponent) steps: QueryList<
    ProgressStepComponent
  >;
  @ViewChild('mainSection', { static: true, read: ElementRef })
  mainContent: ElementRef;

  maxHeight = '0';
  progress = 0;
  public innerWidth: number;

  public ngAfterContentInit() {
    // if there is no active tab set, activate the first
    this.selectStep(this.currentStep);
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }

  public selectStep(activeStep: number) {
    // complete all tabs before active step and activate current
    this.steps.toArray().forEach((steps: ProgressStepComponent, index) => {
      if (index < activeStep) {
        steps.active = false;
        steps.complete = true;
      } else if (index === activeStep) {
        steps.active = true;
        steps.complete = false;
      } else {
        steps.active = false;
        steps.complete = false;
      }
    });

    this.progress = Math.ceil((activeStep / this.steps.toArray().length) * 100);
  }

  public togglePanel(open?: boolean): void {
    this.open = open ? open : !this.open;

    if (this.innerWidth > 990) {
      this.maxHeight = null;
      this.open = true;
      return;
    }

    if (this.open) {
      this.maxHeight = this.mainContent.nativeElement.scrollHeight + 'px';
    } else {
      this.maxHeight = null;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
}
