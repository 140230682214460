/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Injectable({
  providedIn: 'root',
})
export class DatalayerService {
  protected dataLayer: any[];

  constructor() {
    this.dataLayer = window.dataLayer = window.dataLayer ?? [];
  }

  /**
   * Send DataLayer event.
   * @param eventName Event Name, cammelCase.
   * @param data Additional Event Data
   */
  sendEvent(eventName: string, data?: any): void {
    this.dataLayer.push({
      event: eventName,
      ...data,
    });
  }
}
