import { createAction, props } from '@ngrx/store';
import { EventRequestSingleType } from '../services/event-request/event-request.type';

const GetEvent = createAction(
  '[Event] Get Event',
  props<{ payload: { eventUuid: string; eventRequestUuid: string } }>(),
);

const SetEventSuccess = createAction(
  '[Event] Set Event Success',
  props<{ payload: EventRequestSingleType }>(),
);

const SetEventFailure = createAction('[Event] Set Event Failure');

export const eventActions = {
  GetEvent,
  SetEventSuccess,
  SetEventFailure,
};
