import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewMoreDetailsComponent } from './view-more-details/view-more-details.component';
import { ReadMoreComponent } from './view-more-details/read-more/read-more.component';
import { StatusPillComponent } from './status-pill/status-pill.component';
import { ServiceListItemComponent } from './service-list-item/service-list-item.component';
import { ReviewListItemComponent } from './listing-reviews/review-list-item/review-list-item.component';
import { ListingProfilePictureComponent } from './profile-picture/listing-profile-picture/listing-profile-picture.component';
import { ProfilePictureComponent } from './profile-picture/organiser-profile-picture/profile-picture.component';
import { EventRequestListItemComponent } from '../../features/event/components/event-request-list-item/event-request-list-item.component';
import { SharedModule } from '../../shared/shared.module';
import { QuoteCountComponent } from './quote-count/quote-count.component';
import { QuoteListItemComponent } from './quotes-list/quote-list-item/quote-list-item.component';
import { QuotesDeclinedComponent } from './quotes-list/quotes-declined/quotes-declined.component';
import { QuotesListComponent } from './quotes-list/quotes-list.component';
import { ListingListComponent } from './listing-list/listing-list.component';
import { ListingListItemComponent } from './listing-list/listing-list-item/listing-list-item.component';
import { ListingReviewsComponent } from './listing-reviews/listing-reviews.component';
import { LinkComponent } from './link/link.component';

@NgModule({
  declarations: [
    ViewMoreDetailsComponent,
    ReadMoreComponent,
    StatusPillComponent,
    ServiceListItemComponent,
    ListingReviewsComponent,
    ReviewListItemComponent,
    ListingProfilePictureComponent,
    ProfilePictureComponent,
    EventRequestListItemComponent,
    QuoteCountComponent,
    QuotesListComponent,
    QuoteListItemComponent,
    QuotesDeclinedComponent,
    ListingListComponent,
    ListingListItemComponent,
    LinkComponent,
  ],
  exports: [
    ViewMoreDetailsComponent,
    ReadMoreComponent,
    StatusPillComponent,
    ServiceListItemComponent,
    ListingReviewsComponent,
    ReviewListItemComponent,
    ListingProfilePictureComponent,
    ProfilePictureComponent,
    EventRequestListItemComponent,
    QuoteCountComponent,
    QuotesListComponent,
    QuoteListItemComponent,
    QuotesDeclinedComponent,
    ListingListComponent,
    ListingListItemComponent,
    LinkComponent,
  ],
  imports: [CommonModule, SharedModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule {}
