<app-supplier-listing-modal
  [listingUuid]="quote?.listing?.uuid"
  [phoneNumber]="quote?.listing?.phoneNumber"
>
</app-supplier-listing-modal>

<app-page-container>
  <div class="quote-request-container">
    <section class="chat-container">
      <header>
        <div class="title">
          <ate-icon (click)="goBack()" name="chevron-left-v2"></ate-icon>
          <section>
            <h1 (click)="goToListing()">
              {{ quote?.listing.title }}
            </h1>
          </section>
          <ate-button-control
            class="phone-button"
            color="secondary"
            (click)="callPhoneNumber()"
          >
            <ate-icon name="phone-v2"></ate-icon
            >{{ quote?.listing?.phoneNumber }}</ate-button-control
          >
        </div>
      </header>
      <app-chat
        [quoteUuid]="quoteUuid"
        [phoneNumber]="quote?.listing?.phoneNumber"
        [listing]="quote?.listing"
        [fixedMessageBox]="false"
        [displayControls]="false"
        [status]="quote?.quoteStatus"
      >
        <app-quote-events [quote]="quote"></app-quote-events>
      </app-chat>
    </section>
    <div class="quote-body-container">
      <app-quote-body
        class="quote-container sticky-action-buttons"
        [quoteBody]="quoteBody"
        [review]="quote?.review"
        [listingTitle]="quote?.listing?.title"
        ><h1>
          <app-subheader
            [quote]="quote"
            [showBackButton]="false"
            [quoteUuid]="quoteUuid"
            (listingClick)="goToListing()"
            (goBack)="goBack()"
            ><app-quote-context-menu
              slot="context-menu"
              [quoteUuid]="quoteUuid"
              [supplierPhoneNumber]="quote?.listing.phoneNumber"
              [listingUuid]="quote?.listing?.uuid"
              [listingTitle]="quote?.listing?.title"
              [listing]="quote?.listing"
              [status]="quote?.quoteStatus"
              [review]="quote?.review"
              (listingClick)="goToListing()"
            ></app-quote-context-menu>
            <div class="status" slot="status">
              <app-quote-status-info
                [quoteEstimate]="quote?.quotePrice"
                [status]="quote?.quoteStatus"
              ></app-quote-status-info>

              <app-status-pill
                [small]="true"
                *ngIf="statusText"
                [text]="statusText"
                [status]="statusColor"
              ></app-status-pill>
            </div>
          </app-subheader></h1
      ></app-quote-body>
    </div></div
></app-page-container>
