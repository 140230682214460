<!-- MODALS  -->
<ng-container *ngIf="isLoading">
  <div class="spinner">
    <ate-spinner *ngIf="isLoading"></ate-spinner></div
></ng-container>

<ng-container *ngIf="!isLoading">
  <ng-container *ngIf="!isMobile">
    <ate-form-flow-modal
      #modal
      [autoOpen]="true"
      [simpleModal]="true"
      [showFooterButtons]="false"
      (onModalClose)="navigateBack()"
    >
      <ng-container [ngTemplateOutlet]="modalBodyTemplate$ | async">
      </ng-container>
    </ate-form-flow-modal>
  </ng-container>
</ng-container>

<ng-container *ngIf="!isLoading">
  <!-- MOBILE PAGES -->
  <ng-container *ngIf="isMobile">
    <div class="full-page">
      <ng-container [ngTemplateOutlet]="modalBodyTemplate$ | async">
      </ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-template #accept>
  <app-accept-quote-modal
    [quote]="quote"
    [listingItems]="listingItems"
    [currentListing]="currentListing"
    [isMobile]="isMobile"
    (onDeclineOtherQuote)="switchModal($event)"
    (onClose)="close()"
  ></app-accept-quote-modal>
</ng-template>
<ng-template #decline>
  <app-decline-quote-modal
    #declineQuoteModalComponent
    [quote]="quote"
    [listingItems]="listingItems"
    [currentListing]="currentListing"
    [isMobile]="isMobile"
    (onAcceptOtherQuote)="switchModal($event)"
    (onClose)="close()"
  ></app-decline-quote-modal>
</ng-template>
<ng-template #closeEventRequest>
  <app-close-event-request-modal
    #closeEventRequestModal
    (onQuoteAction)="switchModal($event)"
    [isMobile]="isMobile"
    [eventRequest]="eventRequest"
    (onClose)="close()"
  ></app-close-event-request-modal>
</ng-template>
