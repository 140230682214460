/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { QuoteActionType } from '../../../../shared/enums/quote-action-type.enum';

export interface ListingItem {
  uuid?: string;
  images: string[];
  title: string;
  stars: number;
  ratings: number;
  selected?: boolean;
  declined?: boolean;
  disabled?: boolean;
  actionButtons?: boolean;
  viewQuoteButton?: boolean;
  quoteUuid: string;
}

@Component({
  selector: 'app-listing-list-item',
  templateUrl: './listing-list-item.component.html',
  styleUrls: ['./listing-list-item.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class ListingListItemComponent implements OnInit {
  public image: string;
  // Make the enum available in the template
  public quoteActionTypes = QuoteActionType;
  private _listing: ListingItem;

  get listing(): ListingItem {
    return this._listing;
  }

  @HostBinding('class.selected') selected = false;
  @HostBinding('class.unselected') unselected = false;
  @HostBinding('class.declined') declined = false;
  @HostBinding('class.disabled') disabled = false;
  @HostBinding('class.actions') actionButtons = false;
  @HostBinding('class.view-quotes') viewQuoteButton = false;
  @Output() SendAction = new EventEmitter<QuoteActionType>();

  @Input() set listing(value: ListingItem) {
    this._listing = value;
    this.setImage(value.images);
  }

  ngOnInit(): void {
    this.selectClass();
  }

  private setImage(images: string[]) {
    this.image = images?.length && images[0] ? images[0] : '';
  }

  private selectClass(): void {
    if (this.listing?.selected) {
      this.selected = true;
    } else if (!this.listing?.selected) {
      this.unselected = true;
    }

    if (this.listing?.actionButtons) {
      this.actionButtons = true;
    }

    if (this.listing?.viewQuoteButton) {
      this.viewQuoteButton = true;
    }

    if (this.listing?.declined) {
      this.declined = true;
    }

    if (this.listing?.disabled) {
      this.disabled = true;
    }
  }

  public selectAction(action: QuoteActionType): void {
    this.SendAction.emit(action);
  }
}
